import { Keyword } from "./Model.Medicine";
import { string } from "prop-types";
import { PremiumType } from "./Model.User.Premium";
import { UserInfo } from "./Model.User";

export interface Reference {
  url: string;
  mediaType?: string;
  title?: string;
  description?: string;
  image?: string;
  hash?: number;
}

export interface Attachment {
  url?: string;
  name?: string;
  size?: string;
  description?: string;
  link?: string;
  base64?: any;
  logData?: any;
  logDataJson?: any;
  partialLinks?: any[]; // {top:number<=1, bottom, left, right, link}
  partialLinksJson?: string; // {top:number<=1, bottom, left, right, link}
}

export interface BoardComment {
  id?: number;
  key?: string;
  boardsContentsId?: number;
  boardsContentsGroupId?: number;
  usersId?: number;
  usersBizId?: string;
  usersNickname?: string;
  usersProfileUrl?: string;
  usersCustomName?: string;
  usersPassword?: string;
  password?: string;
  isAnonymous?: boolean; // 익명 게시 여부
  isHiddenPorfilePost?: boolean; // 질문자의 여부
  usersPremiumType?: PremiumType;

  groupParent?: number;
  groupDepth?: number;
  groupOrder?: number;
  groupCount?: number;

  createdAt?: any;
  deletedAt?: any;
  managedAt?: any;

  likeCnt?: number;
  dislikeCnt?: number;
  bodyText?: string; // 본문 텍스트
  bodyHtml?: string;
  fileUrl?: string;
  fileName?: string;
  fileSize?: string;
  imageUrl?: string;
  liked?: boolean;
  disliked?: boolean;
  reported?: boolean;
  blocked?: boolean;
  editable?: boolean;
}

export interface BoardInfo {
  id?: number;
  ids?: string;
  name?: string;
  order?: number;
  fullname?: string;
  type?: number;
  level?: number;
  attribute?: string;
}

export enum BoardContentCommercialType {
  NONE = 0,
  ADVERTISE = 1,
  SPONSORED = 2,
}

export enum BoardAttribute {
  IS_QNA = "0A",
  IS_DISCUSSION = "0B",
  HAS_TITLE = "0C",
  HAS_BODY = "0D",
  ALLOW_NEW_LINE_ON_TITLE = "0O",

  CAN_REPLY = "0E",
  CAN_SHARE = "0F",
  CAN_LIKE_ON_POST = "0G",
  CAN_LIKE_ON_REPLY = "0H",
  CAN_DISLIKE = "0I",
  CAN_METOO = "0J",
  CAN_COMMENT_ON_POST = "0K",
  CAN_COMMENT_ON_REPLY = "0L",
  CAN_ANONYMOUS = "0M",
  CAN_SCRAP = "0N",

  HAS_CARDNEWS = "0Q",
  IS_COMMERCIAL = "0P",

  HAS_SUBCATEGORY = "0R",

  UI_VIEWER_PROFILE_ON_POST = "1A",
  UI_VIEWER_PROFILE_ON_REPLY = "1B",
  UI_VIEWER_TOOLBAR_ON_POST = "1C",
  UI_VIEWER_TOOLBAR_ON_REPLY = "1D",
  UI_VIEWER_LIKE_DISLIKE_EMPHASIS = "1E",
  UI_VIEWER_SHOW_ROOT_AUTHOR = "1F",

  UI_LIST_LINK = "2A",
  UI_LIST_TITLE = "2B",
  UI_LIST_BODY = "2C",
  UI_LIST_NOTICE = "2D",
  UI_LIST_FAB = "2E",
  UI_LIST_FILTER = "2F",
  UI_LIST_SORT = "2G",
  UI_LIST_PROFILE = "2H",
  UI_LIST_METOO_CNT = "2I",
  UI_LIST_REPLY_CNT = "2J",
  UI_LIST_COMMENT_CNT = "2K",
  UI_LIST_LIKE_CNT = "2L",
  UI_LIST_VIEW_CNT = "2M",
  UI_LIST_IMAGE = "2N",
  UI_LIST_BANNER = "2O",
  UI_LIST_SUMMARY = "2V",
  UI_LIST_NICE_CONTENT_BADGE = "2P",
  UI_LIST_BIG_IMAGE = "2Q",
  UI_LIST_COMMENT = "2S",
  UI_LIST_TOOLBAR = "2T",
  UI_LIST_CANTEGORY_NAME = "2U",
}

export const ALL_QNA_PRESET =
  BoardAttribute.IS_QNA +
  BoardAttribute.HAS_TITLE +
  BoardAttribute.HAS_BODY +
  // BoardAttribute.CAN_REPLY +
  BoardAttribute.CAN_SHARE +
  BoardAttribute.CAN_SCRAP +
  BoardAttribute.CAN_METOO +
  BoardAttribute.CAN_LIKE_ON_REPLY +
  BoardAttribute.CAN_COMMENT_ON_REPLY +
  BoardAttribute.HAS_SUBCATEGORY +
  BoardAttribute.UI_VIEWER_PROFILE_ON_REPLY +
  BoardAttribute.UI_VIEWER_TOOLBAR_ON_POST +
  BoardAttribute.UI_VIEWER_TOOLBAR_ON_REPLY +
  BoardAttribute.UI_VIEWER_SHOW_ROOT_AUTHOR +
  BoardAttribute.UI_LIST_CANTEGORY_NAME +
  BoardAttribute.UI_LIST_NICE_CONTENT_BADGE +
  BoardAttribute.UI_LIST_IMAGE +
  BoardAttribute.UI_LIST_LINK +
  BoardAttribute.UI_LIST_TITLE +
  BoardAttribute.UI_LIST_BODY +
  BoardAttribute.UI_LIST_NOTICE +
  BoardAttribute.UI_LIST_BANNER +
  BoardAttribute.UI_LIST_SUMMARY +
  BoardAttribute.UI_LIST_FAB +
  BoardAttribute.UI_LIST_FILTER +
  BoardAttribute.UI_LIST_SORT +
  BoardAttribute.UI_LIST_METOO_CNT +
  BoardAttribute.UI_LIST_REPLY_CNT +
  BoardAttribute.UI_LIST_LIKE_CNT +
  BoardAttribute.UI_LIST_VIEW_CNT;
export const QNA_PRESET =
  BoardAttribute.IS_QNA +
  BoardAttribute.HAS_TITLE +
  BoardAttribute.HAS_BODY +
  BoardAttribute.CAN_REPLY +
  BoardAttribute.CAN_SHARE +
  BoardAttribute.CAN_SCRAP +
  BoardAttribute.CAN_METOO +
  BoardAttribute.CAN_LIKE_ON_REPLY +
  BoardAttribute.CAN_COMMENT_ON_REPLY +
  BoardAttribute.HAS_SUBCATEGORY +
  BoardAttribute.UI_VIEWER_PROFILE_ON_REPLY +
  BoardAttribute.UI_VIEWER_TOOLBAR_ON_POST +
  BoardAttribute.UI_VIEWER_TOOLBAR_ON_REPLY +
  BoardAttribute.UI_VIEWER_SHOW_ROOT_AUTHOR +
  BoardAttribute.UI_LIST_NICE_CONTENT_BADGE +
  BoardAttribute.UI_LIST_IMAGE +
  BoardAttribute.UI_LIST_LINK +
  BoardAttribute.UI_LIST_TITLE +
  BoardAttribute.UI_LIST_BODY +
  BoardAttribute.UI_LIST_NOTICE +
  BoardAttribute.UI_LIST_FAB +
  BoardAttribute.UI_LIST_FILTER +
  BoardAttribute.UI_LIST_SORT +
  BoardAttribute.UI_LIST_METOO_CNT +
  BoardAttribute.UI_LIST_REPLY_CNT +
  BoardAttribute.UI_LIST_LIKE_CNT +
  BoardAttribute.UI_LIST_VIEW_CNT;
export const POST_PRESET =
  BoardAttribute.HAS_BODY +
  BoardAttribute.CAN_SHARE +
  BoardAttribute.CAN_SCRAP +
  BoardAttribute.CAN_LIKE_ON_POST +
  BoardAttribute.CAN_COMMENT_ON_POST +
  BoardAttribute.UI_VIEWER_PROFILE_ON_POST +
  BoardAttribute.UI_VIEWER_TOOLBAR_ON_POST +
  BoardAttribute.HAS_SUBCATEGORY +
  BoardAttribute.UI_LIST_IMAGE +
  BoardAttribute.UI_LIST_LINK +
  BoardAttribute.UI_LIST_BODY +
  BoardAttribute.UI_LIST_NOTICE +
  BoardAttribute.UI_LIST_FAB +
  BoardAttribute.UI_LIST_FILTER +
  BoardAttribute.UI_LIST_SORT +
  BoardAttribute.UI_LIST_PROFILE +
  BoardAttribute.UI_LIST_COMMENT_CNT +
  BoardAttribute.UI_LIST_LIKE_CNT +
  BoardAttribute.UI_LIST_VIEW_CNT;
export const DISCUSS_PRESET =
  BoardAttribute.IS_DISCUSSION +
  BoardAttribute.HAS_TITLE +
  BoardAttribute.HAS_BODY +
  BoardAttribute.CAN_REPLY +
  BoardAttribute.CAN_SHARE +
  BoardAttribute.CAN_COMMENT_ON_REPLY +
  BoardAttribute.CAN_ANONYMOUS +
  BoardAttribute.CAN_LIKE_ON_REPLY +
  BoardAttribute.CAN_DISLIKE +
  BoardAttribute.UI_VIEWER_PROFILE_ON_REPLY +
  BoardAttribute.UI_VIEWER_TOOLBAR_ON_REPLY +
  BoardAttribute.UI_VIEWER_SHOW_ROOT_AUTHOR +
  BoardAttribute.UI_LIST_NOTICE +
  BoardAttribute.UI_LIST_FAB +
  BoardAttribute.UI_LIST_TITLE +
  BoardAttribute.UI_LIST_BODY +
  BoardAttribute.UI_LIST_REPLY_CNT +
  BoardAttribute.UI_LIST_COMMENT_CNT +
  BoardAttribute.UI_LIST_VIEW_CNT;
export const NOTICE_PRESET =
  BoardAttribute.HAS_TITLE +
  BoardAttribute.HAS_BODY +
  BoardAttribute.CAN_SHARE +
  BoardAttribute.CAN_LIKE_ON_POST +
  BoardAttribute.CAN_COMMENT_ON_POST +
  BoardAttribute.UI_VIEWER_TOOLBAR_ON_POST +
  BoardAttribute.UI_LIST_TITLE +
  BoardAttribute.UI_LIST_COMMENT_CNT +
  BoardAttribute.UI_LIST_LIKE_CNT +
  BoardAttribute.UI_LIST_VIEW_CNT;
export const RECOMMEND_PRESET =
  BoardAttribute.HAS_TITLE +
  BoardAttribute.HAS_BODY +
  BoardAttribute.UI_LIST_TITLE +
  BoardAttribute.UI_LIST_COMMENT_CNT +
  BoardAttribute.UI_LIST_LIKE_CNT +
  BoardAttribute.UI_LIST_VIEW_CNT +
  BoardAttribute.UI_LIST_CANTEGORY_NAME +
  BoardAttribute.UI_LIST_FILTER +
  BoardAttribute.UI_LIST_BANNER +
  BoardAttribute.UI_LIST_SORT;
export const COMMERCIAL_PRESET =
  BoardAttribute.IS_COMMERCIAL +
  BoardAttribute.HAS_TITLE +
  BoardAttribute.HAS_BODY +
  BoardAttribute.CAN_REPLY +
  BoardAttribute.CAN_SHARE +
  BoardAttribute.CAN_SCRAP +
  BoardAttribute.CAN_LIKE_ON_POST +
  BoardAttribute.CAN_LIKE_ON_REPLY +
  BoardAttribute.CAN_COMMENT_ON_REPLY +
  BoardAttribute.UI_VIEWER_PROFILE_ON_REPLY +
  BoardAttribute.UI_VIEWER_TOOLBAR_ON_POST +
  BoardAttribute.UI_VIEWER_TOOLBAR_ON_REPLY +
  BoardAttribute.UI_LIST_NICE_CONTENT_BADGE +
  BoardAttribute.UI_LIST_IMAGE +
  BoardAttribute.UI_LIST_TITLE +
  BoardAttribute.UI_LIST_LIKE_CNT +
  BoardAttribute.UI_LIST_VIEW_CNT;
export const CHALLENGE_PRESET =
  BoardAttribute.HAS_BODY +
  BoardAttribute.CAN_SHARE +
  BoardAttribute.CAN_SCRAP +
  BoardAttribute.CAN_LIKE_ON_POST +
  BoardAttribute.CAN_COMMENT_ON_POST +
  BoardAttribute.UI_VIEWER_PROFILE_ON_POST +
  BoardAttribute.UI_VIEWER_TOOLBAR_ON_POST +
  BoardAttribute.UI_LIST_IMAGE +
  BoardAttribute.UI_LIST_BIG_IMAGE +
  BoardAttribute.UI_LIST_LINK +
  BoardAttribute.UI_LIST_BODY +
  BoardAttribute.UI_LIST_FAB +
  BoardAttribute.UI_LIST_PROFILE +
  BoardAttribute.UI_LIST_COMMENT_CNT +
  BoardAttribute.UI_LIST_LIKE_CNT +
  BoardAttribute.UI_LIST_VIEW_CNT +
  BoardAttribute.UI_LIST_COMMENT +
  BoardAttribute.UI_LIST_TOOLBAR;

export const SUBCATEGORY_ALL_PRESET = [
  { id: 1, name: "효과/기전" },
  { id: 2, name: "용법/용량" },
  { id: 3, name: "부작용" },
  { id: 7, name: "질환" },
  { id: 4, name: "금기/주의사항" },
  { id: 5, name: "상호작용(병용)" },
  { id: 8, name: "제품추천" },
  { id: 6, name: "보관/안전성" },
  { id: 9, name: "정보공유" },
  { id: 10, name: "커리어" },
  { id: 12, name: "일상" },
  { id: 13, name: "경영" },
  { id: 14, name: "세무" },
  { id: 15, name: "노무" },
  { id: 11, name: "기타" },
];

export const SUBCATEGORY_QNA_PRESET = [
  { id: 1, name: "효과/기전" },
  { id: 2, name: "용법/용량" },
  { id: 3, name: "부작용" },
  { id: 7, name: "질환" },
  { id: 4, name: "금기/주의사항" },
  { id: 5, name: "상호작용(병용)" },
  { id: 8, name: "제품추천" },
  { id: 6, name: "보관/안전성" },
  { id: 9, name: "정보공유" },
  { id: 11, name: "기타" },
];

export const SUBCATEGORY_CONSULT_PRESET = [
  { id: 9, name: "정보공유" },
  { id: 10, name: "커리어" },
  { id: 12, name: "일상" },
  { id: 13, name: "경영" },
  { id: 14, name: "세무" },
  { id: 15, name: "노무" },
  { id: 11, name: "기타" },
];

export const SUBCATEGORY_ETC_PRESET = [
  { id: 12, name: "일상" },
  { id: 16, name: "뉴스" },
  { id: 11, name: "기타" },
];

export interface BoardContent {
  id?: number;
  key?: string;

  groupParent?: number;
  groupId?: number;
  groupDepth?: number;
  groupOrder?: number;

  boardsCategoriesId?: number; // 저장될 게시판 id
  boardsCategoriesName?: string;
  boardsCategoriesAttribute?: string;

  subCategories?: any[]; // [{id, name}]
  inviteBiz?: boolean;

  isAnonymous?: boolean; // 익명 게시 여부
  usersId?: number;
  usersBizId?: string;
  usersCustomName?: string;
  usersNickname?: string;
  usersPremiumType?: PremiumType;
  usersProfileUrl?: string;

  seminarLectureId?: number;

  subject?: string; // 제목
  subjectHightlight?: string; // 제목
  bodySummary?: any; // 본문 요약 json
  bodyText?: string; // 본문 텍스트
  bodyTextHightlight?: string; // 본문 텍스트
  bodyHidden?: string; // 숨겨진 본문 텍스트
  bodyHtml?: string; // 본문 html

  keywords?: Keyword[]; //키워드
  images?: string[]; // 첨부 이미지
  image?: string; // 대표 이미지
  imagesLocal?: any[];
  imagesBase64?: any[];
  imagesInner?: string[];

  files?: Attachment[]; // 첨부 파일
  file?: Attachment; // 대표 첨부 파일
  filesLocal?: any[];
  references?: Reference[]; // 링크들
  reference?: Reference; // 대표링크
  referenceId?: Reference; // 대표링크

  createdAt?: any;
  modifiedAt?: any;
  managedAt?: any;
  deletedAt?: any;
  lastViewedAt?: any;

  nicedAt?: any; // 추천된 날자
  digestedAt?: any; // digest로 전달된 날자
  digestCandidate?: number; // digest 후보

  viewCnt?: number;
  likeCnt?: number;
  likeCntAll?: number;
  dislikeCnt?: number;
  scrapCnt?: number;
  replyCnt?: number;
  sharedCnt?: number;
  commentCnt?: number;
  meTooCnt?: number;

  liked?: boolean;
  scrapped?: boolean;
  shared?: boolean;
  disliked?: boolean;
  viewed?: boolean;
  metoo?: boolean;
  reported?: boolean;
  blocked?: boolean;
  editable?: boolean;

  comments?: BoardComment[];
  replies?: BoardContent[];
  checksum?: number;
  anonymousNames?: {};

  skipNotification?: boolean;
  isNice?: boolean;
  noNice?: boolean;
  score?: number;

  commercialType?: BoardContentCommercialType;
  advertiserCode?: string;
  advertiserProductCode?: string;
  cards?: Attachment[];
  videoUrl?: string;

  digestStatisticBefore?: number[]; // viewCnt, likeCntAll, scrapCnt, replyCnt, sharedCnt, commentCnt, meTooCnt
  digestStatisticAfter?: number[]; // viewCnt, likeCntAll, scrapCnt, replyCnt, sharedCnt, commentCnt, meTooCnt
  hasQuiz?: boolean;
}

export interface BoardContentSummaryGroup {
  keyword?: string;
  summaries?: BoardContentSummary[];
  lastViewed?: number;
  count?: number;
  end?: boolean;
  loading?: boolean;
}

export interface BoardContentSummary {
  id?: number;
  version?: string;
  contentId?: string;
  summary?: {
    question: string;
    answers: { text: string; from: string[] }[];
  };
  keyword?: string[];
  summarizedAt?: string;
  viewed?: boolean;
  scrapped?: boolean;
  state?: string;
  deletedAt?: string;
  createdAt?: string;
}

export enum BoardContentQuizType {
  NONE = "",
  CHOICE = "객관식",
  BLANK = "빈칸채우기",
  OX = "OX퀴즈",
  ORDER = "순서맞추기",
}

export interface BoardContentQuiz {
  id?: number;

  version?: string;
  contentType?: string;
  contentId?: number;

  type?: BoardContentQuizType;
  question?: string;
  options?: { text?: string; score?: number; idx?: number }[];
  answers?: { text?: string; score?: number; idx?: number }[];
  result?: string;
  comment?: string;

  createdAt?: string;
  createdBy?: number;
  createdByUser?: UserInfo;
  updatedAt?: string;
  deletedAt?: string;
  deletedBy?: number;
  deletedByUser?: UserInfo;
  verifiedAt?: string;
  verifiedBy?: number;
  verifiedByUser?: UserInfo;

  verified?: boolean;

  //통계
  tryCnt?: number;
  successCnt?: number;
  likeCnt?: number;
  dislikeCnt?: number;
}

export interface BoardContentQuizResult {
  userId?: number;
  dailyQuizId?: number;
  quizId?: number;
  option?: string; // original index 순서
  score?: number; // 맞으면 1 아니면 0
  actedAt?: string;
}
//100점 이후로 solve를 저장하지 않는다.

export interface DailyQuiz {
  id?: number; // days number : UTC milliseconds / 1000 / 60 / 60 / 24
  date?: string; // yyyy-MM-dd
  quizzes?: BoardContentQuiz[];
  title?: string;

  createdAt?: string;
  createdBy?: number;
  createdByUser?: UserInfo;

  //내가 문제를 히스토리
  score?: number; //내 점수 그날의 퀴즈의 몇개 맞은 개수
  quizCnt?: number; // 퀴즈의 개수

  //문제를 푼 이력
  results?: BoardContentQuizResult[];
}

export const sampleBoardContentSummary: BoardContentSummary = {
  contentId: "9303",
  summary: {
    question:
      "1도 화상에 대해 아줄렌, 미보, 항생제 연고 중 어떤 것을 선택해야 하는지, 그 차이는 무엇인지?",
    answers: [
      {
        text:
          "2도 이하의 화상에서 세균 감염 우려가 있을 때는 항생제 연고를 사용할 수 있습니다.",
        from: ["CNT9307", "CMT2534"],
      },
      {
        text:
          "아줄렌은 햇볕에 의한 화상 등에 소염, 진통, 진정 효과로 사용됩니다.",
        from: ["CNT9307"],
      },
      {
        text:
          "미보연고는 항염, 항산화, 항균 작용이 잘 나타나며 부작용이 적어 임산부나 유아도 사용 가능합니다.",
        from: ["CNT9307"],
      },
      {
        text: "가벼운 상처에는 바세린이나 비판텐 연고를 고려할 수 있습니다.",
        from: ["CNT9307"],
      },
      {
        text:
          "통증, 열감, 가려움증이 심할 때는 황련해독탕, 농양이나 진물이 많을 때는 황기건중탕, 붓기가 심할 때는 오령산, 월비가출탕을 사용할 수 있습니다.",
        from: ["9311"],
      },
    ],
  },
  keyword: ["화상연고", "아줄렌", "미보", "항생제연고"],
};
export const sampleBoardContentSummaryStr: string = `
{
  "contentId": "9303",
  "summary": {
    "question":
      "1도 화상에 대해 아줄렌, 미보, 항생제 연고 중 어떤 것을 선택해야 하는지, 그 차이는 무엇인지?",
    "answers": [
      {
        "text":
          "2도 이하의 화상에서 세균 감염 우려가 있을 때는 항생제 연고를 사용할 수 있습니다.",
        "from": ["CNT9307", "CMT2534"],
      },
      {
        "text":
          "아줄렌은 햇볕에 의한 화상 등에 소염, 진통, 진정 효과로 사용됩니다.",
        "from": ["CNT9307"],
      },
      {
        "text":
          "미보연고는 항염, 항산화, 항균 작용이 잘 나타나며 부작용이 적어 임산부나 유아도 사용 가능합니다.",
        "from": ["CNT9307"],
      },
      {
        "text": "가벼운 상처에는 바세린이나 비판텐 연고를 고려할 수 있습니다.",
        "from": ["CNT9307"],
      },
      {
        "text":
          "통증, 열감, 가려움증이 심할 때는 황련해독탕, 농양이나 진물이 많을 때는 황기건중탕, 붓기가 심할 때는 오령산, 월비가출탕을 사용할 수 있습니다.",
        "from": ["9311"],
      },
    ],
  },
  "keyword": ["화상연고", "아줄렌", "미보", "항생제연고"],
}
`;

export const sampleBoardContentQuiz: BoardContentQuiz[] = [
  {
    id: 1,
    version: "1.0.1",
    contentType: "board",
    contentId: 760,
    type: BoardContentQuizType.CHOICE,
    question:
      "아스피린과 오메가 3를 함께 복용할 경우 어떤 부작용이 발생할 수 있나요?",
    options: [
      { text: "출혈 경향", score: 1 },
      { text: "심장마비", score: 0 },
      { text: "고혈압", score: 0 },
      { text: "당뇨", score: 0 },
    ],
    comment:
      "아스피린과 오메가 3를 함께 복용할 경우 출혈 경향이 있을 수 있습니다.",
  },
  {
    id: 2,
    version: "1.0.1",
    contentType: "board",
    contentId: 51511,
    type: BoardContentQuizType.CHOICE,
    question:
      "스테로이드 주사 후 메트포르민을 제미글로로 변경하였습니다. 이유는 무엇일까요?",

    options: [
      { text: "제미글로가 혈당을 더 적극적으로 관리한다.", score: 1 },
      { text: "제미글로가 더 싼 약이다.", score: 0 },
      { text: "메트포르민이 스테로이드의 대사를 방해한다.", score: 0 },
      { text: "제미글로가 저혈당 위험을 줄인다", score: 0 },
    ],
    comment:
      "스테로이드는 혈당을 상승시키기 때문에 혈당을 더 적극적으로 관리해야합니다. 메트포르민은 당 신생을 억제하여 공복시 혈당을 오르지 못하도록 하는 약물이며, 제미글로는 식후 인슐린 분비를 촉진시켜 혈당 상승을 보다 적극적으로 관리합니다.",
  },
  {
    id: 3,
    version: "1.0.1",
    contentType: "board",
    contentId: 8175,
    type: BoardContentQuizType.CHOICE,
    question: "로아큐탄 복용 시 피임약을 복용한다면 어떤 점을 주의해야 하나요?",

    options: [
      {
        text:
          "피임약의 효과가 100% 보장되지 않으므로 추가적인 피임 방법을 사용해야 한다.",
        score: 1,
      },
      { text: "피임약을 복용하면 로아큐탄의 효과가 감소한다.", score: 0 },
      { text: "피임약과 로아큐탄은 함께 복용하면 안된다.", score: 0 },
      { text: "피임약과 로아큐탄의 복용 시간을 정확히 맞춰야 한다.", score: 0 },
    ],
    comment:
      "로아큐탄은 기형아 출산 위험이 있는 약이기 때문에 추가적인 방법으로 피임을 철저히 해야합니다.",
  },
  {
    id: 4,
    version: "1.0.1",
    contentType: "board",
    contentId: 55475,
    type: BoardContentQuizType.CHOICE,
    question:
      "암로디핀 5mg와 레르카니디핀 10mg의 혈압강하효과는 어떻게 다른가요?",

    options: [
      { text: "두 약의 혈압강하효과는 동등하다", score: 1 },
      { text: "암로디핀이 더 강하다", score: 0 },
      { text: "레르카니디핀이 더 강하다", score: 0 },
      { text: "약의 강도를 비교할 수 없다", score: 0 },
    ],
    comment: "암로디핀 5mg와 레르카니디핀 10mg의 혈압강하효과는 동등하다.",
  },
  {
    id: 5,
    version: "1.0.1",
    contentType: "board",
    contentId: 29713,
    type: BoardContentQuizType.OX,
    question: "포사맥스플러스디는 통증에 사용될 수 있다.",
    options: [
      { text: "O", score: 1 },
      { text: "X", score: 0 },
    ],
    comment:
      "알렌드론산나트륨수화물은 복합부위 통증증후군(CRPS)에서 통증을 유의미하게 줄일 수 있습니다.",
  },
  {
    id: 6,
    version: "1.0.1",
    contentType: "board",
    contentId: 34046,
    type: BoardContentQuizType.CHOICE,
    question: "심근경색 환자에게 아르기닌이 금기인 주된 이유는 무엇인가요?",

    options: [
      { text: "유해산소 발생 및 호모시스테인 증가해서", score: 1 },
      { text: "혈관 확장 효과가 너무 강해서", score: 0 },
      { text: "혈압을 높이는 부작용이 있어서", score: 0 },
      { text: "심장쇼크를 유발할 수 있어서", score: 0 },
    ],
    comment:
      "아르기닌은 유해산소 발생 및 호모시스테인 증가로 인해 심근경색 환자에게 금기입니다.",
  },
  {
    id: 7,
    version: "1.0.1",
    contentType: "board",
    contentId: 5491,
    type: BoardContentQuizType.CHOICE,
    question: "딸꾹질이 심한 환자에게 권장할 수 없는 OTC 약물은?",
    options: [
      { text: "이부프로펜", score: 0 },
      { text: "트리메부틴", score: 1 },
      { text: "돔페리돈", score: 0 },
      { text: "반하사심탕", score: 0 },
    ],
    comment: "트리메부틴, 돔페리돈, 반하사심탕을 권할 수 있습니다.",
  },
  {
    id: 8,
    version: "1.0.1",
    contentType: "board",
    contentId: 23071,
    type: BoardContentQuizType.CHOICE,
    question: "어린이 영양제 복용법 중 틀린 것은?",

    options: [
      {
        text: "0~6개월 동안은 비타민D 섭취량을 100IU를 넘지 않아야 한다.",
        score: 1,
      },
      { text: "아연은 상한용량을 넘지 않는 경우 복용해도 된다.", score: 0 },
      { text: "비타민D는 생후 바로 복용이 가능하다.", score: 0 },
      { text: "철분 수치가 정상일 경우 복용하지 않아야 한다.", score: 0 },
    ],
    comment: "0~6개월 동안은 비타민D 복용량이 1,000IU를 넘지 않아야 합니다.",
  },
  {
    id: 9,
    version: "1.0.1",
    contentType: "board",
    contentId: 13902,
    type: BoardContentQuizType.CHOICE,
    question: "귀를 뚫고 염증이 난 환자에게 줄 OTC 조합을 고르세요.",

    options: [
      { text: "소염진통제 + 브로멜라인", score: 0 },
      { text: "브로멜라인 + 배농산급탕 + 항생제포함점안제", score: 0 },
      { text: "배농산급탕 + 항생제연고", score: 1 },
      { text: "아스피린 + 프로폴리스", score: 0 },
    ],
    comment:
      "귀를 뚫고 염증이 난 환자에게는 배농산급탕과 항생제연고를 주로 추천합니다.",
  },
  {
    id: 10,
    version: "1.0.1",
    contentType: "board",
    contentId: 18994,
    type: BoardContentQuizType.CHOICE,
    question: "리보트릴정이 오프라벨로 사용되는 경우가 아닌 것은?",

    options: [
      { text: "고혈압", score: 1 },
      { text: "하지불안증후군", score: 0 },
      { text: "구강작열감증후군", score: 0 },
      { text: "수면제", score: 0 },
    ],
    comment:
      "허가사항엔 발작, 공황장애 정도로 나와있으며, 하지불안증후군, 구강작열감증후군, 수면제 목적으로 사용될 수 있습니다.",
  },
  {
    id: 11,
    version: "1.0.1",
    contentType: "board",
    contentId: 34196,
    type: BoardContentQuizType.CHOICE,
    question:
      "역류성식도염에 스타브론(tianeptine)을 처방하는 이유는 무엇인가요?",

    options: [
      { text: "스타브론이 glutamate 수용체를 차단하기 때문에", score: 1 },
      { text: "스타브론이 위산을 중화시키기 때문에", score: 0 },
      { text: "스타브론이 기분을 좋게 만들기 떄문에", score: 0 },
      { text: "스타브론이 소화효소를 분비시키기 때문에", score: 0 },
    ],
    comment:
      "스타브론(tianeptine)은 glutamate 수용체를 차단하여 위장관 미주신경 활성화를 억제하고, 이로 인한 위장운동력 감소를 줄이고 위장내용물 정체를 완화시킵니다.",
  },
  {
    id: 12,
    version: "1.0.1",
    contentType: "board",
    contentId: 9209,
    type: BoardContentQuizType.OX,
    question:
      "구순포진이 입안까지 퍼진 경우 오라메디 연고를 단독으로 추천할 수 있다.",
    options: [
      { text: "X", score: 1 },
      { text: "O", score: 0 },
    ],
    comment:
      "오라메디 연고는 스테로이드 성분을 포함하고 있어 단독 사용시 구순포진이 더 악화될 수 있습니다. 정제를 처방받아 복용 중이라면 가능합니다.",
  },
  {
    id: 13,
    version: "1.0.1",
    contentType: "board",
    contentId: 10487,
    type: BoardContentQuizType.CHOICE,
    question:
      "도미나크림을 2개월 바르고 나서 효과가 없는 경우 사용을 중지하는 이유는 무엇인가요?",

    options: [
      { text: "멜라닌 색소에 반응하지 않는 체질", score: 1 },
      { text: "약의 부작용 우려", score: 0 },
      { text: "약의 성분이 변질됨", score: 0 },
      { text: "약의 효과가 사라짐", score: 0 },
    ],
    comment:
      "도미나크림의 주요 성분이 멜라닌 색소에 반응하여 작용하는데, 이에 반응하지 않는 체질일 경우 효과를 보지 못하므로 사용을 중지합니다.",
  },
  {
    id: 14,
    version: "1.0.1",
    contentType: "board",
    contentId: 7825,
    type: BoardContentQuizType.CHOICE,
    question: "PPI중 식사의 영향을 받지 않아 식후에 복용해도 되는 성분은?",

    options: [
      { text: "Pantoprazole", score: 1 },
      { text: "Omeprazole", score: 0 },
      { text: "Esomeprazole", score: 0 },
      { text: "Lansoprazole", score: 0 },
    ],
    comment: "Panto, Rabe, DexLanso는 식사의 영향을 받지 않는 PPI입니다.",
  },
  {
    id: 15,
    version: "1.0.1",
    contentType: "board",
    contentId: 19976,
    type: BoardContentQuizType.CHOICE,
    question: "다음 중 스타틴계열 약물의 부작용이 아닌 것은 무엇인가요?",

    options: [
      { text: "혈압 상승", score: 1 },
      { text: "코큐텐의 결핍", score: 0 },
      { text: "근육관련 병증", score: 0 },
      { text: "당뇨병 상대적 위험증가", score: 0 },
    ],
    comment:
      "스타틴계열 약물의 부작용에는 코큐텐의 결핍, 근육관련 병증, 당뇨병 상대적 위험증가가 있지만, 혈압 상승은 포함되지 않습니다.",
  },
  {
    id: 16,
    version: "1.0.1",
    contentType: "board",
    contentId: 652,
    type: BoardContentQuizType.CHOICE,
    question:
      "하지불안증후군(RLS) 치료에 도움이 될 수 있는 영양제로 적절하지 않은 것은?",
    options: [
      { text: "카페인", score: 1 },
      { text: "철분", score: 0 },
      { text: "마그네슘", score: 0 },
      { text: "엽산", score: 0 },
    ],
    comment:
      "카페인은 하지불안증후군(RLS)의 증상을 악화시킬 수 있으므로, 치료에 도움이 되는 영양제가 아닙니다.",
  },
  {
    id: 17,
    version: "1.0.1",
    contentType: "board",
    contentId: 4473,
    type: BoardContentQuizType.CHOICE,
    question:
      "소론도, 테트라싸이클린, 보령바이오아스트릭스를 갈아서 생리식염수에 섞어 사용하는 처방은 어떤 질환을 치료하기 위한 것일까요?",
    options: [
      { text: "구강내 염증질환", score: 1 },
      { text: "피부염", score: 0 },
      { text: "위염", score: 0 },
      { text: "결막염", score: 0 },
    ],
    comment: "이 처방은 구강내 염증질환을 치료하기 위한 용도로 사용됩니다.",
  },
  {
    id: 18,
    version: "1.0.1",
    contentType: "board",
    contentId: 8029,
    type: BoardContentQuizType.OX,
    question: "터지지 않은 물집 상태의 입술포진에 티로트리신 연고를 추천한다.",
    options: [
      { text: "X", score: 1 },
      { text: "O", score: 0 },
    ],
    comment:
      "터지지 않은 물집 상태의 입술포진에는 아시클로버 연고를 사용하며, 물집이 터진 후에는 티로트리신 연고를 사용한다.",
  },
  {
    id: 19,
    version: "1.0.1",
    contentType: "board",
    contentId: 20659,
    type: BoardContentQuizType.CHOICE,
    question:
      "미카르디스(Losartan 50mg)에서 살로탄(Telmisartan 40mg)으로 변경하는 이유 중 가장 타당한 것은?",

    options: [
      {
        text:
          "미카르디스의 반감기가 길어 혈압 조절이 더 일정해질 수 있기 때문에",
        score: 1,
      },
      { text: "미카르디스가 더 저렴하기 때문에", score: 0 },
      { text: "살로탄에 비해 미카르디스가 부작용이 적기 때문에", score: 0 },
      { text: "미카르디스가 살로탄보다 효과가 약하기 때문에", score: 0 },
    ],
    comment:
      "동일강도이며, telmisartan의 반감기가 길어 혈압 조절이 더 일정해질 수 있어 변경하는 경우가 많습니다.",
  },
  {
    id: 20,
    version: "1.0.1",
    contentType: "board",
    contentId: 11013,
    type: BoardContentQuizType.CHOICE,
    question:
      "캐롤에프정(이부프로펜아르기닌)의 최대 용량이 일반 이부프로펜보다 낮은 이유는 무엇인가요?",

    options: [
      { text: "이부프로펜아르기닌이 더 빠르게 흡수되기 때문에", score: 1 },
      {
        text: "이부프로펜아르기닌이 더 강한 부작용을 가지고 있기 때문에",
        score: 0,
      },
      { text: "이부프로펜아르기닌이 더 비싸기 때문에", score: 0 },
      {
        text: "이부프로펜아르기닌이 더 낮은 효과를 가지고 있기 때문에",
        score: 0,
      },
    ],
    comment:
      "이부프로펜아르기닌은 빠르게 흡수되고 적은 용량으로도 강한 효과를 나타내기 때문에 최대 용량이 일반 이부프로펜보다 낮습니다.",
  },
  {
    id: 21,
    version: "1.0.1",
    contentType: "board",
    contentId: 87253,
    type: BoardContentQuizType.OX,
    question: "물집 잡힌 화상인 경우 일반 가정에서는 물집은 터뜨리면 안된다",
    options: [
      { text: "O", score: 1 },
      { text: "X", score: 0 },
    ],
    comment:
      "병윈처럼 멸균상태를 잘 유지한 채 처치할 수가 없는 일반 가정에서는 물집은 최대한 터뜨리지 않는게 좋습니다.",
  },
  {
    id: 22,
    version: "1.0.1",
    contentType: "board",
    contentId: 35739,
    type: BoardContentQuizType.CHOICE,
    question: "밀크시슬 복용 시 주의해야 하는 환자 유형은 무엇인가요?",

    options: [
      { text: "담도폐쇄 환자", score: 1 },
      { text: "고혈압 환자", score: 0 },
      { text: "당뇨병 환자", score: 0 },
      { text: "고지혈증 환자", score: 0 },
    ],
    comment:
      "밀크시슬은 약동학적으로 80%가 담즙 배출되므로, 심한 담도폐쇄 환자에게는 부담이 될 수 있습니다.",
  },
  {
    id: 23,
    version: "1.0.1",
    contentType: "board",
    contentId: 6935,
    type: BoardContentQuizType.ORDER,
    question:
      "다음 스테로이드 점안액들을 높은 강도에서 낮은 강도순으로 나열하세요.",

    options: [
      { text: "프레드니솔론아세테이트 1%", score: 1 },
      { text: "플루오로메톨론아세테이트 0.1%", score: 2 },
    ],
    comment:
      "플루오로메톨론아세테이트 0.1%가 더 약하고, 프레드니솔론아세테이트 1%가 더 강한 스테로이드 점안액입니다.",
  },
  {
    id: 24,
    version: "1.0.1",
    contentType: "board",
    contentId: 10109,
    type: BoardContentQuizType.CHOICE,
    question: "통풍 환자에게 항생제가 함께 처방되는 경우는?",

    options: [
      { text: "통풍 환자가 동시에 감염증을 일으킨 경우", score: 1 },
      { text: "통풍이 심해진 경우", score: 0 },
      { text: "통풍 치료를 위한 보조요법", score: 0 },
      { text: "통풍 환자의 면역력 강화", score: 0 },
    ],
    comment:
      "통풍 환자가 동시에 감염증을 일으킨 경우에 항생제가 함께 처방될 수 있습니다.",
  },
  {
    id: 25,
    version: "1.0.1",
    contentType: "board",
    contentId: 24603,
    type: BoardContentQuizType.OX,
    question:
      "전립성비대증이 있는 비염 환자에게 2세대 항히스타민제는 권할 수 있다.",
    options: [
      { text: "O", score: 1 },
      { text: "X", score: 0 },
    ],
    comment:
      "2세대 항히스타민제는 전립선비대증 위험성이 없어서 전립선비대증이 있는 비염 환자에게 좋은 선택이 될 수 있습니다.",
  },
  {
    id: 26,
    version: "1.0.1",
    contentType: "board",
    contentId: 4262,
    type: BoardContentQuizType.CHOICE,
    question:
      "도란사민(Tranexamic AcontentId) 복용 시 주의해야 할 사항이 아닌 것은?",

    options: [
      { text: "혈전증 경험 있는 사람 복용 가능", score: 1 },
      { text: "망막이나 시력에 대한 이상 가능성", score: 0 },
      { text: "지혈작용을 증가시키는 피임약과의 병용", score: 0 },
      { text: "두통이나 위장장애 많이 발생함", score: 0 },
    ],
    comment: "혈전증 경험 있는 사람에게는 금기입니다.",
  },
  {
    id: 27,
    version: "1.0.1",
    contentType: "board",
    contentId: 8463,
    type: BoardContentQuizType.OX,
    question: "항생제 안연고를 일반 상처에 사용해도 된다",
    options: [
      { text: "O", score: 1 },
      { text: "X", score: 0 },
    ],
    comment:
      "항생제 안연고는 일반 상처에도 사용 가능하며, 특히 노출된 상처나 절개부위 등에 사용하면 자극감이 적어 환자의 불편함을 줄일 수 있습니다.",
  },
  {
    id: 28,
    version: "1.0.1",
    contentType: "board",
    contentId: 5373,
    type: BoardContentQuizType.CHOICE,
    question:
      "다음 중 안과 처방에서 비가목스 점안액과 크라비트 점안액을 병용하는 이유는 무엇인가요?",

    options: [
      { text: "항균 범위가 다르기 때문에", score: 1 },
      { text: "동일한 항균 범위를 가지기 때문에", score: 0 },
      { text: "부작용을 줄이기 위해서", score: 0 },
      { text: "치료 기간을 단축하기 위해서", score: 0 },
    ],
    comment:
      "비가목스와 크라비트는 같은 퀴놀론계지만 세대별로 항균 범위가 다르기 때문에 병용 처방됩니다.",
  },
  {
    id: 29,
    version: "1.0.1",
    contentType: "board",
    contentId: 10464,
    type: BoardContentQuizType.OX,
    question:
      "케토프로펜 외용제는 고혈압, 당뇨, 위장장애, 간장, 신장질환 등의 합병증을 가진 환자에게도 비교적 안전하게 사용될 수 있다.",
    options: [
      { text: "O", score: 1 },
      { text: "X", score: 0 },
    ],
    comment:
      "케토프로펜 외용제는 피부로 흡수되어 시스템적 부작용이 적으므로, 다양한 합병증을 가진 환자들에게도 비교적 안전하게 사용될 수 있습니다.",
  },
  {
    id: 30,
    version: "1.0.1",
    contentType: "board",
    contentId: 58833,
    type: BoardContentQuizType.CHOICE,
    question:
      "다음 중 점안액의 개봉 후 사용 기간에 대한 설명으로 올바르지 않은 것은?",

    options: [
      {
        text: "엘러간 간포트 점안액은 파우치 개봉 후 15일 이내에 사용한다.",
        score: 1,
      },
      { text: "종근당 도졸점안액은 개봉 후 4주 이내에 사용한다.", score: 0 },
      {
        text:
          "삼일제약 듀얼콥점안액은 첫 개봉 후 2달을 초과하는 경우 사용하지 않는다.",
        score: 0,
      },
      {
        text:
          "노바티스 듀오트라브점안액은 개봉 후 4주가 지나면 남은 액을 버린다.",
        score: 0,
      },
    ],
    comment:
      "엘러간 간포트 점안액은 파우치 개봉 후 10일 이내에 사용해야 합니다.",
  },
  {
    id: 31,
    version: "1.0.1",
    contentType: "board",
    contentId: 1040,
    type: BoardContentQuizType.CHOICE,
    question:
      "임산부에게 변비 치료를 위해 사용할 수 있는 안전한 약물은 무엇인가요?",

    options: [
      { text: "lactulose", score: 1 },
      { text: "마그네슘 제제", score: 0 },
      { text: "senna 및 sennoside", score: 0 },
      { text: "sorbitol, glycerine", score: 0 },
    ],
    comment:
      "lactulose는 삼투압성 변비완화제로 임부에게 안전하게 투여할 수 있으며, 나머지 세개는 임부에게 권장하지 않습니다.",
  },
  {
    id: 32,
    version: "1.0.1",
    contentType: "board",
    contentId: 2543,
    type: BoardContentQuizType.CHOICE,
    question: "임산부가 타미플루를 복용하는 이유는 무엇일까요?",

    options: [
      { text: "모든 위의 이유", score: 1 },
      {
        text: "인플루엔자로 인한 심각한 합병증의 위험을 줄이기 위해",
        score: 0,
      },
      { text: "산모의 고열로 인한 기형아 위험성을 줄이기 위해", score: 0 },
      {
        text: "임신 중 경구 타미플루의 치료가 안전하며 부작용이 없기 때문에",
        score: 0,
      },
    ],
    comment:
      "임산부가 타미플루를 복용하는 이유는 인플루엔자로 인한 심각한 합병증의 위험을 줄이고, 산모의 고열로 인한 기형아 위험성을 줄이며, 임신 중 경구 타미플루의 치료가 안전하며 부작용이 없기 때문입니다.",
  },
  {
    id: 33,
    version: "1.0.1",
    contentType: "board",
    contentId: 28369,
    type: BoardContentQuizType.CHOICE,
    question: "다음 중 소아과 약국에서 맛이 가장 좋지 않은 시럽은?",

    options: [
      { text: "클래리쓰로마이신 시럽", score: 1 },
      { text: "록씨스로마이신 시럽", score: 0 },
      { text: "덱시부프로펜 시럽", score: 0 },
      { text: "아세트아미노펜 시럽", score: 0 },
    ],
    comment:
      "클래리쓰로마이신 시럽은 시럽 중에서도 맛이 가장 좋지 않은 것으로 알려져 있습니다.",
  },
  {
    id: 34,
    version: "1.0.1",
    contentType: "board",
    contentId: 18964,
    type: BoardContentQuizType.CHOICE,
    question:
      "비리어드(테노포비르디소프록실)와 베믈리디(테노포비르알라페나미드)의 주요 차이점은 무엇인가요?",

    options: [
      {
        text: "베믈리디는 비리어드보다 신장과 골 대사에 대한 독성이 감소한다",
        score: 1,
      },
      { text: "베믈리디는 비리어드보다 혈장 반감기가 짧다", score: 0 },
      { text: "베믈리디는 비리어드보다 더 높은 용량으로 투여된다", score: 0 },
      { text: "베믈리디는 비리어드와 동일한 용량으로 투여된다", score: 0 },
    ],
    comment:
      "베믈리디는 테노포비어 전구체(pro-drug)를 이용하여 만들어져, 비리어드보다 낮은 용량으로도 유사한 효과를 내며, 신장과 골 대사에 대한 독성이 감소했습니다",
  },
  {
    id: 35,
    version: "1.0.1",
    contentType: "board",
    contentId: 4520,
    type: BoardContentQuizType.CHOICE,
    question:
      "헬리코박터 3제요법 시 현재 복용중인 약물을 체크할 필요가 없는 환자는?",

    options: [
      { text: "골관절염 환자", score: 1 },
      { text: "고지혈증 환자", score: 0 },
      { text: "전립샘비대증 환자", score: 0 },
      { text: "불면증 환자", score: 0 },
    ],
    comment:
      "클라리스로마이신은 CYP3A4 억제제로, 고지혈증(심바, 로바스타틴 금기), 전립샘비대증(탐수로신, 알푸조신 금기), 불면증(트리아졸람, 알프라졸람 금기)이며, 골관절염에 사용되는 소염진통제 금기는 없기때문에 정답은 4번입니다.",
  },
  {
    id: 36,
    version: "1.0.1",
    contentType: "board",
    contentId: 15916,
    type: BoardContentQuizType.OX,
    question:
      "임신 기간 중 독감 예방 접종을 맞으면 면역 항체가 태아에게 전달되어 출산한 아이가 출산 후 4개월까지 독감에 걸리지 않는다.",
    options: [
      { text: "O", score: 1 },
      { text: "X", score: 0 },
    ],
    comment:
      "임신 기간 중 독감 예방 접종을 맞으면 면역 항체가 태아에게 전달되어 출산한 아이가 출산 후 4개월까지 독감에 걸리지 않습니다.",
  },
  {
    id: 37,
    version: "1.0.1",
    contentType: "board",
    contentId: 2910,
    type: BoardContentQuizType.ORDER,
    question: "다음 점안액들을 올바른 순서대로 점안하는 순서를 맞추세요.",
    options: [
      { text: "용액", score: 1 },
      { text: "현탁액", score: 2 },
      { text: "겔", score: 3 },
      { text: "연고", score: 4 },
    ],
    comment:
      "점안액은 용액부터 시작하여 현탁액, 겔, 연고 순으로 점안하는 것이 올바른 순서입니다.",
  },
  {
    id: 38,
    version: "1.0.1",
    contentType: "board",
    contentId: 58076,
    type: BoardContentQuizType.CHOICE,
    question: "다음 중 마그네슘 제제의 효능으로 올바르지 않은 것은?",

    options: [
      { text: "말초동맥 혈액순환 장애 개선", score: 1 },
      { text: "근육 이완", score: 0 },
      { text: "인슐린 저항성 개선", score: 0 },
      { text: "통증 물질 분비 경감", score: 0 },
    ],
    comment: "말초동맥 혈액순환 장애 개선은 은행엽 제제의 효능입니다.",
  },
  {
    id: 39,
    version: "1.0.1",
    contentType: "board",
    contentId: 2115,
    type: BoardContentQuizType.CHOICE,
    question: "치과에서 슈다페드정을 처방하는 이유는 무엇일까요?",
    options: [
      { text: "구강 상악동 누공 예방", score: 1 },
      { text: "충치 치료", score: 0 },
      { text: "치아 통증 완화", score: 0 },
      { text: "치아 백색화", score: 0 },
    ],
    comment:
      "치아가 원인이 되는 축농증 치료에 사용되며, 부비동을 비우는 역할을 합니다. 발치 후 지혈을 하기 위해 사용하기도 합니다.",
  },
  {
    id: 40,
    version: "1.0.1",
    contentType: "board",
    contentId: 9139,
    type: BoardContentQuizType.CHOICE,
    question: "엑세라민 B에서 통풍 환자가 주의해야하는 영양소는?",
    options: [
      { text: "비타민 B3", score: 1 },
      { text: "비타민 C", score: 0 },
      { text: "비타민 D", score: 0 },
      { text: "비타민 B12", score: 0 },
    ],
    comment:
      "비타민B3는 요산배출을 막아 통풍환자는 주의해야합니다. 또한 비타민 A는 과량일 경우 통풍에 좋지 않다는 정보가 있습니다.",
  },
  {
    id: 41,
    version: "1.0.1",
    contentType: "board",
    contentId: 4957,
    type: BoardContentQuizType.CHOICE,
    question: "다음 중 어린이들이 사용해도 되는 파스는?",
    options: [
      { text: "제놀 쿨", score: 1 },
      { text: "케토톱", score: 0 },
      { text: "케펜텍", score: 0 },
      { text: "트라스트", score: 0 },
    ],
    comment:
      "제놀 쿨은 소염진통제 성분이 없고 멘톨, 캄파 성분 위주로, 3세 이하만 금기이므로 어린이들이 사용할 수 있습니다.",
  },
  {
    id: 42,
    version: "1.0.1",
    contentType: "board",
    contentId: 21547,
    type: BoardContentQuizType.CHOICE,
    question: "마그밀과 마그밀에스의 주요 차이점은 무엇인가요?",
    options: [
      { text: "흡수율", score: 1 },
      { text: "함량", score: 0 },
      { text: "제조 비용", score: 0 },
      { text: "제형", score: 0 },
    ],
    comment: "마그밀(Mg(OH)2)과 마그밀에스(MgO)의 주요 차이점은 흡수율입니다.",
  },
];
