import React, { Component } from "react";
import { connect } from "react-redux";
import { actions, RootState } from "../../store";
import { withRouter, RouteComponentProps } from "react-router";
import "./Chat.css";
import "./../../Common.css";
// import './../Common.css';
import { log, LogLevel } from "../../utils/LogUtil";
import * as API from "../../API.json";
import styled from "styled-components";
import Text from "../atoms/Text";
import { GroupChannel, GroupChannelListQuery, SendBirdError } from "sendbird";
import axios from "axios";

import {
  Channel,
  sendBirdSelectors,
  SendBirdState,
  withSendBird,
} from "sendbird-uikit";
import "sendbird-uikit/dist/index.css";
import ABTestUtil from "../../utils/ABTestUtil";
import { UserWorkType } from "../../models/Model.User";
import {
  getPayString,
  JobOfferInfo,
  JobOfferName,
  JobOfferStatus,
} from "../../models/Model.JobPost";
import AnalyticsUtil from "../../utils/AnalyticsUtil";
import { callPhone } from "../../utils/UrlSchemeUtil";
import { fetchAPI } from "../../utils/APIUtil";
import { getGlobal, GlobalKey } from "../../utils/GlobalUtil";
import { getDistanceString } from "../../utils/LocationUtil";
import { JobListType } from "../../store/jobpost/types";
import { COLOR_SYSTEM } from "../design/design-system";
import TopAppBar from "../molecules/TopAppBar/TopAppBar";
import Button from "../atoms/Button";
import Icon from "../atoms/Icon";
import { parse } from "date-fns";
const queryString = require("query-string");

type Props = RouteComponentProps &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapSendbirdStateToProps>;

type State = {
  name: string;
  phone: string;
  offer: JobOfferInfo;
};

const windowAny: any = window;

const Header = styled.header`
  background-color: ${COLOR_SYSTEM.get("Gray")[0]};
  width: 100%;
`;

const Main = styled.div`
  min-height: calc(100vh - 48px);
  background-color: ${COLOR_SYSTEM.get("Gray")[0]};
`;

const ChatHeader = styled.div`
  height: 100px;
  widht: 100%;
  background-color: ${COLOR_SYSTEM.get("Gray")[10]};
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;

  .information {
    flex-grow: 1;

    .item {
      display: flex;
      flex-direction: row;
      align-items: center;

      .icon {
        margin-right: 12px;
      }
    }
  }
`;

class Chat extends Component<Props, State> {
  loadingChannel: boolean = false;
  loadingData: boolean = false;
  channelUrl: string = "";
  isOwner = false;
  meta: any = null;

  constructor(props: Props) {
    super(props);
    log(LogLevel.UI_LIFECYCLE, "Chat:constructor", props);

    let qs = queryString.parse(this.props.location.search);
    if (qs.offerId) {
      if (
        this.props.offers &&
        this.props.offers[parseInt(qs.offerId)] &&
        this.props.sdk &&
        this.props.sdk.GroupChannel
      ) {
        log(
          LogLevel.UI_LIFECYCLE,
          "Chat:constructor findOrCreateChannel start "
        );
        // if no chat id, make new chat and replace it.
        this.findOrCreateChannel(parseInt(qs.offerId));
      } else this.props.loadJobOffer({}, parseInt(qs.offerId));
    } else if (qs.id) this.channelUrl = qs.id;
    else if (props.location.state && props.location.state.url)
      this.channelUrl = props.location.state.url;
    this.state = {
      name: "",
      phone: "",
      offer: null,
    };
    this.loadChannel();
    this.loadData();
  }

  componentDidMount() {
    log(LogLevel.UI_LIFECYCLE, "Chat:componentDidMount");
  }

  componentDidUpdate(prevProps, prevState) {
    let qs = queryString.parse(this.props.location.search);
    if (qs.offerId) {
      // log(
      //   LogLevel.UI_LIFECYCLE,
      //   "Chat:componentDidUpdate ",
      //   prevProps.location.search != this.props.location.search,
      //   (!prevProps.offers[parseInt(qs.offerId)] ||
      //     prevProps.offers[parseInt(qs.offerId)].loading) &&
      //     prevProps.offers[parseInt(qs.offerId)] !=
      //       this.props.offers[parseInt(qs.offerId)] &&
      //     !this.props.offers[parseInt(qs.offerId)].loadin,
      //   (!prevProps.sdk || !prevProps.sdk.GroupChannel) &&
      //     this.props.sdk &&
      //     !!this.props.sdk.GroupChannel,

      //   "conditions : ",
      //   !!this.props.offers,
      //   !!this.props.offers[parseInt(qs.offerId)],
      //   this.props.offers[parseInt(qs.offerId)] &&
      //     !this.props.offers[parseInt(qs.offerId)].loading,
      //   !!this.props.sdk,
      //   !!this.props.sdk.GroupChannel
      // );
      if (
        prevProps.location.search != this.props.location.search ||
        ((!prevProps.offers[parseInt(qs.offerId)] ||
          prevProps.offers[parseInt(qs.offerId)].loading) &&
          prevProps.offers[parseInt(qs.offerId)] !=
            this.props.offers[parseInt(qs.offerId)] &&
          !this.props.offers[parseInt(qs.offerId)].loading) ||
        ((!prevProps.sdk || !prevProps.sdk.GroupChannel) &&
          this.props.sdk &&
          this.props.sdk.GroupChannel)
      ) {
        if (
          this.props.offers &&
          this.props.offers[parseInt(qs.offerId)] &&
          !this.props.offers[parseInt(qs.offerId)].loading &&
          this.props.sdk &&
          this.props.sdk.GroupChannel
        ) {
          log(
            LogLevel.UI_LIFECYCLE,
            "Chat:componentDidUpdate findOrCreateChannel start "
          );
          // if no chat id, make new chat and replace it.
          this.findOrCreateChannel(parseInt(qs.offerId));
        }
      }
    }

    if (prevProps.location.search != this.props.location.search) {
      let qs = queryString.parse(this.props.location.search);
      if (qs.id && this.channelUrl != qs.id) this.channelUrl = qs.id;
      else if (
        this.props.location.state &&
        this.props.location.state.url &&
        this.channelUrl != this.props.location.state.url
      )
        this.channelUrl = this.props.location.state.url;
    }

    this.loadChannel();
    this.loadData();
  }

  componentWillUnmount(): void {
    if (this.channelUrl) {
      if (this.meta.type == "JOBPOST" && this.props.me.id != this.meta.ouid) {
        this.props.reloadMyLike();
      }

      fetchAPI(
        API.JOBPOST_CHAT_READ,
        "",
        null,
        {
          channelId: this.channelUrl,
          user: this.isOwner ? "owner" : "applier",
        },
        getGlobal(GlobalKey.TOKEN)
      ).then((result) => {
        if (result && !result.error) {
          this.props.setChatUnreadCount(result.hasUnread ? 1 : 0);
        }
      });
    }
  }

  findOrCreateChannel = async (offerId: number) => {
    log(LogLevel.UI_LIFECYCLE, "Chat:findOrCreateChannel");
    let target = "";
    if (ABTestUtil.isDev()) {
      target = "DEV";
    }

    const offer = this.props.offers[offerId];

    let name = target + "_jobpost_" + offer.id + "_" + this.props.me.id + "_";
    const listQuery: GroupChannelListQuery = this.props.sdk.GroupChannel.createMyGroupChannelListQuery();
    listQuery.includeEmpty = false;
    listQuery.channelNameContainsFilter = name; // 'all', 'joined_only', 'invited_only', 'invited_by_friend', and 'invited_by_non_friend'
    listQuery.order = "latest_last_message"; // 'chronological', 'latest_last_message', 'channel_name_alphabetical', and 'metadata_value_alphabetical'
    listQuery.limit = 1; // The value of pagination limit could be set up to 100.

    try {
      if (listQuery.hasNext) {
        let groupChannels: Array<GroupChannel> = await listQuery.next(null);
        log(
          LogLevel.UI_ACTION,
          "Chat:findOrCreateChannel:listQuery.next ",
          groupChannels,
          this.props.sdk
        );

        if (groupChannels && groupChannels.length) {
          log(
            LogLevel.UI_ACTION,
            "Chat:findOrCreateChannel:listQuery.next success ",
            groupChannels[0]
          );
          this.props.history.replace("/chat?id=" + groupChannels[0].url);
          AnalyticsUtil.event(
            AnalyticsUtil.TYPE_ALL,
            "JOBPOST_CHAT",
            "구인구직_공고_채팅진입",
            { isNew: false }
          );
          return;
        }
      }
      log(
        LogLevel.UI_ACTION,
        "Chat:findOrCreateChannel:listQuery no existing chat thread -> create",
        offer,
        name
      );

      // no existing chat thread -> create
      let newChannel = await this.props.sdk.GroupChannel.createChannelWithUserIds(
        [
          target + offer.ownerId.toString(),
          target + this.props.me.id.toString(),
        ],
        false,
        name,
        "",
        JSON.stringify({
          type: "JOBPOST",
          id: offer.id,
          pharmacyName: offer.pharmacy.name,
          ouid: offer.ownerId,
          auid: this.props.me.id,
          title: offer.title,
          salary: getPayString(offer),
        }),
        "",
        async (groupChannel: GroupChannel, error: SendBirdError) => {
          // if(ABTestUtil.isTest()){
          let message = {
            message_type: "ADMM",
            message: "[채팅팁]",
            custom_type: "notice",
            is_silent: true,
          };
          await axios.post(
            `https://api-39C5C867-4869-40DA-9D12-9840569F454F.sendbird.com/v3/group_channels/${groupChannel.url}/messages`,
            message,
            {
              headers: {
                "Content-Type": "application/json; charset=utf8",
                "Api-Token": "337f47ae80261965f94de78e95498c6d88b4a3d3",
              },
            }
          );
          message = {
            message_type: "ADMM",
            message: "채팅은 실명으로 진행되어요.",
            custom_type: "notice",
            is_silent: true,
          };
          await axios.post(
            `https://api-39C5C867-4869-40DA-9D12-9840569F454F.sendbird.com/v3/group_channels/${groupChannel.url}/messages`,
            message,
            {
              headers: {
                "Content-Type": "application/json; charset=utf8",
                "Api-Token": "337f47ae80261965f94de78e95498c6d88b4a3d3",
              },
            }
          );
          message = {
            message_type: "ADMM",
            message: "우측상단의 📞를 눌러서 상대방에게 전화를 걸 수 있어요.",
            custom_type: "notice",
            is_silent: true,
          };
          await axios.post(
            `https://api-39C5C867-4869-40DA-9D12-9840569F454F.sendbird.com/v3/group_channels/${groupChannel.url}/messages`,
            message,
            {
              headers: {
                "Content-Type": "application/json; charset=utf8",
                "Api-Token": "337f47ae80261965f94de78e95498c6d88b4a3d3",
              },
            }
          );
          message = {
            message_type: "ADMM",
            message: "전화걸기 전엔 꼭 상대의 동의를 구하세요 ^^",
            custom_type: "notice",
            is_silent: true,
          };
          await axios.post(
            `https://api-39C5C867-4869-40DA-9D12-9840569F454F.sendbird.com/v3/group_channels/${groupChannel.url}/messages`,
            message,
            {
              headers: {
                "Content-Type": "application/json; charset=utf8",
                "Api-Token": "337f47ae80261965f94de78e95498c6d88b4a3d3",
              },
            }
          );
          // }
        }
      );

      log(
        LogLevel.UI_LIFECYCLE,
        "Chat:findOrCreateChannel:listQuery.create success ",
        newChannel
      );
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "JOBPOST_CHAT",
        "구인구직_공고_채팅진입",
        { isNew: true }
      );

      this.props.history.replace("/chat?id=" + newChannel.url);
    } catch (e) {
      log(LogLevel.UI_EXCEPTION, "Chat:findOrCreateChannel:exception ", e);
    }
  };

  loadChannel = async () => {
    if (!this.channelUrl || this.meta || this.loadingChannel) return;
    this.loadingChannel = true;
    if (
      this.props.sdk &&
      this.props.sdk.GroupChannel &&
      this.props.sdk.GroupChannel.getChannel
    ) {
      let channel = await this.props.sdk.GroupChannel.getChannel(
        this.channelUrl
      );
      let meta = JSON.parse(channel.data);
      // let meta:any = await channel.getAllMetaData();

      log(LogLevel.UI_ACTION, "Chat:loadChannel meta", meta);
      // type:"JOBPOST",
      // id: props.offer.id,
      // pharmacyName: props.offer.pharmacy.name,
      // ouid: props.offer.ownerId,
      // title: props.offer.title,
      // salary: getPayString(props.offer),
      if (meta && meta.type) {
        log(LogLevel.UI_ACTION, "Chat:loadChannel meta success", meta.type);
        this.meta = meta;

        let target = "";
        if (ABTestUtil.isDev()) {
          target = "DEV";
        }

        if (this.props.me.id == meta.ouid) {
          let memberListQuery = channel.createMemberListQuery();
          memberListQuery.next((users, error) => {
            log(
              LogLevel.UI_ACTION,
              "Chat:loadChannel users check 1",
              this.props.me.id,
              meta.ouid,
              users
            );
            for (let i = 0; i < users.length; i++) {
              let user = users[i];
              if (
                !user.userId.startsWith("admin") &&
                user.userId != target + this.props.me.id.toString()
              ) {
                log(LogLevel.UI_ACTION, "Chat:loadChannel member", user);
                this.setState({
                  name: user.metaData["name"] + " 약사",
                  phone: user.metaData["phone"],
                });
                break;
              }
            }
          });
          this.isOwner = true;
        } else {
          let memberListQuery = channel.createMemberListQuery();
          memberListQuery.next((users, error) => {
            log(
              LogLevel.UI_ACTION,
              "Chat:loadChannel users check 2",
              this.props.me.id,
              meta.ouid,
              users
            );
            for (let i = 0; i < users.length; i++) {
              let user = users[i];
              if (
                !user.userId.startsWith("admin") &&
                user.userId != target + this.props.me.id.toString()
              ) {
                log(LogLevel.UI_ACTION, "Chat:loadChannel member", user);
                this.setState({
                  name: meta.pharmacyName,
                  phone: user.metaData["phone"],
                });
                break;
              }
            }
          });
          this.isOwner = false;
        }

        fetchAPI(
          API.JOBPOST_CHAT_READ,
          "",
          null,
          {
            channelId: this.channelUrl,
            user: this.isOwner ? "owner" : "applier",
          },
          getGlobal(GlobalKey.TOKEN)
        ).then((result) => {
          if (result && !result.error) {
            this.props.setChatUnreadCount(result.hasUnread ? 1 : 0);
          }
        });
      }
    }
    this.loadingChannel = false;
  };

  loadData = async () => {
    if (this.loadingData || this.state.offer || !this.meta || !this.meta.id)
      return;

    this.loadingData = true;
    if (this.meta.type == "JOBPOST") {
      if (!this.props.offers[this.meta.id]) {
        let options: any = {};

        if (
          this.props.me.workType == UserWorkType.PHARMACY_OWNER &&
          this.props.me.workPlacePosition &&
          this.props.me.workPlacePosition.x &&
          this.props.me.workPlacePosition.y
        ) {
          options.positionx = this.props.me.workPlacePosition.x;
          options.positiony = this.props.me.workPlacePosition.y;
        } else if (
          this.props.me.workType != UserWorkType.PHARMACY_OWNER &&
          this.props.me.homePosition &&
          this.props.me.homePosition.x &&
          this.props.me.homePosition.y
        ) {
          options.positionx = this.props.me.homePosition.x;
          options.positiony = this.props.me.homePosition.y;
        }

        this.props.loadJobOffer(options, this.meta.id);
      } else if (!this.props.offers[this.meta.id].loading) {
        let offer: JobOfferInfo = this.props.offers[this.meta.id];
        this.setState({ offer });

        if (!this.isOwner && offer.owner && offer.owner.phone)
          this.setState({ phone: offer.owner.phone });
      }
    }

    this.loadingData = false;
  };

  onCall = async () => {
    if (!this.state.phone) return;

    if (this.state.offer) {
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "JOBPOST_PHARMACY_INQUERY",
        "구인구직_약국_공고_문의_전화",
        {
          offerId: this.state.offer.id,
        }
      );
      await fetchAPI(
        API.JOBPOST_INQUERY,
        this.state.offer.id.toString(),
        null,
        null,
        getGlobal(GlobalKey.TOKEN)
      );
    }
    callPhone(this.state.phone);
  };

  goBack = (e) => {
    log(LogLevel.UI_ACTION, "Chat:componentDidMount");

    if (this.props.history.length > 1) this.props.history.goBack();
    else this.props.history.replace("/");
  };

  onClickJobOffer = () => {
    log(LogLevel.UI_LIFECYCLE, "Home.render", this.state);
    if (this.state.offer) {
      this.props.history.push("/jobpost/view?id=" + this.state.offer.id);
    }
  };

  render() {
    log(LogLevel.UI_LIFECYCLE, "Home.render", this.state);

    return (
      <>
        <Header>
          <TopAppBar
            title={this.state.name ? this.state.name : ""}
            titleType="H4"
            leftButtons={[
              <Button
                color="Tertiary"
                icon="CaretLeft"
                onClick={this.goBack}
                size="Medium"
                type="Icon"
                variant="Ghost"
              />,
            ]}
            rightButtons={[
              <Button
                color="Tertiary"
                icon="Call"
                onClick={this.onCall}
                size="Medium"
                type="Icon"
                variant="Ghost"
              />,
            ]}
          />
        </Header>
        <Main>
          <Channel
            channelUrl={this.channelUrl}
            renderChatHeader={this.renderChatHeader}
            useReaction={false}
            disableUserProfile={true}
            showSearchIcon={false}
            displayNameType="name"
            displayNamePostfix=" 약사"
          />
        </Main>
        <div className="common-content-with-header"></div>
      </>
    );
  }

  renderChatHeader = () => {
    if (this.state.offer) {
      return (
        <ChatHeader onClick={this.onClickJobOffer}>
          <div className="information">
            <div className="item">
              <Icon
                name="MapPin"
                className="icon"
                size={16}
                color={COLOR_SYSTEM.get("Gray")[200]}
              />
              <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[700]}>
                {" "}
                {this.state.offer.pharmacy.region}{" "}
                {this.state.offer.distance > 0
                  ? getDistanceString(this.state.offer.distance)
                  : ""}{" "}
              </Text>
            </div>
            <div className="item">
              <Icon
                name="Won"
                className="icon"
                size={16}
                color={COLOR_SYSTEM.get("Gray")[200]}
              />
              <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[700]}>
                {getPayString(this.state.offer)}{" "}
              </Text>
            </div>
          </div>
          <Button
            size="Medium"
            variant="Ghost"
            color="Primary"
            onClick={() => {}}
            type="Text"
          >
            공고 보기
          </Button>
        </ChatHeader>
      );
    }

    return null;
  };
}

const mapStateToProps = (state: RootState) => ({
  me: state.user.me,
  offers: state.jobpost.offers,
});

const mapDispatchToProps = {
  loadJobOffer: (option, id) => actions.jobpost.loadOffer(option, id),
  reloadMyLike: () =>
    actions.jobpost.reloadList(null, JobListType.OFFER | JobListType.MY_LIKE),
  setChatUnreadCount: (count: number) =>
    actions.notification.chatUnreadCountSet(count),
};

const mapSendbirdStateToProps = (state: SendBirdState) => ({
  sdk: sendBirdSelectors.getSdk(state),
});

export default withSendBird(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Chat)),
  mapSendbirdStateToProps
);
