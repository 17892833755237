import { BoardContent, Attachment } from "../models/Model.Board";
import { LogLevel, log } from "./LogUtil";
import { fetchAPI } from "./APIUtil";
import * as API from "./../API.json";
import { getGlobal, GlobalKey } from "./GlobalUtil";
import StringUtil from "./StringUtil";
import { UserInterests } from "../models/Model.User";
import { TARGET } from "./../config.json";

export enum ABTestFeature {
  REL_FREEZE = -1,

  DEV_TEST = 0,

  HASHTAG = REL_FREEZE, //1,
  NUDGE = REL_FREEZE, //2,

  DISPOSABLE_SALE = 3,
  DISABLE_OPEN_PAGE_SHARE = REL_FREEZE, //4,
  ONLINE_SEMINAR = REL_FREEZE, //5,

  GROUD_RULE = 6,
  APPLE_LOGIN = REL_FREEZE, //7,

  SALE = 8,
  CHAT = REL_FREEZE,
  SUBCATEGORY = REL_FREEZE,

  UI_BOARD_UPDATED = REL_FREEZE, //100,
  UI_COMMENT_REPLY = REL_FREEZE, //101,
  UI_MOVE_TO_TOP = REL_FREEZE, //102,
  UI_VIEW_UPDATED = REL_FREEZE, //103,
  UI_COMPOSER_AUTO_SAVE = 104,
  UI_GAMIFICATION_WEEKLY_REPORT = REL_FREEZE, //105,
  UI_VIEWER_RECOMMEND = REL_FREEZE, //106,
  UI_VIEWER_RECOMMEND_OPTIONS = 107, //PERMANENT TEST OPTION
  UI_COMPOSER_RECOMMEND = 108,
  UI_SEMINAR_LIST_VIEW = REL_FREEZE, //109,

  UI_MENU = REL_FREEZE, //110,
  UI_JOB_POST = REL_FREEZE, //111,
  UI_JOB_POST_CHANNEL_TALK = REL_FREEZE, //112,
  UI_LOCAL_DEAL = 114,
  UI_LOCAL_DEAL_USED_MARKET = 115,
  UI_CF_SEARCH = 113,
  UI_JOB_POST_DAILY_PHARM_AUTO_POST = 116,
  UI_SALE_MY_PAGE = 117,
  UI_INVITE_BIZ = 118,
  UI_INVITE_EVENT = 119,

  UI_PHARMACY_ALL_IN_ONE = REL_FREEZE,
  UI_YMYD_PARTNERS = 121,
  UI_PARTNERS_UNION_BOARD_DEFAULT = 122,

  UI_GPT_SUMMARY = 130,
  UI_PREMIUM_USER = 131,
  UI_APP_ADS = 132,

  UI_PHARMACY_PREMIUM = 133,
  UI_QUIZ = 134,
}

export default class ABTestUtil {
  static id: number = 0;
  static devTestFeatures = [
    ABTestFeature.DEV_TEST,
    ABTestFeature.UI_COMPOSER_RECOMMEND,
    // ABTestFeature.GROUD_RULE,
    ABTestFeature.UI_VIEWER_RECOMMEND_OPTIONS,
    // ABTestFeature.UI_CF_SEARCH,
    // ABTestFeature.UI_INVITE_BIZ,
    // ABTestFeature.UI_APP_ADS,
    ABTestFeature.UI_QUIZ,
  ];
  static enabledFeatures = [
    ABTestFeature.UI_GPT_SUMMARY,
    ABTestFeature.UI_PREMIUM_USER,
    ABTestFeature.SALE,
    ABTestFeature.UI_LOCAL_DEAL,
    ABTestFeature.UI_SALE_MY_PAGE,
    ABTestFeature.UI_PARTNERS_UNION_BOARD_DEFAULT,
    ABTestFeature.UI_INVITE_EVENT,
    ABTestFeature.UI_YMYD_PARTNERS,
    ABTestFeature.UI_PHARMACY_PREMIUM,
  ];

  static isTest(feature: ABTestFeature = ABTestFeature.DEV_TEST): boolean {
    if (ABTestFeature.REL_FREEZE == feature) return true;
    let rvalue = false;
    rvalue = ABTestUtil.enabledFeatures.includes(feature);
    if (
      !rvalue &&
      (getGlobal(GlobalKey.ENABLE_TEST, true) ||
        TARGET == "dev" ||
        TARGET == "local")
    ) {
      rvalue = ABTestUtil.devTestFeatures.includes(feature);
    }
    return rvalue;
  }

  static isDev(): boolean {
    if (TARGET == "dev" || TARGET == "local") {
      return true;
    }
    return false;
  }

  static isLocal(): boolean {
    if (TARGET == "local") {
      return true;
    }
    return false;
  }
}
