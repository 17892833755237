import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { actions, RootState } from "../../store";
import { log, LogLevel } from "../../utils/LogUtil";
import queryString from "query-string";
import { getPayString, JobOfferName } from "../../models/Model.JobPost";
import { fetchAPI } from "../../utils/APIUtil";
import {
  clearGlobal,
  getGlobal,
  GlobalKey,
  setGlobal,
} from "../../utils/GlobalUtil";
import * as API from "../../API.json";
import AnalyticsUtil from "../../utils/AnalyticsUtil";
import PremiumUserSubscribe from "../../components/templates/PremiumUserSubscribe";
import {
  PremiumType,
  UserPremiumInfo,
  UserPremiumPurchasedInfo,
} from "../../models/Model.User.Premium";
import { getDateStringFromToday } from "../../utils/TimeUtil";
import { set } from "lodash";
import PremiumUserDetail from "../../components/templates/PremiumUserDetail";
import { UIPopupType } from "../../store/ui/types";
import PremiumUserSubscribeComplete from "../../components/templates/PremiumUserSubscribeComplete";
import ABTestUtil from "../../utils/ABTestUtil";
import { UserLevel } from "../../models/Model.User";

const windowAny: any = window;

const PremiumUserSubscribePage: React.FC<RouteComponentProps> = ({
  history,
  location,
  match,
}) => {
  const dispatch = useDispatch();
  const me = useSelector((state: RootState) => state.user.me);
  const waitingPopup = useSelector(
    (state: RootState) => state.ui.popups[UIPopupType.WAITING_POPUP]
  );

  let donations = [5000, 10000, 20000, 30000];
  if (ABTestUtil.isDev() || me.level >= UserLevel.MANAGER)
    donations = [100, 5000, 10000, 20000, 30000];

  const loading = useRef<boolean>(false);

  useEffect(() => {
    log(
      LogLevel.UI_ACTION,
      "PremiumUserSubscribePage:useEffect mount",
      me,
      history,
      location
    );
    windowAny.IMP.init("imp68909306");
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "PREMIUM_PAGE",
      "유료회원_가입진입",
      {}
    );
  }, []);

  const goBack = useCallback(() => {
    history.replace(
      location && location.state && location.state.from
        ? location.state.from
        : "/"
    );
  }, [history]);

  const viewBenefit = () => {
    window.open(
      "https://bit.ly/ymyd_benefit",
      getGlobal(GlobalKey.OS) == "browser" ? "_blank" : "_system"
    );
  };

  const subscribe = useCallback(
    async (amount: number) => {
      log(LogLevel.UI_ACTION, "PremiumUserSubscribePage:subscribe", me);
      if (loading.current) return;
      loading.current = true;
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "PREMIUM_PAGE",
        "후원회원가입_시작",
        { amount }
      );
      waitingPopup.show();

      // step1. 후원 정보 생성
      let premiumInfo: UserPremiumInfo = {
        type: PremiumType.DONOR,
        amount,
      };

      let premiumResult = await fetchAPI(
        API.USER_PREMIUM_POST,
        "",
        null,
        premiumInfo,
        getGlobal(GlobalKey.TOKEN)
      );

      if (premiumResult && !premiumResult.error) {
        premiumInfo.id = premiumResult.data;
      } else {
        loading.current = false;
        waitingPopup.hide();
        alert("후원 정보 생성 실패, 관리자에게 문의하세요.");
        return;
      }

      const merchant_uid = `ymyd_premium_U${me.id}_P${premiumInfo.id}_${
        Math.floor(new Date().getTime() / 1000) % 10000000
      }`;

      const from = getDateStringFromToday({ separator: "" }); //YYYYMMDD
      const to = getDateStringFromToday({ days: -1, months: 1, separator: "" }); //YYYYMMDD

      // step2. 결제 정보 생성
      let premiumPayment: UserPremiumPurchasedInfo = {
        userId: me.id,
        userPremiumId: premiumInfo.id,
        payMerchantId: merchant_uid,
        startAt: from,
        endAt: to,
      };

      let premiumPaymentResult = await fetchAPI(
        API.USER_PREMIUM_PAYMENT_POST,
        "",
        null,
        premiumPayment,
        getGlobal(GlobalKey.TOKEN)
      );

      if (!premiumPaymentResult || premiumPaymentResult.error) {
        loading.current = false;
        waitingPopup.hide();
        alert("결제 정보 생성 실패, 관리자에게 문의하세요.");
        return;
      }

      if (premiumResult && !premiumResult.error) {
        premiumInfo.id = premiumResult.data;
      } else {
        loading.current = false;
        waitingPopup.hide();
        alert("후원 정보 생성 실패");
        return;
      }

      // step3. 결제 진행
      const pg = "danal_tpay" + (ABTestUtil.isDev() ? ".9810030929" : "");
      const name = "약문약답 정기 후원";
      const buyer_email = me.email;
      const buyer_name = me.name;
      const buyer_tel = me.phone;
      const customer_uid = `YP_U${me.id}_P${premiumInfo.id}`; // YMYD Premium
      // const notice_url = PAYMENT_HOOK
      //   ? `${PAYMENT_HOOK}/api/v1/sale/purchased`
      //   : `${SERVER_ADDRESS}/api/v1/sale/purchased`; // webhook

      const custom_data = {
        type: "ymyd_premium",
        userId: me.id,
        premiumId: premiumInfo.id,
        action: "register",
      };

      var data: any = {
        pg, // PG사
        pay_method: "card", // 결제수단
        name, // 주문명
        merchant_uid, // 주문번호
        amount, // 결제금액
        // amount: 100,                              // 결제금액
        buyer_name, // 구매자 이름
        buyer_tel, // 구매자 연락처
        buyer_email, // 구매자 이메일
        customer_uid,
        custom_data,
        period: {
          from, //YYYYMMDD
          to, //YYYYMMDD
        },
      };

      if (getGlobal(GlobalKey.OS) == "browser") {
        windowAny.IMP.request_pay(data, (rsp) =>
          onPaymentDone(rsp, "후원회원가입", "/premium/done/subscribe")
        );
      } else {
        loading.current = false;
        waitingPopup.hide();
        var titleOptions = {
          text: "결제진행", // 타이틀
          textColor: "#ffffff", // 타이틀 색
          textSize: "20", // 타이틀 크기
          textAlignment: "left", // 타이틀 정렬 유형
          backgroundColor: "#344e81", // 타이틀 배경색
          show: true, // 타이틀 유무
          leftButtonType: "back", // 왼쪽 버튼 유형
          leftButtonColor: "#ffffff", // 왼쪽 버튼 색
          rightButtonType: "close", // 오른쪽 버튼 유형
          rightButtonColor: "#ffffff", // 오른쪽 버튼 색
        };
        var userCode = "imp68909306"; // 가맹점 식별코드
        data.app_scheme = "kakao845dd8ac648986a8631ecadc339a7acf"; // 앱 URL 스킴
        var params = {
          titleOptions: titleOptions, // 타이틀 옵션
          userCode: userCode, // 가맹점 식별코드
          data: data, // 결제 데이터
          callback: (rsp) =>
            onPaymentDone(rsp, "후원회원가입", "/premium/done/subscribe"),
        };
        windowAny.cordova.plugins.IamportCordova.payment(params);
      }
    },
    [me]
  );

  const unsubscribe = useCallback(async () => {
    log(LogLevel.UI_ACTION, "PremiumUserSubscribePage:subscribe", me);
    if (loading.current) return;
    waitingPopup.show();

    try {
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "PREMIUM_PAGE",
        "유료회원_해지_신청",
        {}
      );

      let premiumPaymentResult = await fetchAPI(
        API.USER_PREMIUM_PAYMENT_DELETE,
        "",
        null,
        null,
        getGlobal(GlobalKey.TOKEN)
      );
      dispatch(actions.user.login());
      waitingPopup.hide();

      history.replace("/premium/done/unsubscribe", location.state);
    } catch (e) {
      waitingPopup.hide();
    }
  }, [me]);

  const changeCard = useCallback(async () => {
    log(LogLevel.UI_ACTION, "PremiumUserSubscribePage:subscribe", me, history);
    if (!me.premium || loading.current) return;
    loading.current = true;
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "PREMIUM_PAGE",
      "후원카드변경_시작",
      {}
    );

    waitingPopup.show();
    console.log(history);

    const from = getDateStringFromToday({ separator: "" }); //YYYYMMDD
    const to = getDateStringFromToday({ days: -1, months: 1, separator: "" }); //YYYYMMDD

    // step3. 빌링키 업데이트 진행
    const pg = "danal_tpay" + (ABTestUtil.isDev() ? ".9810030929" : "");
    const name = "약문약답 정기 후원 카드 변경";
    const buyer_email = me.email;
    const buyer_name = me.name;
    const buyer_tel = me.phone;
    const customer_uid = `YP_U${me.id}_P${me.premium.id}`; // YMYD Premium
    // const notice_url = PAYMENT_HOOK
    //   ? `${PAYMENT_HOOK}/api/v1/sale/purchased`
    //   : `${SERVER_ADDRESS}/api/v1/sale/purchased`; // webhook

    const custom_data = {
      type: "ymyd_premium",
      userId: me.id,
      premiumId: me.premium.id,
      action: "change",
    };

    var data: any = {
      pg, // PG사
      pay_method: "card", // 결제수단
      name, // 주문명
      amount: 0, // 결제금액
      buyer_name, // 구매자 이름
      buyer_tel, // 구매자 연락처
      buyer_email, // 구매자 이메일
      customer_uid,
      custom_data,
      // period: {
      //   from, //YYYYMMDD
      //   to, //YYYYMMDD
      // },
    };

    if (getGlobal(GlobalKey.OS) == "browser") {
      windowAny.IMP.request_pay(data, (rsp) =>
        onPaymentDone(rsp, "후원카드변경", "/premium/done/subscribe")
      );
    } else {
      loading.current = false;
      waitingPopup.hide();
      var titleOptions = {
        text: "결제진행", // 타이틀
        textColor: "#ffffff", // 타이틀 색
        textSize: "20", // 타이틀 크기
        textAlignment: "left", // 타이틀 정렬 유형
        backgroundColor: "#344e81", // 타이틀 배경색
        show: true, // 타이틀 유무
        leftButtonType: "back", // 왼쪽 버튼 유형
        leftButtonColor: "#ffffff", // 왼쪽 버튼 색
        rightButtonType: "close", // 오른쪽 버튼 유형
        rightButtonColor: "#ffffff", // 오른쪽 버튼 색
      };
      var userCode = "imp68909306"; // 가맹점 식별코드
      data.app_scheme = "kakao845dd8ac648986a8631ecadc339a7acf"; // 앱 URL 스킴
      var params = {
        titleOptions: titleOptions, // 타이틀 옵션
        userCode: userCode, // 가맹점 식별코드
        data: data, // 결제 데이터
        callback: (rsp) =>
          onPaymentDone(rsp, "후원카드변경", "/premium/done/subscribe"),
      };
      windowAny.cordova.plugins.IamportCordova.payment(params);
    }
  }, [me]);

  const resubscribe = useCallback(
    async (amount) => {
      log(
        LogLevel.UI_ACTION,
        "PremiumUserSubscribePage:resubscribe",
        me,
        history
      );
      if (!me.premium || loading.current) return;
      loading.current = true;
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "PREMIUM_PAGE",
        "후원재가입_시작",
        {}
      );

      waitingPopup.show();

      // step1. 후원 정보 생성
      let premiumInfo: UserPremiumInfo = {
        type: PremiumType.DONOR,
        amount,
      };

      let premiumResult;
      try {
        premiumResult = await fetchAPI(
          API.USER_PREMIUM_POST,
          "",
          null,
          premiumInfo,
          getGlobal(GlobalKey.TOKEN)
        );
      } catch (e) {
        log(
          LogLevel.UI_ACTION,
          "PremiumUserSubscribePage:resubscribe API.USER_PREMIUM_POST failed",
          e
        );
      }

      if (premiumResult && !premiumResult.error) {
        premiumInfo.id = premiumResult.data;
      } else {
        loading.current = false;
        waitingPopup.hide();
        alert("후원 정보 생성 실패, 관리자에게 문의하세요.");
        return;
      }

      // step3. 빌링키 업데이트 진행
      const pg = "danal_tpay" + (ABTestUtil.isDev() ? ".9810030929" : "");
      const name = "약문약답 정기 후원";
      const buyer_email = me.email;
      const buyer_name = me.name;
      const buyer_tel = me.phone;
      const customer_uid = `YP_U${me.id}_P${premiumInfo.id}`; // YMYD Premium
      // const notice_url = PAYMENT_HOOK
      //   ? `${PAYMENT_HOOK}/api/v1/sale/purchased`
      //   : `${SERVER_ADDRESS}/api/v1/sale/purchased`; // webhook

      const custom_data = {
        type: "ymyd_premium",
        userId: me.id,
        premiumId: premiumInfo.id,
        action: "renewal",
        reservedAt: me.premium.purchases[0].reservedAt,
        startAt: me.premium.purchases[0].startAt,
        endAt: me.premium.purchases[0].endAt,
      };

      var data: any = {
        pg, // PG사
        pay_method: "card", // 결제수단
        name, // 주문명
        amount: 0, // 결제금액
        buyer_name, // 구매자 이름
        buyer_tel, // 구매자 연락처
        buyer_email, // 구매자 이메일
        customer_uid,
        custom_data,
      };

      if (getGlobal(GlobalKey.OS) == "browser") {
        windowAny.IMP.request_pay(data, (rsp) =>
          onPaymentDone(rsp, "후원재가입", "/premium/done/subscribe")
        );
      } else {
        loading.current = false;
        waitingPopup.hide();
        var titleOptions = {
          text: "결제진행", // 타이틀
          textColor: "#ffffff", // 타이틀 색
          textSize: "20", // 타이틀 크기
          textAlignment: "left", // 타이틀 정렬 유형
          backgroundColor: "#344e81", // 타이틀 배경색
          show: true, // 타이틀 유무
          leftButtonType: "back", // 왼쪽 버튼 유형
          leftButtonColor: "#ffffff", // 왼쪽 버튼 색
          rightButtonType: "close", // 오른쪽 버튼 유형
          rightButtonColor: "#ffffff", // 오른쪽 버튼 색
        };
        var userCode = "imp68909306"; // 가맹점 식별코드
        data.app_scheme = "kakao845dd8ac648986a8631ecadc339a7acf"; // 앱 URL 스킴
        var params = {
          titleOptions: titleOptions, // 타이틀 옵션
          userCode: userCode, // 가맹점 식별코드
          data: data, // 결제 데이터
          callback: (rsp) =>
            onPaymentDone(rsp, "후원재가입", "/premium/done/subscribe"),
        };
        windowAny.cordova.plugins.IamportCordova.payment(params);
      }
    },
    [me]
  );

  const onPaymentDone = async (rsp, logName, redirection) => {
    log(LogLevel.UI_ACTION, "PremiumUserSubscribePage:onSubscribeDoneWeb", rsp);
    if (rsp.success || rsp.imp_success == "true") {
      loading.current = true;
      waitingPopup.show();
      var msg = "결제가 완료되었습니다.";
      msg += "고유ID : " + rsp.imp_uid;
      msg += " 상점 거래ID : " + rsp.merchant_uid;
      msg += " 결제 금액 : " + rsp.paid_amount;
      msg += " 카드 승인번호 : " + rsp.apply_num;
      log(LogLevel.UI_ACTION, "onPaymentDoneWeb:success", rsp, msg);
      // this.props.history.replace("/sale/order/done?id=" + orderId);

      let purchasedResult = await fetchAPI(
        API.USER_PREMIUM_PURCHASED_POST,
        "",
        null,
        { imp_uid: rsp.imp_uid, merchant_uid: rsp.merchant_uid },
        getGlobal(GlobalKey.TOKEN)
      );

      if (!purchasedResult || purchasedResult.error) {
        alert(
          "결제오류 : " + purchasedResult.message + " 관리자에게 문의하세요."
        );
        loading.current = false;
        waitingPopup.hide();
      }

      loading.current = false;
      waitingPopup.hide();
      dispatch(actions.user.login());
      console.log(history);
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "PREMIUM_PAGE",
        `${logName}_성공`,
        {}
      );
      if (redirection) history.replace(redirection, location.state);
    } else {
      var msg = "결제에 실패하였습니다.";
      msg += "에러내용 : " + rsp.error_msg;
      alert(msg);
      log(LogLevel.UI_ACTION, "onOrder:fail", rsp);
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "PREMIUM_PAGE",
        `${logName}_실패`,
        {}
      );
      // clearGlobal("jsonTestPremium", true);
      // setPremium(null);
      // this.failed = true;
      loading.current = false;
      waitingPopup.hide();
    }

    loading.current = false;
    waitingPopup.hide();
    // setGlobal("jsonTestPremium", premium, true);
    // setShowCompletePopup(true);
    // setPremium(premium);
    // history.replace("/premium/user/detail");
  };

  try {
    if (!me.premiumType || !me.premium)
      return (
        <PremiumUserSubscribe
          me={me}
          onGoBack={goBack}
          onSubscribe={subscribe}
          onViewBenefit={viewBenefit}
          menu={donations}
        />
      );
    else
      return (
        <PremiumUserDetail
          premium={me.premium}
          me={me}
          onGoBack={goBack}
          onChangeCard={changeCard}
          onUnsubscribe={unsubscribe}
          onResubscribe={resubscribe}
          menu={donations}
        />
      );
  } catch (error) {
    log(
      LogLevel.UI_EXCEPTION,
      "PremiumUserSubscribePage:exception",
      error.message,
      error.stack
    );
    AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "UI_Rendering_Error", "에러", {
      page: "PremiumUserSubscribePage",
      errorMessage: error,
    });

    return null;
  }
};

export default withRouter(PremiumUserSubscribePage);
