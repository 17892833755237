import * as boards from "./actions";
import {
  BoardState,
  BoardListState,
  BoardType,
  BoardSortType,
  BoardFilterType,
} from "./types";
import { ActionType, getType } from "typesafe-actions";
import { Middleware } from "redux";
import { fetchAPI } from "../../utils/APIUtil";
import * as API from "./../../API.json";
import { getGlobal, GlobalKey, setGlobal } from "./../../utils/GlobalUtil";
import {
  BoardContent,
  BoardContentSummaryGroup,
  BoardInfo,
  Reference,
} from "../../models/Model.Board";
import { ErrorCode } from "../../models/ErrorCode";
import { log, LogLevel } from "../../utils/LogUtil";
import { UserState } from "../user/types";
import { isChrome } from "react-device-detect";
import ABTestUtil, { ABTestFeature } from "../../utils/ABTestUtil";
import { CompanyType } from "../../models/Model.User";
import { BoardSummaryFetcher } from "../../utils/BoardSummaryUtil";

export const fetchBoardMiddleware: Middleware<{}, BoardState> = (store) => (
  next
) => async (action: ActionType<typeof boards>) => {
  next(action);

  let states = store.getState();
  let state: BoardState = states["board"];
  if (
    action.type == getType(boards.getBoards) &&
    (state.boardListLoaded == false || action.payload)
  ) {
    log(
      LogLevel.REDUX_BOARD,
      "fetchBoardMiddleware : boards.getBoards ",
      action
    );
    next(boards.fetchBoards.request(null));
    try {
      let result = await fetchAPI(
        API.BOARDS_LIST,
        "",
        null,
        null,
        getGlobal(GlobalKey.TOKEN)
      )
        .then((result) => {
          if (result && !result.error) {
            log(
              LogLevel.REDUX_BOARD,
              "fetchBoardMiddleware getBoards success",
              result.data
            );
            let rboards: BoardInfo[] = result.data;
            next(boards.fetchBoards.success(rboards));
          } else {
            log(
              LogLevel.REDUX_BOARD,
              "fetchBoardMiddleware getBoards failed",
              result
            );

            let error: Error = {
              name: "error code " + result.error,
              message: "error code " + result.error,
            };
            next(boards.fetchBoards.failure(error));
          }
        })
        .catch((e) => {
          log(LogLevel.REDUX_BOARD, "fetchBoardMiddleware getBoards failed", e);
          next(boards.fetchBoards.failure(e));
        });
    } catch (e) {
      log(LogLevel.REDUX_BOARD, "fetchBoardMiddleware getBoards failed", e);
      next(boards.fetchBoards.failure(e));
    }
  } else if (action.type == getType(boards.visitBoard)) {
    log(
      LogLevel.REDUX_BOARD,
      "fetchBoardMiddleware : boards.visitBoard ",
      action
    );
    if (action.payload)
      setGlobal(
        GlobalKey.BOARD_LAST_VISITED + action.payload.toString(),
        Date.now(),
        true
      );
  } else if (action.type == getType(boards.changeUnionBoard)) {
    log(
      LogLevel.REDUX_BOARD,
      "fetchBoardMiddleware : boards.changeUnionBoard ",
      action
    );

    if (action.payload) {
      let targets = [...action.payload];
      if (
        ABTestUtil.isTest(ABTestFeature.UI_PARTNERS_UNION_BOARD_DEFAULT) &&
        !targets.includes(BoardType.ALLINONE)
      )
        targets.push(BoardType.ALLINONE);
      setGlobal(
        GlobalKey.BOARD_UNION_CATEGORIES +
          (action.meta == BoardType.UNION_TAB ? "" : action.meta),
        targets,
        true
      );
    }
  } else if (action.type == getType(boards.getBoard)) {
    log(
      LogLevel.REDUX_BOARD,
      "fetchBoardMiddleware : boards.getBoard ",
      action,
      state
    );
    if (
      !state.boards[action.payload] ||
      !state.boards[action.payload].end ||
      state.boards[action.payload].needReload ||
      !state.boards[action.payload].loading
    ) {
      let needReload = state.boards[action.payload]
        ? state.boards[action.payload].needReload
        : false;
      next(boards.fetchBoard.request(action.payload));
      try {
        let currentBoardList: BoardListState = state.boards[action.payload];
        let start = 0;
        if (
          !needReload &&
          currentBoardList.contents &&
          currentBoardList.contents.length
        )
          start = currentBoardList.contents.length;
        let api: any;
        let path: string = "0";
        let param: any = { start, count: action.meta };
        if (action.payload == BoardType.MY_POST) api = API.BOARD_USER_POST;
        else if (action.payload == BoardType.MY_REPLY)
          api = API.BOARD_USER_REPLY_POST;
        else if (action.payload == BoardType.MY_LIKE) api = API.BOARD_USER_LIKE;
        else if (action.payload == BoardType.MY_SCRAP)
          api = API.BOARD_USER_SCRAP;
        else if (action.payload == BoardType.LATEST_NOTICE) {
          path = "";
          param = null;
          api = API.BOARD_LATEST_NOTICE;
        } else if (action.payload == BoardType.FAQ) {
          api = API.BOARD_SIMPLE_LIST;
          path = action.payload.toString();
          param.start = start;
        } else if ((action.payload & BoardType.OTHER_ANSWER_START) != 0) {
          api = API.BOARD_USER_REPLY_POST;
          path = (action.payload & ~BoardType.OTHER_ANSWER_START).toString();
          param.count = 0;
        } else if ((action.payload & BoardType.OTHER_POST_START) != 0) {
          api = API.BOARD_USER_POST;
          path = (action.payload & ~BoardType.OTHER_POST_START).toString();
          param.count = 0;
        } else if (action.payload == BoardType.PARTNERS_HOT) {
          api = API.ALLINONE_HOT_BOARD_LIST; // TODO API
          param.count = 0;
        } else if (action.payload == BoardType.PARTNERS_NEW_CONTENTS) {
          api = API.ALLINONE_NEW_BOARD_LIST; // TODO API
        } else {
          let readCnt = 0;
          let repliedCnt = 0;

          api = API.BOARD_LIST_REQUEST;
          path = currentBoardList.category.toString();
          param.sort = currentBoardList.sort
            ? currentBoardList.sort
            : BoardSortType.TIME_DESC;
          param.userId = currentBoardList.user ? currentBoardList.user : "";
          param.attribute = currentBoardList.attribute;
          if (currentBoardList.filter) {
            if ((currentBoardList.filter & BoardFilterType.UNREAD) != 0) {
              param.unread = 1;
            } else if (
              (currentBoardList.filter & BoardFilterType.UNANSWERED) !=
              0
            ) {
              param.unanswered = 1;
            }
          }
          if (action.payload == BoardType.RECOMMEND) {
            param.nice = 1;
          }

          if (
            // currentBoardList.category == BoardType.UNION &&
            currentBoardList.categories &&
            currentBoardList.categories.length
          ) {
            param.categories = currentBoardList.categories.join(",");
          }

          if (!needReload) {
            if (currentBoardList.filter) {
              if ((currentBoardList.filter & BoardFilterType.UNREAD) != 0) {
                if (currentBoardList && currentBoardList.contents) {
                  for (let i = 0; i < currentBoardList.contents.length; i++) {
                    if (state.contents[currentBoardList.contents[i].id].viewed)
                      readCnt++;
                  }
                }
              } else if (
                (currentBoardList.filter & BoardFilterType.UNANSWERED) !=
                0
              ) {
                if (currentBoardList && currentBoardList.contents) {
                  for (let i = 0; i < currentBoardList.contents.length; i++) {
                    if (
                      state.contents[currentBoardList.contents[i].id]
                        .repliedCnt > 0
                    )
                      repliedCnt++;
                  }
                }
              }
            }

            param.start = start - readCnt - repliedCnt;
          }

          log(
            LogLevel.REDUX_BOARD,
            "fetchBoardMiddleware : boards.getBoard normal ",
            action,
            state,
            api,
            path,
            param,
            currentBoardList,
            needReload
          );
        }

        if (api) {
          fetchAPI(api, path, param, null, getGlobal(GlobalKey.TOKEN)).then(
            (result) => {
              if (result && !result.error) {
                let contents: BoardContent[] = [];
                if (!needReload && currentBoardList.contents)
                  contents = [...currentBoardList.contents, ...result.data];
                else contents = [...result.data];
                let end: boolean =
                  !action.meta || result.data.length < action.meta
                    ? true
                    : false;
                let id: number = action.payload;
                let loading: boolean = false;
                let checksum: number =
                  currentBoardList.checksum + result.checksum;

                let newList: BoardListState = {
                  contents,
                  end,
                  id,
                  loading,
                  checksum,
                };

                log(
                  LogLevel.REDUX_BOARD,
                  "fetchBoardMiddleware QnA result",
                  needReload,
                  newList,
                  newList.lastVisitedTime
                );
                next(boards.fetchBoard.success(newList));
              } else if (result && result.error == ErrorCode.ERROR_NOT_EXIST) {
                let contents: BoardContent[] = [];
                let checksum = 0;
                if (!needReload && currentBoardList.contents) {
                  contents = currentBoardList.contents;
                  checksum = currentBoardList.checksum;
                }

                let newList: BoardListState = {
                  id: action.payload,
                  contents,
                  checksum,
                  end: true,
                  loading: false,
                };
                log(
                  LogLevel.REDUX_BOARD,
                  "fetchBoardMiddleware QnA result ERROR_NOT_EXIST",
                  start,
                  newList,
                  newList.lastVisitedTime
                );
                next(boards.fetchBoard.success(newList));
              } else {
                log(
                  LogLevel.REDUX_BOARD,
                  "fetchBoardMiddleware QnA failed",
                  result
                );

                let error: Error = {
                  name: action.payload.toString(),
                  message: "error code " + result.error,
                };
                next(boards.fetchBoard.failure(error));
              }

              // if(action.payload == BoardType.RECOMMEND && result){
              //   let today = Math.floor((Date.now() + 9 * 60 * 60 * 1000)/(24*60*60*1000));
              //   setGlobal(GlobalKey.UNREAD_RECOMMENDS_LOADED_AT, today, true);
              //   let data = [];
              //   if(result.data)
              //     data = result.data;
              //   setGlobal(GlobalKey.UNREAD_RECOMMENDS, data, true);
              // }
            }
          );
        }
      } catch (e) {
        log(LogLevel.REDUX_BOARD, "fetchBoardMiddleware QnA failed", e);
        let error: Error = {
          name: action.payload.toString(),
          message: e.name + " " + e.message,
        };
        next(boards.fetchBoard.failure(error));
      }
    }
  } else if (action.type == getType(boards.getBizBoard)) {
    let id;
    if (!action.payload) {
    } else if (action.payload.bizCategoryId) {
      id = action.payload.bizCategoryId;
    } else if (action.payload.bizUseId) {
      id = action.payload.bizUseId;
    }

    log(
      LogLevel.REDUX_BOARD,
      "fetchBoardMiddleware : boards.getBizBoard ",
      action,
      state,
      id,
      state.bizBoards[id]
    );

    if (id) {
      if (
        !state.bizBoards[id] ||
        !state.bizBoards[id].end ||
        state.bizBoards[id].needReload ||
        !state.bizBoards[id].loading
      ) {
        let needReload = state.bizBoards[id]
          ? state.bizBoards[id].needReload
          : false;
        next(boards.fetchBizBoard.request(action.payload));
        try {
          let currentBoardList: BoardListState = state.bizBoards[id];
          let start = 0;
          if (
            !needReload &&
            currentBoardList.contents &&
            currentBoardList.contents.length
          )
            start = currentBoardList.contents.length;
          let param: any = { start: 0, count: action.meta };

          let path = currentBoardList.category.toString();
          param.sort = currentBoardList.sort
            ? currentBoardList.sort
            : BoardSortType.TIME_DESC;
          if (action.payload.bizUseId)
            param.usersBizId = action.payload.bizUseId;
          if (
            action.payload.bizCategoryId &&
            action.payload.bizCategoryId != CompanyType.ALL
          )
            param.subCategories =
              state.bizBoards[action.payload.bizCategoryId].name;
          log(
            LogLevel.REDUX_BOARD,
            "fetchBoardMiddleware : boards.getBizBoard normal ",
            state.bizBoards[action.payload.bizCategoryId],
            action,
            state,
            path,
            param,
            currentBoardList,
            needReload
          );

          fetchAPI(
            API.BOARD_LIST_REQUEST,
            path,
            param,
            null,
            getGlobal(GlobalKey.TOKEN)
          ).then((result) => {
            if (result && !result.error) {
              let contents: BoardContent[] = [];
              if (!needReload && currentBoardList.contents)
                contents = [...currentBoardList.contents, ...result.data];
              else contents = [...result.data];
              let end: boolean =
                !action.meta || result.data.length < action.meta ? true : false;
              let loading: boolean = false;
              let checksum: number =
                currentBoardList.checksum + result.checksum;

              let newList: BoardListState = {
                contents,
                end,
                id,
                loading,
                checksum,
                bizCategoryId: action.payload.bizCategoryId,
                bizUserId: action.payload.bizUseId,
              };

              log(
                LogLevel.REDUX_BOARD,
                "fetchBoardMiddleware getBizBoard result",
                needReload,
                newList,
                newList.lastVisitedTime
              );
              next(boards.fetchBizBoard.success(newList));
            } else if (result && result.error == ErrorCode.ERROR_NOT_EXIST) {
              let contents: BoardContent[] = [];
              let checksum = 0;
              if (!needReload && currentBoardList.contents) {
                contents = currentBoardList.contents;
                checksum = currentBoardList.checksum;
              }

              let newList: BoardListState = {
                bizCategoryId: action.payload.bizCategoryId,
                bizUserId: action.payload.bizUseId,
                contents,
                checksum,
                end: true,
                loading: false,
              };
              log(
                LogLevel.REDUX_BOARD,
                "fetchBoardMiddleware getBizBoard result ERROR_NOT_EXIST",
                start,
                newList,
                newList.lastVisitedTime
              );
              next(boards.fetchBizBoard.success(newList));
            } else {
              log(
                LogLevel.REDUX_BOARD,
                "fetchBoardMiddleware getBizBoard failed",
                result
              );

              let error: Error = {
                name: id,
                message: "error code " + result.error,
              };
              next(boards.fetchBizBoard.failure(error));
            }

            // if(action.payload == BoardType.RECOMMEND && result){
            //   let today = Math.floor((Date.now() + 9 * 60 * 60 * 1000)/(24*60*60*1000));
            //   setGlobal(GlobalKey.UNREAD_RECOMMENDS_LOADED_AT, today, true);
            //   let data = [];
            //   if(result.data)
            //     data = result.data;
            //   setGlobal(GlobalKey.UNREAD_RECOMMENDS, data, true);
            // }
          });
        } catch (e) {
          log(
            LogLevel.REDUX_BOARD,
            "fetchBoardMiddleware getBizBoard failed",
            e
          );
          let error: Error = {
            name: action.payload.toString(),
            message: e.name + " " + e.message,
          };
          next(boards.fetchBoard.failure(error));
        }
      }
    } else {
      for (let i = 0; i < state.bizCategoryList.length; i++) {
        log(
          LogLevel.REDUX_BOARD,
          "fetchBoardMiddleware : boards.getBizBoard all",
          action,
          state,
          i,
          state.bizCategoryList[i],
          state.bizBoards[state.bizCategoryList[i]]
        );
        store.dispatch(
          boards.getBizBoard({ bizCategoryId: state.bizCategoryList[i] })
        );
      }
    }
  } else if (action.type == getType(boards.getBoardUpdates)) {
    if (action.payload > BoardType.QURATED_START) {
      log(
        LogLevel.REDUX_BOARD,
        "fetchBoardMiddleware : boards.getBoardUpdates BoardType.QURATED_START skip",
        action,
        state
      );
      return;
    }

    let currentBoardList: BoardListState = state.boards[action.payload];
    if (
      !currentBoardList ||
      !currentBoardList.contents ||
      !currentBoardList.contents.length ||
      currentBoardList.needReload ||
      currentBoardList.loading ||
      currentBoardList.sort
    ) {
      log(
        LogLevel.REDUX_BOARD,
        "fetchBoardMiddleware : boards.getBoardUpdates skip",
        action,
        state
      );
      return;
    }

    let now: number = Date.now();
    if (
      !action.meta &&
      now - currentBoardList.lastUpdatedTime < 2 * 60 * 1000
    ) {
      log(
        LogLevel.REDUX_BOARD,
        "fetchBoardMiddleware getBoardUpdates time wait",
        action,
        state,
        now,
        currentBoardList.lastUpdatedTime
      );
      return;
    }
    log(
      LogLevel.REDUX_BOARD,
      "fetchBoardMiddleware : boards.getBoardUpdates ",
      action,
      state
    );

    next(boards.fetchBoard.request(action.payload));
    try {
      let lastContentId: number = currentBoardList.contents[0].id;
      let sort = state.boards[action.payload].sort
        ? state.boards[action.payload].sort
        : 0;
      let param = { start: 0, count: 0, before: lastContentId, sort };
      let api = API.BOARD_LIST_REQUEST;
      let path = action.payload.toString();
      let lastUpdatedTime = now;
      fetchAPI(api, path, param, null, getGlobal(GlobalKey.TOKEN)).then(
        (result) => {
          if (
            result &&
            !result.error &&
            result.data.length > 0 &&
            currentBoardList.contents[0].id != result.data[0]
          ) {
            let contents: BoardContent[] = [
              ...result.data,
              ...currentBoardList.contents,
            ];
            let loading: boolean = false;
            let checksum: number = currentBoardList.checksum + result.checksum;

            let newList: BoardListState = {
              id: currentBoardList.id,
              loading,
              contents,
              checksum,
              lastUpdatedTime,
            };

            log(
              LogLevel.REDUX_BOARD,
              "fetchBoardMiddleware getBoardUpdates result",
              newList
            );
            next(boards.fetchBoard.success(newList));
          } else if (result && result.error == ErrorCode.ERROR_NOT_EXIST) {
            let newList: BoardListState = {
              id: currentBoardList.id,
              loading: false,
              lastUpdatedTime,
            };
            log(
              LogLevel.REDUX_BOARD,
              "fetchBoardMiddleware getBoardUpdates result ERROR_NOT_EXIST",
              newList
            );
            next(boards.fetchBoard.success(newList));
          } else {
            log(
              LogLevel.REDUX_BOARD,
              "fetchBoardMiddleware getBoardUpdates failed",
              result
            );

            let error: Error = {
              name: action.payload.toString(),
              message: "error code " + result.error,
            };
            next(boards.fetchBoard.failure(error));
          }
        }
      );
    } catch (e) {
      log(LogLevel.REDUX_BOARD, "fetchBoardMiddleware QnA failed", e);
      let error: Error = {
        name: action.payload.toString(),
        message: e.name + " " + e.message,
      };
      next(boards.fetchBoard.failure(error));
    }
  } else if (action.type == getType(boards.getBoardReloadTop10)) {
    if (action.payload > BoardType.QURATED_START) {
      log(
        LogLevel.REDUX_BOARD,
        "fetchBoardMiddleware : boards.getBoardReloads BoardType.QURATED_START skip",
        action,
        state
      );
      return;
    }

    let currentBoardList: BoardListState = state.boards[action.payload];
    if (
      !currentBoardList ||
      !currentBoardList.contents ||
      !currentBoardList.contents.length ||
      currentBoardList.needReload ||
      currentBoardList.loading ||
      (currentBoardList.sort &&
        currentBoardList.sort != BoardSortType.TIME_DESC)
    ) {
      log(
        LogLevel.REDUX_BOARD,
        "fetchBoardMiddleware : boards.getBoardReloads skip",
        action,
        state
      );
      return;
    }

    let now: number = Date.now();
    log(
      LogLevel.REDUX_BOARD,
      "fetchBoardMiddleware : boards.getBoardReloads ",
      action,
      state
    );

    next(boards.fetchBoard.request(action.payload));
    try {
      let reloadLastContentId: number = currentBoardList.contents[9].id;
      let sort = state.boards[action.payload].sort
        ? state.boards[action.payload].sort
        : 0;
      let param = {
        start: 0,
        count: 0,
        before: reloadLastContentId,
        sort,
        attribute: state.boards[action.payload].attribute,
      };
      let api = API.BOARD_LIST_REQUEST;
      let path = action.payload.toString();
      let lastUpdatedTime = now;
      fetchAPI(api, path, param, null, getGlobal(GlobalKey.TOKEN)).then(
        (result) => {
          if (
            result &&
            !result.error &&
            result.data.length > 0 &&
            currentBoardList.contents[0].id != result.data[0]
          ) {
            let contents: BoardContent[] = [
              ...result.data,
              ...currentBoardList.contents.slice(9),
            ];
            let loading: boolean = false;
            let checksum: number = currentBoardList.checksum + result.checksum;

            let newList: BoardListState = {
              id: currentBoardList.id,
              loading,
              contents,
              checksum,
              lastUpdatedTime,
            };

            log(
              LogLevel.REDUX_BOARD,
              "fetchBoardMiddleware getBoardReloads result",
              newList
            );
            next(boards.fetchBoard.success(newList));
          } else if (result && result.error == ErrorCode.ERROR_NOT_EXIST) {
            let newList: BoardListState = {
              id: currentBoardList.id,
              loading: false,
              lastUpdatedTime,
            };
            log(
              LogLevel.REDUX_BOARD,
              "fetchBoardMiddleware getBoardReloads result ERROR_NOT_EXIST",
              newList
            );
            next(boards.fetchBoard.success(newList));
          } else {
            log(
              LogLevel.REDUX_BOARD,
              "fetchBoardMiddleware getBoardReloads failed",
              result
            );

            let error: Error = {
              name: action.payload.toString(),
              message: "error code " + result.error,
            };
            next(boards.fetchBoard.failure(error));
          }
        }
      );
    } catch (e) {
      log(LogLevel.REDUX_BOARD, "fetchBoardMiddleware getBoardReloads", e);
      let error: Error = {
        name: action.payload.toString(),
        message: e.name + " " + e.message,
      };
      next(boards.fetchBoard.failure(error));
    }
  } else if (action.type == getType(boards.getContent) && action.payload) {
    log(
      LogLevel.REDUX_BOARD,
      "fetchBoardMiddleware : boards.getContent ",
      action
    );
    next(boards.fetchContent.request(action.payload));
    try {
      let result = await fetchAPI(
        API.BOARD_DETAIL_REQUEST,
        action.payload,
        null,
        null,
        getGlobal(GlobalKey.TOKEN)
      );
      if (result && !result.error) {
        log(LogLevel.REDUX_BOARD, "boards.getContent result", result.data);
        let content: BoardContent = result.data;
        // result = await fetchAPI(API.REPLY_LIST_REQUEST, action.payload, {start:0, count: 30}, null, getGlobal(GlobalKey.TOKEN));
        // if(result){
        //     if(result.message == 'success' && result.data){
        //         content.replies = result.data;
        //     }
        // }

        next(boards.fetchContent.success(content));
      } else {
        log(LogLevel.REDUX_BOARD, "fetchBoardMiddleware QnA failed", result);

        let error: Error = {
          name: action.payload.toString(),
          message: "error code " + result.error,
        };
        next(boards.fetchContent.failure(error));
      }
    } catch (e) {
      log(LogLevel.REDUX_BOARD, "fetchBoardMiddleware QnA failed", e);
      let error: Error = {
        name: action.payload.toString(),
        message: e.name + " " + e.message,
      };
      next(boards.fetchContent.failure(error));
    }
  } else if (action.type == getType(boards.getSummaryGroup) && action.payload) {
    log(
      LogLevel.REDUX_BOARD,
      "fetchBoardMiddleware : boards.getSummaryGroup ",
      action
    );
    if (
      state.summaryGroups[action.payload] &&
      ((state.summaryGroups[action.payload].summaries &&
        state.summaryGroups[action.payload].count ==
          state.summaryGroups[action.payload].summaries.length) ||
        state.summaryGroups[action.payload].loading)
    ) {
      return;
    }
    next(boards.fetchSummaryGroup.request(action.payload));
    try {
      let result = await fetchAPI(
        API.BOARDS_GET_SUMMARY_GROUP,
        "",
        null,
        { keyword: action.payload },
        getGlobal(GlobalKey.TOKEN)
      );
      if (result && !result.error) {
        log(LogLevel.REDUX_BOARD, "boards.getSummaryGroup result", result.data);
        let group: BoardContentSummaryGroup = result.data;
        next(boards.fetchSummaryGroup.success(group));
      } else {
        log(LogLevel.REDUX_BOARD, "boards.getSummaryGroup failed", result);

        let error: Error = {
          name: action.payload.toString(),
          message: "error code " + result.error,
        };
        next(boards.fetchSummaryGroup.failure(error));
      }
    } catch (e) {
      log(LogLevel.REDUX_BOARD, "boards.getSummaryGroup failed", e);
      let error: Error = {
        name: action.payload.toString(),
        message: e.name + " " + e.message,
      };
      next(boards.fetchSummaryGroup.failure(error));
    }
  } else if (
    action.type == getType(boards.getSummaryGroupCount) &&
    action.payload
  ) {
    log(
      LogLevel.REDUX_BOARD,
      "fetchBoardMiddleware : boards.getSummaryGroupCount ",
      action
    );
    if (
      state.summaryGroups[action.payload] &&
      (state.summaryGroups[action.payload].count >= 0 ||
        state.summaryGroups[action.payload].loading)
    ) {
      return;
    }
    next(boards.fetchSummaryGroup.request(action.payload));
    try {
      let result = await fetchAPI(
        API.BOARDS_GET_SUMMARY_GROUP_CNT,
        "",
        null,
        { keyword: action.payload },
        getGlobal(GlobalKey.TOKEN)
      );
      if (result && !result.error) {
        log(
          LogLevel.REDUX_BOARD,
          "boards.getSummaryGroupCount result",
          result.data
        );
        let group: BoardContentSummaryGroup = {
          keyword: action.payload,
          count: result.data,
        };
        next(boards.fetchSummaryGroup.success(group));
      } else {
        log(LogLevel.REDUX_BOARD, "boards.getSummaryGroupCount failed", result);

        let error: Error = {
          name: action.payload.toString(),
          message: "error code " + result.error,
        };
        next(boards.fetchSummaryGroup.failure(error));
      }
    } catch (e) {
      log(LogLevel.REDUX_BOARD, "boards.getSummaryGroupCount failed", e);
      let error: Error = {
        name: action.payload.toString(),
        message: e.name + " " + e.message,
      };
      next(boards.fetchSummaryGroup.failure(error));
    }
  } else if (action.type == getType(boards.getSummary) && action.payload) {
    log(
      LogLevel.REDUX_BOARD,
      "fetchBoardMiddleware : boards.getSummary ",
      action
    );
    if (state.summaries[action.payload]) {
      return;
    }
    next(boards.fetchSummary.request(action.payload));
    try {
      let fetcher = new BoardSummaryFetcher(
        action.payload,
        (summary) => {
          next(boards.fetchSummary.success(summary));
        },
        (summary) => {
          log(
            LogLevel.REDUX_BOARD,
            "fetchBoardMiddleware : boards.getSummary done",
            summary
          );
          fetcher = null;
          next(boards.fetchSummary.success(summary));
        },
        (err) => {
          next(boards.fetchSummary.failure(err));
        }
      );
    } catch (e) {
      log(LogLevel.REDUX_BOARD, "fetchBoardMiddleware : boards.getSummary", e);
      let error: Error = {
        name: action.payload.toString(),
        message: e.name + " " + e.message,
      };
      next(boards.fetchSummary.failure(error));
    }
  } else if (
    (action.type == getType(boards.loadLink) ||
      action.type == getType(boards.loadCommentLink)) &&
    action.payload
  ) {
    log(
      LogLevel.REDUX_BOARD,
      "fetchBoardMiddleware : boards.loadLink ",
      action
    );
    next(boards.fetchLink.request(action.payload));
    try {
      let result = await fetchAPI(
        API.BOARD_GET_REFERENCE,
        "",
        null,
        {
          url: action.payload,
          id: action.meta,
          isComment: action.type == getType(boards.loadCommentLink),
        },
        getGlobal(GlobalKey.TOKEN)
      );
      if (result && !result.error) {
        log(LogLevel.REDUX_BOARD, "boards.getContent result", result.data);
        let link: Reference = result.data;
        // result = await fetchAPI(API.REPLY_LIST_REQUEST, action.payload, {start:0, count: 30}, null, getGlobal(GlobalKey.TOKEN));
        // if(result){
        //     if(result.message == 'success' && result.data){
        //         content.replies = result.data;
        //     }
        // }

        next(boards.fetchLink.success(link));
      } else {
        log(LogLevel.REDUX_BOARD, "fetchBoardMiddleware QnA failed", result);

        let error: Error = {
          name: action.payload.toString(),
          message: "error code " + result.error,
        };
        next(boards.fetchLink.failure(error));
      }
    } catch (e) {
      log(LogLevel.REDUX_BOARD, "fetchBoardMiddleware QnA failed", e);
      let error: Error = {
        name: action.payload.toString(),
        message: e.name + " " + e.message,
      };
      next(boards.fetchContent.failure(error));
    }
  } else if (action.type == getType(boards.getFilePath)) {
    log(
      LogLevel.REDUX_BOARD,
      "fetchBoardMiddleware : boards.loadLink ",
      action
    );
    if (!state.filePath) {
      next(boards.fetchFilePath.request());
      try {
        let result = await fetchAPI(API.UPLOAD_PATH, "", null, null);
        if (result && !result.error) {
          log(
            LogLevel.REDUX_BOARD,
            "fetchBoardMiddleware : boards.loadLink result",
            result.data
          );
          setGlobal(GlobalKey.FILE_PATH, result.data);
          next(boards.fetchFilePath.success(result.data["###"]));
        } else {
          log(
            LogLevel.REDUX_BOARD,
            "fetchBoardMiddleware : boards.loadLink failed",
            result
          );

          let error: Error = {
            name: "FAILED",
            message: "error code " + result.error,
          };
          next(boards.fetchFilePath.failure(error));
        }
      } catch (e) {
        log(
          LogLevel.REDUX_BOARD,
          "fetchBoardMiddleware : boards.loadLink failed",
          e
        );
        let error: Error = {
          name: "EXCEPTION",
          message: e.name + " " + e.message,
        };
        next(boards.fetchFilePath.failure(error));
      }
    }
  } else if (
    action.type == getType(boards.loadDailyQuizList) &&
    action.payload
  ) {
    log(
      LogLevel.REDUX_BOARD,
      "fetchBoardMiddleware : boards.loadDailyQuizList ",
      action
    );
    next(boards.fetchDailyQuizList.request(action.payload));
    try {
      let result = await fetchAPI(
        API.DAILY_QUIZ_GET_LIST,
        "",
        action.payload,
        null,
        getGlobal(GlobalKey.TOKEN)
      );
      if (result && !result.error) {
        log(
          LogLevel.REDUX_BOARD,
          "boards.loadDailyQuizList result",
          result.data
        );
        next(boards.fetchDailyQuizList.success(result.data));
      } else {
        log(LogLevel.REDUX_BOARD, "boards.loadDailyQuizList failed", result);

        let error: Error = {
          name: action.payload.toString(),
          message: "error code " + result.error,
        };
        next(boards.fetchDailyQuizList.failure(error));
      }
    } catch (e) {
      log(LogLevel.REDUX_BOARD, "boards.loadDailyQuizList failed", e);
      let error: Error = {
        name: action.payload.toString(),
        message: e.name + " " + e.message,
      };
      next(boards.fetchDailyQuizList.failure(error));
    }
  } else if (action.type == getType(boards.loadDailyQuiz)) {
    log(
      LogLevel.REDUX_BOARD,
      "fetchBoardMiddleware : boards.loadDailyQuiz ",
      action
    );
    next(boards.fetchDailyQuiz.request(action.payload));
    try {
      let result = await fetchAPI(
        API.DAILY_QUIZ_GET_DETAIL,
        "",
        { date: action.payload },
        null,
        getGlobal(GlobalKey.TOKEN)
      );
      if (result && !result.error) {
        log(LogLevel.REDUX_BOARD, "boards.loadDailyQuiz result", result.data);
        next(boards.fetchDailyQuiz.success(result.data));
      } else {
        log(LogLevel.REDUX_BOARD, "boards.loadDailyQuiz failed", result);

        let error: Error = {
          name: action.payload.toString(),
          message: "error code " + result.error,
        };
        next(boards.fetchDailyQuiz.failure(error));
      }
    } catch (e) {
      log(LogLevel.REDUX_BOARD, "boards.loadDailyQuiz failed", e);
      let error: Error = {
        name: action.payload.toString(),
        message: e.name + " " + e.message,
      };
      next(boards.fetchDailyQuiz.failure(error));
    }
  }
};
