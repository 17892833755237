import React, { Component } from "react";
import { connect } from "react-redux";
import { actions, RootState } from "../../../store";
import { withRouter, RouteComponentProps } from "react-router";
import { IonButton } from "@ionic/react";
import "./../../Admin.scss";
import { log, LogLevel } from "../../../utils/LogUtil";
import { GlobalKey, getGlobal } from "./../../../utils/GlobalUtil";
import { fetchAPI } from "../../../utils/APIUtil";
import { UIPopupType } from "../../../store/ui/types";
import { getDateStringFromToday } from "../../../utils/TimeUtil";
import { getOS } from "../../../utils/DeviceUtil";
import {
  BizJobOfferName,
  JobApplyInfo,
  JobApplyStatus,
  JobOfferInfo,
  JobOfferName,
  JobOfferSalaryType,
  JobOfferStatus,
} from "../../../models/Model.JobPost";
import DownloadUtil from "../../../utils/DownloadUtil";
import * as API from "./../../../API.json";

const ADMIN_API = {
  JOBPOST_OFFERS: {
    method: "get",
    path: "/admin/jobpost/offers",
    contentType: "application/json",
  },
  JOBPOST_CHATS: {
    method: "get",
    path: "/admin/jobpost/chats",
    contentType: "application/json",
  },
};

type Props = RouteComponentProps<{ tab: string }> &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> & {
    onSelectOffer: (offer: JobOfferInfo) => void;
  };

type State = {
  items: any[];
  dateField: string;
  dateStart: string;
  dateStartWarn: boolean;
  dateEnd: string;
  dateEndWarn: boolean;
  finished: boolean;
  loading: boolean;
  selectedIndex: number;
  orderBy: string;
  orderAsc: boolean;
  unitCount: number;
};

class JobpostList extends Component<Props, State> {
  type: number = 1;
  dateField: string = "";
  dateStart: string = "";
  dateEnd: string = "";

  constructor(props: Props) {
    super(props);
    this.state = {
      items: [],
      finished: false,
      loading: false,
      dateField: "alive",
      dateStart: getDateStringFromToday({ weeks: -1 }),
      dateStartWarn: false,
      dateEnd: getDateStringFromToday({ days: 1 }),
      dateEndWarn: false,
      selectedIndex: -1,
      orderBy: "",
      orderAsc: false,
      unitCount: 100,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    if (this.state.dateStartWarn || this.state.dateEndWarn) return;

    this.dateField = this.state.dateField;
    this.dateStart = this.state.dateStart;
    this.dateEnd = this.state.dateEnd;

    this.setState({ loading: true, items: [] });
    fetchAPI(
      ADMIN_API.JOBPOST_OFFERS,
      "",
      {
        withEnded: 1,
        start: 0,
        count: this.state.unitCount,
        startDate: this.state.dateStart,
        endDate: this.state.dateEnd,
        dateField: this.state.dateField,
        minStatus: 2,
        ownerType: 2,
      },
      null,
      getGlobal(GlobalKey.TOKEN)
    ).then((result) => {
      log(LogLevel.UI_EVENT, "JobpostList:fetchCompanyData Offer"), result;
      if (result && !result.error) {
        let items = result.data;
        if (this.state.orderBy) {
          items = this.orderJobOffer(items, this.state.orderBy, this.state.orderAsc);
        }
        items.map((item) => this.setOrderInfo(item));

        this.setState({
          selectedIndex: -1,
          items: result.data,
          finished: result.data.length < this.state.unitCount,
          loading: false,
          orderBy: "",
        });
      } else {
        this.setState({
          selectedIndex: -1,
          items: [],
          finished: true,
          loading: false,
        });
      }
    });
  };

  onDetailOffer = (index) => {
    this.setState({ selectedIndex: index });

    fetchAPI(
      API.JOBPOST_OFFER_GET,
      this.state.items[index].id,
      { chatlist: true },
      null,
      getGlobal(GlobalKey.TOKEN)
    ).then((result) => {
      log(LogLevel.UI_EVENT, "JobpostList:fetchDataMore Offer", result);
      if (result && !result.error) {
        let items = [...this.state.items];
        items[index] = { ...items[index], ...result.data };
        this.setState({ items });
      }
    });
  };

  fetchDataMore = async () => {
    if (this.state.loading == true) return;
    this.setState({ loading: true });
    fetchAPI(
      ADMIN_API.JOBPOST_OFFERS,
      "",
      {
        withEnded: 1,
        start: this.state.items.length,
        count: this.state.unitCount,
        startDate: this.state.dateStart,
        endDate: this.state.dateEnd,
        dateField: this.state.dateField,
        minStatus: 2,
        ownerType: 2,
      },
      null,
      getGlobal(GlobalKey.TOKEN)
    ).then((result) => {
      log(LogLevel.UI_EVENT, "JobpostList:fetchDataMore Offer", result);
      if (result && !result.error) {
        let items = [...this.state.items, ...result.data];
        if (this.state.orderBy) {
          items = this.orderJobOffer(items, this.state.orderBy, this.state.orderAsc);
        }
        items.map((item) => this.setOrderInfo(item));
        this.setState({
          items,
          finished: result.data.length < this.state.unitCount,
          loading: false,
        });
      } else {
        this.setState({ finished: true, loading: false });
      }
    });
  };

  onSetOrderJobOffer(field: string) {
    let asc: boolean = this.state.orderAsc;

    if (this.state.orderBy == field) {
      asc = !asc;
    }
    let items = [...this.state.items];
    items = this.orderJobOffer(items, field, asc);
    this.setState({ items, orderBy: field, orderAsc: asc });
  }

  orderJobOffer(data: any[], field: string, asc: boolean) {
    data = data.sort((a: JobOfferInfo, b: JobOfferInfo) => {
      let tokens = field.split(".");
      let aCmp = 0,
        bCmp = 0;
      if (tokens.length == 1) {
        aCmp = a[tokens[0]];
        bCmp = b[tokens[0]];
      } else if (tokens.length == 2) {
        aCmp = a[tokens[0]][tokens[1]];
        bCmp = b[tokens[0]][tokens[1]];
      }

      let rvalue = 0;
      if (aCmp > bCmp) rvalue = -1;
      else if (aCmp < bCmp) rvalue = 1;

      if (!asc) rvalue *= -1;

      return rvalue;
    });
    return data;
  }

  onDateChange = (e, field) => {
    let dateString = e.target.value;
    let dateWarn = false;
    try {
      let date = Date.parse(dateString);
      if (!date) {
        log(LogLevel.UI_EXCEPTION, "Admin:JobpostList:onDateEndChange", dateString);
        dateWarn = true;
      } else {
        log(LogLevel.UI_DATA_LOAD, "Admin:JobpostList:onDateEndChange", dateString, date);
      }
    } catch (err) {
      log(LogLevel.UI_EXCEPTION, "Admin:JobpostList:onDateEndChange", dateString, err);
      dateWarn = true;
    }
    let newState: any = {};
    (newState[field] = dateString), (newState[field + "Warn"] = dateWarn), this.setState(newState);
  };

  onCopy = () => {
    let windowAny: any = window;
    let $ = windowAny.$;
    // window.open('data:application/vnd.ms-excel,' + $('#table').html());
    var body = document.getElementById("admin-table-body");
    var range = document.createRange();
    range.selectNode(body);
    window.getSelection().addRange(range);
    document.execCommand("copy");
    window.getSelection().removeAllRanges();
    this.props.toastPopup.show("클립보드에 저장되었습니다.(안되었을수도 있음)");
  };

  onDownload = () => {
    if (this.type == 1) {
      this.downloadOfferList();
    } else {
      this.downloadApplyList();
    }
  };

  render() {
    log(LogLevel.UI_LIFECYCLE, "JobpostList.render", this.props, this.state);

    let more;
    if (!this.state.finished) {
      more = (
        <IonButton expand="full" onClick={this.fetchDataMore}>
          더 불러오기
        </IonButton>
      );
    }

    let list = this.renderOfferList();

    return (
      <div className="admin-full-container">
        <div className="common-container-row-wrap admin-margin-bottom">
          <div className="admin-title">게시기간</div>
          <div
            className={"admin-toggle" + (this.state.dateField == "alive" ? " admin-toggle-selected" : "")}
            onClick={() => this.setState({ dateField: "alive" })}
          >
            진행중
          </div>
          <div
            className={"admin-toggle" + (this.state.dateField == "start" ? " admin-toggle-selected" : "")}
            onClick={() => this.setState({ dateField: "start" })}
          >
            생성일
          </div>
          <div
            className={"admin-toggle" + (this.state.dateField == "end" ? " admin-toggle-selected" : "")}
            onClick={() => this.setState({ dateField: "end" })}
          >
            종료일
          </div>
          <input
            className={this.state.dateStartWarn ? "common-color-caution" : ""}
            placeholder="시작일시"
            value={this.state.dateStart}
            onChange={(e) => this.onDateChange(e, "dateStart")}
          />
          <div>~</div>
          <input
            className={this.state.dateEndWarn ? "common-color-caution" : ""}
            placeholder="시작일시"
            value={this.state.dateEnd}
            onChange={(e) => this.onDateChange(e, "dateEnd")}
          />
          <div
            className="admin-title common-color-highlight"
            onClick={() => {
              this.setState({
                dateStart: getDateStringFromToday({ weeks: -1 }),
                dateEnd: getDateStringFromToday({ days: 1 }),
                dateStartWarn: false,
                dateEndWarn: false,
              });
            }}
          >
            1주
          </div>
          <div
            className="admin-title common-color-highlight"
            onClick={() => {
              this.setState({
                dateStart: getDateStringFromToday({ weeks: -4 }),
                dateEnd: getDateStringFromToday({ days: 1 }),
                dateStartWarn: false,
                dateEndWarn: false,
              });
            }}
          >
            4주
          </div>
          <div
            className="admin-title common-color-highlight"
            onClick={() => {
              this.setState({
                dateStart: getDateStringFromToday({ byMonth: true }),
                dateEnd: getDateStringFromToday({ byMonth: true, months: 1 }),
                dateStartWarn: false,
                dateEndWarn: false,
              });
            }}
          >
            이번달
          </div>
          <div
            className="admin-title common-color-highlight"
            onClick={() => {
              this.setState({
                dateStart: getDateStringFromToday({
                  byMonth: true,
                  months: -1,
                }),
                dateEnd: getDateStringFromToday({ days: 1 }),
                dateStartWarn: false,
                dateEndWarn: false,
              });
            }}
          >
            지난달부터
          </div>
          <div
            className="admin-title common-color-highlight"
            onClick={() => {
              this.setState({
                dateStart: getDateStringFromToday({
                  byMonth: true,
                  months: -1,
                }),
                dateEnd: getDateStringFromToday({ byMonth: true }),
                dateStartWarn: false,
                dateEndWarn: false,
              });
            }}
          >
            지난달
          </div>
          <div
            className="admin-title common-color-highlight"
            onClick={() => {
              this.setState({
                dateStart: getDateStringFromToday({ months: -6 }),
                dateEnd: getDateStringFromToday({ days: 1 }),
                dateStartWarn: false,
                dateEndWarn: false,
              });
            }}
          >
            6개월간
          </div>
        </div>
        <div className="common-container-row-wrap admin-margin-bottom">
          <div
            className={"admin-toggle" + (this.state.unitCount == 100 ? " admin-toggle-selected" : "")}
            onClick={() => this.setState({ unitCount: 100 })}
          >
            100row
          </div>
          <div
            className={"admin-toggle" + (this.state.unitCount == 5000 ? " admin-toggle-selected" : "")}
            onClick={() => this.setState({ unitCount: 5000 })}
          >
            5000row
          </div>
        </div>
        <IonButton onClick={this.fetchData}>불러오기</IonButton>
        <IonButton onClick={this.onCopy}>복사</IonButton>
        <IonButton onClick={this.onDownload}>다운로드({this.state.items.length}건)</IonButton>

        <div className="common-container">
          {more}
          {list}
          {more}
        </div>
        <div style={{ height: "100px" }} />
      </div>
    );
  }

  getOfferTerm(offer: JobOfferInfo) {
    if (offer.status == JobOfferStatus.POSTED) return "공고중";
    else if (offer.status == JobOfferStatus.ENDED) {
      return "공고종료";
    }
    return "";
  }

  setOrderInfo = (offer: JobOfferInfo) => {
    if (offer.order && offer.order.length > 0) {
      for (let i = 0; i < offer.order.length; i++) {
        if (offer.order[i].type == 0) {
          offer.orderName = offer.order[i].title;
        }
        if (offer.order[i].type == 2) {
          offer.orderCoupon = offer.order[i].code;
          offer.orderCouponPrice = -offer.order[i].price;
        }
      }
    }
    if (!offer.orderPayAmount) offer.orderPayAmount = 0;
  };

  renderOfferList = () => {
    return (
      <table id="admin-table" className="admin-table">
        <thead className="admin-table-fixed-header">
          <tr>
            <td className="admin-table-label-x" onClick={() => this.onSetOrderJobOffer("id")}>
              ID
              {this.state.orderBy == "id" ? (this.state.orderAsc ? " ▼" : " ▲") : " -"}
            </td>
            <td className="admin-table-label-x" onClick={() => this.onSetOrderJobOffer("startedAt")}>
              등록일
              {this.state.orderBy == "startedAt" ? (this.state.orderAsc ? " ▼" : " ▲") : " -"}
            </td>
            <td className="admin-table-label-x" onClick={() => this.onSetOrderJobOffer("endedAt")}>
              종료일
              {this.state.orderBy == "endedAt" ? (this.state.orderAsc ? " ▼" : " ▲") : " -"}
            </td>
            <td className="admin-table-label-x">경과일</td>

            <td className="admin-table-label-x" onClick={() => this.onSetOrderJobOffer("status")}>
              상태
              {this.state.orderBy == "status" ? (this.state.orderAsc ? " ▼" : " ▲") : " -"}
            </td>
            <td className="admin-table-label-x" onClick={() => this.onSetOrderJobOffer("workType")}>
              타입
              {this.state.orderBy == "workType" ? (this.state.orderAsc ? " ▼" : " ▲") : " -"}
            </td>
            <td className="admin-table-label-x" onClick={() => this.onSetOrderJobOffer("companyRegion")}>
              지역
              {this.state.orderBy == "companyRegion" ? (this.state.orderAsc ? " ▼" : " ▲") : " -"}
            </td>
            <td className="admin-table-label-x" onClick={() => this.onSetOrderJobOffer("companyName")}>
              회사명
              {this.state.orderBy == "companyName" ? (this.state.orderAsc ? " ▼" : " ▲") : " -"}
            </td>
            <td className="admin-table-label-x" onClick={() => this.onSetOrderJobOffer("orderName")}>
              상품 {this.state.orderBy == "orderName" ? (this.state.orderAsc ? " ▼" : " ▲") : " -"}
            </td>
            <td className="admin-table-label-x" onClick={() => this.onSetOrderJobOffer("orderPayAmount")}>
              금액 / 쿠폰
              {this.state.orderBy == "orderPayAmount" ? (this.state.orderAsc ? " ▼" : " ▲") : " -"}
            </td>
            <td className="admin-table-label-x">쿠폰</td>
            <td className="admin-table-label-x">사용자ID</td>
            <td className="admin-table-label-x">담당자</td>
            <td className="admin-table-label-x">연락처</td>
            <td className="admin-table-label-x" onClick={() => this.onSetOrderJobOffer("initCandidateCnt")}>
              알림수
              {this.state.orderBy == "initCandidateCnt" ? (this.state.orderAsc ? " ▼" : " ▲") : " -"}
            </td>
            <td className="admin-table-label-x" onClick={() => this.onSetOrderJobOffer("viewCnt")}>
              View수
              {this.state.orderBy == "viewCnt" ? (this.state.orderAsc ? " ▼" : " ▲") : " -"}
            </td>
            <td className="admin-table-label-x" onClick={() => this.onSetOrderJobOffer("likeCnt")}>
              Like수
              {this.state.orderBy == "likeCnt" ? (this.state.orderAsc ? " ▼" : " ▲") : " -"}
            </td>
          </tr>
        </thead>
        <tbody id="admin-table-body">
          {this.state.items.map((offer: JobOfferInfo, index) => {
            let period = -1;
            if (offer.startedAt) {
              let end = 0;
              if (offer.endedAt) {
                end = new Date(offer.endedAt).valueOf();
              } else {
                end = Date.now() - 9 * 60 * 60 * 1000;
              }

              let start = new Date(offer.startedAt).valueOf();
              start = Math.floor(start / (24 * 60 * 60 * 1000));
              end = Math.floor(end / (24 * 60 * 60 * 1000));
              period = end - start;
            }

            let style = "";
            if (offer.status == JobOfferStatus.ENDED) {
              if (offer.passCnt > 0) style = " admin-table-value-highlight";
              else style = " admin-table-value-dimm";
            }

            let row = (
              <tr key={index.toString()}>
                <td className={"admin-table-value" + style} onClick={() => this.props.onSelectOffer(offer)}>
                  {offer.id}
                </td>
                <td className={"admin-table-value" + style} onClick={() => this.props.onSelectOffer(offer)}>
                  {offer.startedAt ? getDateStringFromToday({ date: offer.startedAt }) : ""}
                </td>
                <td className={"admin-table-value" + style} onClick={() => this.props.onSelectOffer(offer)}>
                  {offer.endedAt ? getDateStringFromToday({ date: offer.endedAt }) : ""}
                </td>
                <td className={"admin-table-value" + style} onClick={() => this.props.onSelectOffer(offer)}>
                  {period}
                </td>
                <td className={"admin-table-value" + style} onClick={() => this.props.onSelectOffer(offer)}>
                  {this.getOfferTerm(offer)}
                </td>
                <td className={"admin-table-value" + style} onClick={() => this.props.onSelectOffer(offer)}>
                  {BizJobOfferName[offer.workType]}
                </td>
                <td className={"admin-table-value" + style}>{offer.pharmacy.region}</td>
                <td className={"admin-table-value" + style}>{offer.companyName}</td>
                <td className={"admin-table-value" + style}>{offer.orderName}</td>
                <td className={"admin-table-value" + style}>
                  {offer.orderPayAmount >= 0 && offer.orderPayAmount.toLocaleString()}{" "}
                  {offer.orderCouponPrice >= 0 && (
                    <span>
                      /<span className="common-color-caution"> {offer.orderCouponPrice.toLocaleString()}</span>
                    </span>
                  )}
                </td>
                <td className={"admin-table-value" + style}>{offer.orderCoupon}</td>
                <td className={"admin-table-value" + style}>{offer.ownerBizId && offer.ownerBizId.slice(0, 8)}</td>
                <td className={"admin-table-value" + style}>{offer.bizOwner && offer.bizOwner.userName}</td>
                <td className={"admin-table-value" + style}>{offer.bizOwner && offer.bizOwner.phone}</td>
                <td className={"admin-table-value" + style} onClick={() => this.onDetailOffer(index)}>
                  {offer.initCandidateCnt}
                </td>
                <td className={"admin-table-value" + style} onClick={() => this.onDetailOffer(index)}>
                  {offer.viewCnt}
                </td>
                <td className={"admin-table-value" + style} onClick={() => this.onDetailOffer(index)}>
                  {offer.likeCnt}
                </td>
              </tr>
            );

            return row;
          })}
        </tbody>
      </table>
    );
  };

  downloadOfferList = () => {
    let csv =
      "ID,등록일,종료일,경과일,최근접속일,상태,타입,지역,약국명,약국장ID,약국장,연락처,알림수,View수,Like수,채팅수,SMS수,합격수,공고제목,급여유형,최소급여,최대급여\r\n";
    csv += this.state.items
      .map((offer: JobOfferInfo, index) => {
        let period = -1;
        if (offer.startedAt) {
          let end = 0;
          if (offer.endedAt) {
            end = new Date(offer.endedAt).valueOf();
          } else {
            end = Date.now() - 9 * 60 * 60 * 1000;
          }

          let start = new Date(offer.startedAt).valueOf();
          start = Math.floor(start / (24 * 60 * 60 * 1000));
          end = Math.floor(end / (24 * 60 * 60 * 1000));
          period = end - start;
        }

        return `${offer.id},${offer.startedAt ? getDateStringFromToday({ date: offer.startedAt }) : ""},${
          offer.endedAt ? getDateStringFromToday({ date: offer.endedAt }) : ""
        },${period},${
          offer.lastJobpostAt ? getDateStringFromToday({ date: offer.lastJobpostAt }) : ""
        },${this.getOfferTerm(offer)},${JobOfferName[offer.workType]},${offer.pharmacy.region},${offer.pharmacy.name},${
          offer.ownerId
        },${offer.owner.name},${offer.owner.phone},${offer.initCandidateCnt},${offer.viewCnt},${offer.likeCnt},${
          offer.chatCnt
        },${offer.smsCnt},${offer.passCnt},"${offer.title}",${JobOfferSalaryType[offer.salaryType]},${
          offer.salaryRangeLow
        },${offer.salaryRangeHigh}\r\n`;
      })
      .join("");

    DownloadUtil.downloadCsv(
      csv,
      `JobPost-Offers-${this.state.dateStart}-${this.state.dateEnd}-${new Date()
        .toISOString()
        .substring(0, 10)
        .replace(/-/gi, "")}.csv`
    );
  };

  downloadApplyList = () => {
    let csv = "ID,지원자ID,공고ID,이름,전화번호,채팅시작일,최근메시지일\r\n";
    csv += this.state.items
      .map((apply: JobApplyInfo, index) => {
        return `${apply.id},${apply.applicantId},${apply.offerId},${apply.name},${apply.phone},${
          apply.createdAt ? getDateStringFromToday({ date: apply.createdAt }) : ""
        },${apply.lastMessagedAt ? getDateStringFromToday({ date: apply.lastMessagedAt }) : ""}\r\n`;
      })
      .join("");

    DownloadUtil.downloadCsv(
      csv,
      `JobPost-Chat-${this.state.dateStart}-${this.state.dateEnd}-${new Date()
        .toISOString()
        .substring(0, 10)
        .replace(/-/gi, "")}.csv`
    );
  };
}

const mapStateToProps = (state: RootState) => ({
  toastPopup: state.ui.popups[UIPopupType.TOAST_POPUP],
});

const mapDispatchToProps = {};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(JobpostList));
