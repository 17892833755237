import { BoardContent, Attachment } from "../models/Model.Board";
import { LogLevel, log } from "./LogUtil";
import { fetchAPI } from "./APIUtil";
import * as API from "./../API.json";
import { getGlobal, GlobalKey } from "./GlobalUtil";
import StringUtil from "./StringUtil";
import parse from "html-react-parser";
// import reactElementToJSXString from 'react-element-to-jsx-string';
import { loadImageUrlBase64 } from "./ImageUtil";
import ReactDOMServer from "react-dom/server";
import { MY_ADDRESS, TARGET } from "./../config.json";
import ABTestUtil from "./ABTestUtil";
import ChannelTalkService from "./ChannelTalkService";

const windowAny: any = window;

export default class AnalyticsUtil {
  static TYPE_AMPLITUDE = 0x01;
  static TYPE_FACEBOOK = 0x02;
  static TYPE_PIXEL = 0x04;
  static TYPE_FULLSTORY = 0x08;
  static TYPE_CHANNELTALK = 0x10;
  static TYPE_ALL =
    AnalyticsUtil.TYPE_AMPLITUDE |
    AnalyticsUtil.TYPE_FACEBOOK |
    AnalyticsUtil.TYPE_CHANNELTALK |
    AnalyticsUtil.TYPE_PIXEL;

  static async event(
    type: number,
    titleE: string,
    titleK: string,
    data: any = {}
  ) {
    if ((type & AnalyticsUtil.TYPE_AMPLITUDE) != 0 && windowAny.amplitude) {
      let title = "";
      if (ABTestUtil.isTest()) {
        // //windowAny.FS.event(`개발서버${TARGET.toUpperCase()}`, {target:TARGET.toUpperCase()});
        title = `[${TARGET.toUpperCase()}]`;
      }
      log(LogLevel.UI_EVENT, "AnalyticsUtil:event AMPLITUDE", titleK, data);
      try {
        windowAny.amplitude.getInstance().logEvent(title + titleK, data);
      } catch (e) {
        log(LogLevel.UI_EVENT, "AnalyticsUtil:event AMPLITUDE ERROR", e, data);
      }
    }
    // if ((type & AnalyticsUtil.TYPE_FACEBOOK) != 0 && windowAny.FB) {
    //   log(LogLevel.UI_EVENT, "AnalyticsUtil:event FACEBOOK", titleE, data);
    //   try {
    //     windowAny.FB.AppEvents.logEvent(titleE, 0, { event: titleK, ...data });
    //   } catch (e) {
    //     log(LogLevel.UI_EVENT, "AnalyticsUtil:event FACEBOOK ERROR", e, data);
    //   }
    // }
    // if ((type & AnalyticsUtil.TYPE_FULLSTORY) != 0 && windowAny.FS) {
    //   log(LogLevel.UI_EVENT, "AnalyticsUtil:event FULLSTORY", titleK, data);
    //   try {
    //     windowAny.FS.event(titleK, data);
    //   } catch (e) {
    //     log(LogLevel.UI_EVENT, "AnalyticsUtil:event FULLSTORY ERROR", e, data);
    //   }
    // }
    // if ((type & AnalyticsUtil.TYPE_PIXEL) != 0 && windowAny.fbq) {
    //   log(LogLevel.UI_EVENT, "AnalyticsUtil:event PIXEL", titleE, titleK, data);
    //   try {
    //     windowAny.fbq("trackCustom", titleE, { event: titleK, ...data });
    //   } catch (e) {
    //     log(LogLevel.UI_EVENT, "AnalyticsUtil:event PIXEL ERROR", e, data);
    //   }
    // }
    // if ((type & AnalyticsUtil.TYPE_CHANNELTALK) != 0) {
    //   log(LogLevel.UI_EVENT, "AnalyticsUtil:CHANNEL TALK", titleK, data);
    //   try {
    //     ChannelTalkService.track(titleK, data);
    //   } catch (e) {
    //     log(LogLevel.UI_EVENT, "AnalyticsUtil:event CHANNEL TALK ERROR", e, data);
    //   }
    // }
  }
}
