import React, { Component, lazy, Suspense, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter,
  RouteComponentProps,
} from "react-router-dom";
import { PrivateRoute, ManagerRoute, RegisteringRoute } from "./utils/Routing";
import "@ionic/core/css/core.css";
import "@ionic/core/css/ionic.bundle.css";
import "./theme.css";
import "./Common.css";
import "./App.css";
import Main from "./pages/Main";
import Composer from "./pages/Composer";
import Viewer from "./pages/Viewer";
import Login from "./pages/Login";
import Loading from "./pages/Loading";
import Register from "./pages/Register";
import OpenViewer from "./pages/OpenViewer";
import Truncate from "react-truncate";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetchAPI } from "./utils/APIUtil";
import * as API from "./API.json";
import AdminMain from "./admin/Main";
import { IonApp, IonRouterOutlet } from "@ionic/react";
import {
  setGlobal,
  GlobalKey,
  getGlobal,
  clearGlobal,
} from "./utils/GlobalUtil";
import OtherProfile from "./pages/OtherProfile";
import { connect, useSelector } from "react-redux";
import { RootState, actions } from "./store";
import { LogLevel, log } from "./utils/LogUtil";
import Information from "./page/InformationViewer";
import RegisterWaiting from "./pages/RegisterWaiting";
import { NotificationType } from "./models/Model.Notification";
import Welcome from "./pages/Welcome";
import AppUIServices from "./AppUIServices";
import { NotiOption, UserLevel, UserWorkType } from "./models/Model.User";
import { BoardType } from "./store/board/types";
import AnalyticsUtil from "./utils/AnalyticsUtil";
import ABTestUtil, { ABTestFeature } from "./utils/ABTestUtil";
import MyProfile from "./pages/MyProfile";
import MyProfileChange from "./pages/MyProfileChange";
import Setting from "./pages/Setting";
import Notice from "./pages/Notice";
import SettingNotification from "./pages/SettingNotification";
import JobPostList from "./pages/JobPostList";
import { AuthStatus } from "./store/user/types";
import JumpUrlScheme from "./poc/JumpUrlScheme";
import Landing from "./pages/Landing";
import { UIPopupType } from "./store/ui/types";
import { JobListType } from "./store/jobpost/types";
import MyScrap from "./pages/MyScrap";
// import Chat from './poc/Chat';
// import ChatSendbird from './poc/ChatSendbird';
// import ChatSendbirdList from './poc/ChatSendbirdList';
import ProductSaleViewer from "./pages/ProductSaleViewer";
import ProductSaleOrder from "./pages/ProductSaleOrder";
import ProductSaleOrderDone from "./pages/ProductSaleOrderDone";
import {
  SendBirdProvider,
  sendBirdSelectors,
  useSendbirdStateContext,
} from "sendbird-uikit";
import StringUtil from "./utils/StringUtil";
import Chat from "./components/chat/Chat";
import ChatList from "./components/chat/ChatList";
import { getOS } from "./utils/DeviceUtil";
import { redireactApp } from "./utils/UrlSchemeUtil";
import Reactive from "./poc/Reactive";
import { UserMessage } from "sendbird";
import ProfileImage from "./components/ProfileImage";
import ChannelTalkService from "./utils/ChannelTalkService";
import ProductSaleMyOrders from "./pages/ProductSaleMyOrders";
import ProductSaleMyOrderDetailViewer from "./pages/ProductSaleMyOrderDetailViewer";
import ProductSaleMyOrderReturn from "./pages/ProductSaleMyOrderReturn";
import ProductSaleMyOrderCancel from "./pages/ProductSaleMyOrderCancel";
import ComponentTest from "./poc/ComponentTest";
import SeminarViewer from "./page/Scholar/SeminarViewer";
import SeminarListViewer from "./page/Scholar/SeminarListViewer";
import SeminarMyListViewer from "./page/Scholar/SeminarMyListViewer";
import UserContractUpdateSign from "./page/UserContractUpdateSign";
import JobPostRegistration from "./pages/JobPostRegistration";
import JobPostDetail from "./pages/JobPostDetail";
import JobPostAlimtalkOrder from "./pages/JobPostAlimtalkOrder";
import JobPostEdit from "./pages/JobPostEdit";
import JobPostSetting from "./pages/JobPostSetting";
import JobPostSuccessSurvey from "./pages/JobPostSuccessSurvey";
import JobPostBizDetail from "./pages/JobPostBizDetail";
import JobPostPublicDetail from "./pages/JobPostPublicDetail";
import PopupTest from "./components/molecules/PopupTest";
import ConfirmDialog from "./components/molecules/ConfirmDialog";
import { ErrorCode } from "./models/ErrorCode";
import useConfirm from "./hooks/useConfirm";
import { COLOR_SYSTEM } from "./components/design/design-system";
import withConfirm, { WithConfirmProps } from "./hoc/withConfirm";
import imgInviteDuplicated from "./assets/image/invite_duplicated.png";
import imgInviteSuccess from "./assets/image/invite_success.png";
import imgInviteFailed from "./assets/image/invite_failed.png";
import imgInviteInvalid from "./assets/image/invite_invalid.png";
import BoardView from "./pages/BoardView";
import Boards from "./pages/Boards";
import Medicine from "./pages/Medicine";
import AllInOneHome from "./pages/AllInOneHome";
import AllInOnePreviewHome from "./pages/AllInOnePreviewHome";
import AllInOneDetail from "./pages/AllInOneDetail";
import AllInOneIntroduction from "./pages/AllInOneIntroduction";
import AllInOneConsult from "./pages/AllInOneConsult";
import PartnersMain from "./pages/PartnersMain";
import PartnersIntroduction from "./pages/PartnersIntroduction";
import PartnersDetail from "./pages/PartnersDetail";
import PartnersConsult from "./pages/PartnersConsult";
import PeriodicPurchase from "./poc/PeriodicPurchase";
import PremiumUserSubscribe from "./pages/PremiumUserSubscribe";
import PremiumUserSubscribeDone from "./pages/PremiumUserSubscribeDone";
import PremiumUserUnsubscribeDone from "./pages/PremiumUserUnsubscribeDone";
import PremiumUserPurchaseHistory from "./pages/PremiumUserPurchaseHistory";
import BoardSummaryGroup from "./pages/BoardSummaryGroup";
import ChatMain from "./components/chat/ChatMain";
import { MY_ADDRESS } from "./config.json";
import Commercial from "./poc/Commercial";
import AdsUtil from "./utils/AdsUtil";
import JobPostPremiumOrder from "./pages/JobPostPremiumOrder";
import DailyQuiz from "./pages/DailyQuiz";
import PurchaseHistory from "./pages/PurchaseHistory";

const queryString = require("query-string");

const PopupPortal = lazy(() => import("./portal/popup"));
const ConfirmPortal = lazy(() => import("./portal/confirm"));
const BottomSheetPortal = lazy(() => import("./portal/bottomSheet"));
const BottomSheetTest = lazy(
  () => import("./components/molecules/BottomSheetTest")
);

const windowAny: any = window;
var fcmInitializeTry = 0,
  fcmInitializeTryMax = 10;
type Props = RouteComponentProps &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> &
  WithConfirmProps;

class App extends Component<Props> {
  constructor(prop: Props) {
    super(prop);
    log(LogLevel.UI_LIFECYCLE, "App:constructor", prop);
    let os = localStorage.getItem("yc_os");
    setGlobal(GlobalKey.OS, os);
    windowAny.handleOpenURL = this.getUrlScheme;
    this.props.loadFilePath();

    // if (
    //   windowAny.cordova &&
    //   windowAny.Keyboard &&
    //   (getGlobal(GlobalKey.OS) === "ios" || getGlobal(GlobalKey.OS) === "android")
    // ) {
    //   alert("call app");
    //   windowAny.Keyboard.hideFormAccessoryBar(false, () => {
    //     setTimeout(() => {
    //       this.props.toastPopup.show("성공");
    //     }, 1000);
    //   });
    //   // windowAny.Keyboard.shrinkView(false);
    // }

    fetchAPI(API.USER_COUNT, "", null, null).then((result) => {
      if (result && !result.error) {
        setGlobal(GlobalKey.TOTAL_USER_COUNT, result.data, true);
      }
    });

    // windowAny.IMP.init("imp68909306");

    let qs = queryString.parse(this.props.location.search);
    if (qs.inflow) {
      AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "INFLOW", "외부유입", {
        유입경로: qs.inflow,
        url: prop.location.pathname + prop.location.search,
      });
    }

    // first loading - app launch
    let platform = getOS();
    log(
      LogLevel.UI_LIFECYCLE,
      "App:constructor",
      window.location.pathname,
      window.location.origin
    );

    if (
      (!os || os == "browser") &&
      (platform == "ios" || platform == "android")
    ) {
      // location.href = "www.ymyd.co.kr://" + prop.location.pathname + prop.location.search;
      // location.href = "kakao845dd8ac648986a8631ecadc339a7acf://" + prop.location.pathname + prop.location.search;
      // window.open("www.ymyd.co.kr://" + prop.location.pathname + prop.location.search, "_system");
      // window.open("kakao845dd8ac648986a8631ecadc339a7acf://" + prop.location.pathname + prop.location.search, "_system");

      if (window.location.pathname == "/redirect") {
        redireactApp("open?url=" + window.location.origin, false);
        return;
      }

      let search = prop.location.search;
      if (search) search += "&type=jump";

      let path = prop.location.pathname.substring(1).replace(/\//gi, "_");
      if (path) {
        if (!search) search = "?type=jump&path=" + path;
        else search += "&path=" + path;
      }
      redireactApp(search);
    }
  }

  componentDidMount() {
    this.loadInitParams();
    // windowAny.appMemStorage.get("initFCM").then((value) => {
    //   this.props.toastPopup.show("appMemStorage exist", value);
    // }, (err) => {
    //   this.props.toastPopup.show("appMemStorage exist", err);
    // });

    let os = getGlobal(GlobalKey.OS);
    if (os == "ios" && windowAny.iNoBounce) {
      windowAny.iNoBounce.enable();
    }

    log(
      LogLevel.UI_LIFECYCLE,
      "App:componentDidMount",
      os,
      !!windowAny.navigator.splashscreen
    );

    document.addEventListener(
      "deviceready",
      () => {
        this.reloadCordova("deviceready");

        if (windowAny.navigator.splashscreen) {
          log(
            LogLevel.UI_LIFECYCLE,
            "App:componentDidMount deviceready hide splashscreen",
            os,
            !!windowAny.navigator.splashscreen
          );
          windowAny.navigator.splashscreen.hide();
        }
      },
      false
    );
    document.addEventListener(
      "resume",
      () => {
        this.reloadCordova("resume");
      },
      false
    );
    this.reloadCordova("componentDidMount");
  }

  reloadCordova = (event: string) => {
    log(LogLevel.UI_LIFECYCLE, "App:reloadCordova", event, "#AdsUtil");
    if (!windowAny.cordova && getGlobal(GlobalKey.OS) != "browser") {
      var headEl = document.querySelector("head");
      var scriptEl = document.createElement("script");
      scriptEl.src = "cordova230503/" + getGlobal(GlobalKey.OS) + "/cordova.js";
      headEl.appendChild(scriptEl);
      log(LogLevel.UI_LIFECYCLE, "App:reloadCordova done", event, "#AdsUtil");

      setTimeout(() => {
        AdsUtil.init(this.props.me, true);
      }, 3000);
    }
  };

  componentWillUnmount() {
    ABTestUtil.isTest(ABTestFeature.UI_JOB_POST_CHANNEL_TALK);
    ChannelTalkService.getInstance().shutdown();
  }

  loadInitParamsTry = 0;
  currentFCM = null;
  initFCM = null;
  currentSCheme = "";
  initScheme = "";

  loadInitParams = () => {
    setTimeout(() => {
      if (windowAny.appMemStorage && this.props.toastPopup) {
        windowAny.appMemStorage.get("initFCM").then(
          (value) => {
            if (value) {
              if (!this.currentFCM) {
                this.initFCM = JSON.parse(value);
              }
              if (ABTestUtil.isTest())
                this.props.toastPopup.show(
                  "[DEBUG] appMemStorage initFCM exist: " + value
                );
              windowAny.appMemStorage.remove("initFCM");
              setTimeout(() => {
                if (this.initFCM) {
                  this.getFCMNotification(this.initFCM);
                }
              }, 300);
            }
          },
          (err) => {}
        );

        windowAny.appMemStorage.get("initScheme").then(
          (value) => {
            if (value) {
              if (ABTestUtil.isTest())
                this.props.toastPopup.show(
                  "[DEBUG] appMemStorage initScheme exist: " + value
                );
              if (!this.currentSCheme) {
                this.initScheme = value;
              }
              setTimeout(() => {
                if (this.initScheme) {
                  this.getUrlScheme(this.initScheme);
                }
              }, 300);
              windowAny.appMemStorage.remove("initScheme");
            }
          },
          (err) => {}
        );
      } else {
        this.loadInitParamsTry++;
        if (this.loadInitParamsTry < 10) {
          this.loadInitParams();
        }
      }
    }, 100);
  };

  componentDidUpdate(prevProps) {
    log(
      LogLevel.UI_LIFECYCLE,
      "App:componentDidUpdate",
      this.props.token,
      getGlobal(GlobalKey.OS),
      prevProps.me,
      this.props.me
    );
    if (this.props.token && this.props.token !== prevProps.token) {
      log(
        LogLevel.UI_LIFECYCLE,
        "App:componentDidUpdate token update",
        this.props.token,
        prevProps.token
      );
      if (getGlobal(GlobalKey.OS) != "browser") {
        this.enableFCM();
      } else {
        this.props.loadNotification();
      }

      // this.props.loadBoards();
      // this.props.loadBanners();
      // setTimeout(() => {
      //   if (ABTestUtil.isTest(ABTestFeature.UI_SEMINAR_LIST_VIEW)) {
      //     this.props.loadSeminarFeatured();
      //     this.props.loadSeminarLectures();
      //   }
      //   // this.props.loadSearchKeywords();
      // }, 500);
    }

    if (
      ABTestUtil.isTest(ABTestFeature.UI_APP_ADS) &&
      this.props.me &&
      (!prevProps.me || prevProps.me.premiumType != this.props.me.premiumType)
    ) {
      log(
        LogLevel.UI_LIFECYCLE,
        "App:componentDidUpdate premiumType update, #AdsUtil"
      );
      setTimeout(() => {
        AdsUtil.init(this.props.me);
      }, 3000);
    }

    if (
      ABTestUtil.isTest(ABTestFeature.UI_JOB_POST) &&
      this.props.token &&
      ((!prevProps.me && this.props.me && this.props.me.workType) ||
        (prevProps.me &&
          this.props.me &&
          prevProps.me.workType != this.props.me.workType &&
          (prevProps.me.workType != UserWorkType.PHARMACY_OWNER) !==
            (this.props.me.workType != UserWorkType.PHARMACY_OWNER)))
    ) {
      log(
        LogLevel.UI_LIFECYCLE,
        "App:componentDidUpdate workType Changed",
        prevProps.me,
        this.props.me
      );
      setTimeout(() => {
        this.props.initJobpost(this.props.me.workType);
      }, 500);
    }

    if (
      this.props.token &&
      this.props.authStatus == AuthStatus.AUTHENTICATED &&
      prevProps.authStatus != this.props.authStatus
    ) {
      log(
        LogLevel.UI_LIFECYCLE,
        "App:componentDidUpdate Level Changed",
        this.props.token,
        prevProps.authStatus,
        this.props.authStatus
      );
      this.props.loadBoards(true);
      this.props.loadBanners();
      this.props.loadEvents();
      this.props.loadReport();
      this.props.initializeSummaryCount();
      this.props.loadChatUnreadCount();

      // ABTestUtil.isTest(ABTestFeature.UI_JOB_POST_CHANNEL_TALK);
      // ChannelTalkService.getInstance().boot(this.props.me, "약문약답");
      ChannelTalkService.getInstance().setMe(this.props.me);
    }

    if (this.props.token && this.props.token !== prevProps.token) {
      let initialContent = getGlobal(GlobalKey.INITIAL_CONTENT);
      if (initialContent) {
        log(
          LogLevel.UI_EVENT,
          "App:componentDidUpdate, Delayed getUrlScheme Call",
          initialContent
        );
        setTimeout(() => {
          clearGlobal(GlobalKey.INITIAL_CONTENT);
        }, 500);
        AnalyticsUtil.event(
          AnalyticsUtil.TYPE_ALL,
          "RUN_CUSTOM_URL",
          "CustomUrl 실행",
          { 게시물id: initialContent }
        );
        this.props.history.push("/boards/view?id=" + initialContent);
      }
    }
    if (!this.props.filePath) {
      this.props.loadFilePath();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.props.token !== nextProps.token ||
      this.props.authStatus !== nextProps.authStatus ||
      (!this.props.me && nextProps.me) ||
      (this.props.me &&
        nextProps.me &&
        this.props.me.workType != nextProps.me.workType &&
        (nextProps.me.workType != UserWorkType.PHARMACY_OWNER) !==
          (this.props.me.workType != UserWorkType.PHARMACY_OWNER))
    )
      return true;
    return false;
  }

  enableFCM = () => {
    if (getGlobal(GlobalKey.NOTIFICATION_INITIALIZED)) {
      log(LogLevel.UI_DATA_LOAD, "App:enableFCM already initialized");
      return;
    }

    if (windowAny.FCMPlugin) {
      log(LogLevel.UI_DATA_LOAD, "App:enableFCM", windowAny.FCMPlugin);
      windowAny.FCMPlugin.onTokenRefresh((token) => {
        log(LogLevel.UI_EVENT, "FCMPlugin.onTokenRefresh", token);
        fetchAPI(
          API.USER_NOTIFICATION_RESET,
          "",
          {
            platform: getGlobal(GlobalKey.OS),
            token: getGlobal(GlobalKey.NOTIFICATION_KEY, true),
          },
          null,
          getGlobal(GlobalKey.TOKEN)
        );
        let option = getGlobal(GlobalKey.NOTIFICATION_OPTION, true);
        if (!option) {
          if (this.props.me.notiPush) {
            option = NotiOption.ALL;
          } else option = NotiOption.NONE;
          setGlobal(GlobalKey.NOTIFICATION_OPTION, option, true);
        }
        setGlobal(GlobalKey.NOTIFICATION_KEY, token, true);
        fetchAPI(
          API.USER_NOTIFICATION_SET,
          "",
          null,
          {
            platform: getGlobal(GlobalKey.OS),
            token: token,
            option: getGlobal(GlobalKey.NOTIFICATION_OPTION, true),
          },
          getGlobal(GlobalKey.TOKEN)
        );
      });

      windowAny.FCMPlugin.getToken((token) => {
        log(LogLevel.UI_EVENT, "FCMPlugin.getToken", token);
        setGlobal(GlobalKey.NOTIFICATION_KEY, token, true);
        let option = getGlobal(GlobalKey.NOTIFICATION_OPTION, true);
        if (!option) {
          if (this.props.me.notiPush) {
            option = NotiOption.ALL;
          } else option = NotiOption.NONE;
          setGlobal(GlobalKey.NOTIFICATION_OPTION, option, true);
        }
        fetchAPI(
          API.USER_NOTIFICATION_SET,
          "",
          null,
          { platform: getGlobal(GlobalKey.OS), token: token, option: option },
          getGlobal(GlobalKey.TOKEN)
        );
      });

      windowAny.FCMPlugin.onNotification(this.getFCMNotification);

      this.props.initNotificationCheckTime();
      this.props.loadNotification();
      setGlobal(GlobalKey.NOTIFICATION_INITIALIZED, true);
    } else {
      log(
        LogLevel.UI_DATA_LOAD,
        "App:enableFCM FCM is not enabled " + fcmInitializeTry
      );
      if (fcmInitializeTry < fcmInitializeTryMax) {
        fcmInitializeTry++;
        setTimeout(this.enableFCM, 1000);
      }
    }
  };

  getFCMNotification = (data) => {
    log(
      LogLevel.UI_EVENT,
      "FCMPlugin.onNotification",
      data,
      data.type == NotificationType.JOBPOST_NEW_RECOMMENDED_OFFER.toString(),
      data.type == NotificationType.JOBPOST_NEW_RECOMMENDED_OFFER
    );
    if (ABTestUtil.isTest())
      this.props.toastPopup.show(
        "[DEBUG] FCMPlugin.onNotification:" + JSON.stringify(data)
      );

    this.initFCM = null;
    this.currentFCM = data;

    if (data.wasTapped) {
      let url = "";
      if (data.type == NotificationType.JOBPOST_NEW_RECOMMENDED_OFFER) {
        if (ABTestUtil.isTest())
          this.props.toastPopup.show(
            "FCMPlugin.onNotification:" + data.type + " " + data.offerId
          );

        url = "/jobpost/view?id=" + data.offerId;
        AnalyticsUtil.event(
          AnalyticsUtil.TYPE_ALL,
          "RUN_NOTIFICATION",
          "앱알림 실행",
          {
            공지id: data.boardsContentsId,
            알림내용: data,
          }
        );
      } else if (data.type == NotificationType.CHAT_MESSAGE_RECIEVED) {
        url = "/chat?id=" + data.chatID;
        AnalyticsUtil.event(
          AnalyticsUtil.TYPE_ALL,
          "RUN_NOTIFICATION",
          "채팅 실행",
          { 챗id: data.chatID }
        );
      } else if (data.type == NotificationType.CUSTOM) {
        if (data.action == "jump" && data.url) {
          url = data.url;
        }
        AnalyticsUtil.event(
          AnalyticsUtil.TYPE_ALL,
          "RUN_NOTIFICATION",
          "앱알림 실행",
          data
        );
      } else {
        if (data.rootBoardContentId && data.rootBoardContentId != "0") {
          let subQuery = "";
          if (data.boardsContentsId)
            subQuery += "&fcnt=" + data.boardsContentsId;
          if (data.boardsCommentsId)
            subQuery += "&fcmt=" + data.boardsCommentsId;

          url = "/boards/view?id=" + data.rootBoardContentId + subQuery;
        } else if (data.boardsContentsId) {
          url = "/boards/view?id=" + data.boardsContentsId;
        }
        AnalyticsUtil.event(
          AnalyticsUtil.TYPE_ALL,
          "RUN_NOTIFICATION",
          "앱알림 실행",
          {
            게시물id: data.boardsContentsId,
            알림내용: data,
          }
        );
      }
      if (url.startsWith("&&&")) {
        if (getGlobal(GlobalKey.OS) != "browser") {
          this.props.history.push(url.substring(3));
        } else {
          window.open(
            url.replace("&&&", MY_ADDRESS),
            getGlobal(GlobalKey.OS) == "browser" ? "_blank" : "_system"
          );
        }
      } else if (url.startsWith(MY_ADDRESS)) {
        if (getGlobal(GlobalKey.OS) != "browser") {
          this.props.history.push(url);
        } else {
          window.open(
            url,
            getGlobal(GlobalKey.OS) == "browser" ? "_blank" : "_system"
          );
        }
      } else if (url.startsWith("/")) {
        this.props.history.push(url);
      } else if (url.startsWith("tel") || url.startsWith("sms")) {
        window.open(url, "_system");
      } else
        window.open(
          url,
          getGlobal(GlobalKey.OS) == "browser" ? "_blank" : "_system"
        );
    }

    if (data.type == NotificationType.NEW_POST) {
      this.props.updateBoard(BoardType.POST);
      this.props.loadNotification();
    } else if (data.type == NotificationType.NEW_QUESTION) {
      this.props.updateBoard(BoardType.QNA);
      this.props.loadNotification();
    } else if (data.type == NotificationType.USER_LEVEL_CHANGE) {
      this.props.loginUser();
    } else if (data.type == NotificationType.JOBPOST_NEW_RECOMMENDED_OFFER) {
      this.props.reloadJobList();
    } else if (data.type == NotificationType.CHAT_MESSAGE_RECIEVED) {
      if (
        location.pathname == "/chat" &&
        location.search.endsWith(data.chatID)
      ) {
        fetchAPI(
          API.JOBPOST_CHAT_READ,
          "",
          null,
          {
            channelId: data.chatID,
          },
          getGlobal(GlobalKey.TOKEN)
        ).then((result) => {
          if (result && !result.error) {
            this.props.setChatUnreadCount(result.hasUnread ? 1 : 0);
          }
        });
        return;
      }
      this.props.setChatUnreadCount(1);

      toast(
        <div className="app-chat-noti-container">
          <ProfileImage
            className="app-chat-noti-profile"
            profileUrl={data.profileUrl}
          />
          <div className="common-flex-grow">
            <div className="app-chat-noti-title">{data.sender}</div>
            <div className="app-chat-noti-body">
              <Truncate lines={1} ellipsis="...">
                {data.message}
              </Truncate>
            </div>
          </div>
        </div>,
        {
          onClick: () => {
            this.props.history.push("/chat?id=" + data.chatID);
          },
        }
      );

      this.props.loadNotification();
    }
  };

  getUrlScheme = (url) => {
    this.initScheme = "";
    this.currentSCheme = url;

    setTimeout(async () => {
      if (ABTestUtil.isTest())
        this.props.toastPopup.show("[DEBUG] getUrlScheme: " + url, 5000);

      let search = url.substring(url.lastIndexOf("?") + 1);
      let qs = queryString.parse(search);
      log(LogLevel.UI_ACTION, "App:getUrlScheme", url, qs);
      if (
        qs.type === "view" &&
        qs.id &&
        qs.id != getGlobal(GlobalKey.INITIAL_CONTENT)
      ) {
        this.props.history.push("/boards/view?id=" + qs.id);
        // }else if(qs.url){
        //   window.location.replace(qs.url + '?target=' + getGlobal(GlobalKey.OS));
        AnalyticsUtil.event(
          AnalyticsUtil.TYPE_ALL,
          "RUN_CUSTOM_URL",
          "CustomUrl 실행",
          { 게시물id: qs.id, Url: url }
        );
      }
      if (qs.type === "lecture" && qs.id) {
        this.props.history.push("/lecture?id=" + qs.id);
        // }else if(qs.url){
        //   window.location.replace(qs.url + '?target=' + getGlobal(GlobalKey.OS));
        AnalyticsUtil.event(
          AnalyticsUtil.TYPE_ALL,
          "RUN_CUSTOM_URL",
          "CustomUrl 실행",
          { 강의id: qs.id, Url: url }
        );
      } else if (qs.type === "action") {
        switch (qs.action) {
          case "invite": {
            if (ABTestUtil.isTest(ABTestFeature.UI_INVITE_EVENT)) {
              let result = await fetchAPI(
                API.USER_INVITED,
                "",
                null,
                { at: qs.at, from: qs.from },
                getGlobal(GlobalKey.TOKEN)
              );
              AnalyticsUtil.event(
                AnalyticsUtil.TYPE_ALL,
                "RUN_CUSTOM_URL",
                "CustomUrl 실행",
                {
                  type: "초대이벤트",
                  초대자id: qs.from,
                  피초대id: this.props.me.id,
                  초대일시: qs.at,
                  결과: result.error,
                }
              );
              if (ABTestUtil.isTest())
                this.props.toastPopup.show(JSON.stringify(result), 5000);

              switch (result.error) {
                case ErrorCode.ERROR_DUPLICATED: {
                  this.props.confirm({
                    img: (
                      <img
                        style={{ width: "160px", height: "160px" }}
                        src={imgInviteDuplicated}
                      />
                    ),
                    title: `이미 이벤트에 참여하셨어요.`,
                    description: (
                      <span>
                        이벤트 참여는 1번만 가능합니다. <br /> 대신,{" "}
                        <span
                          style={{ color: COLOR_SYSTEM.get("Skyblue")[400] }}
                        >
                          새로운 친구를 초대하면 <br /> 가입자 수만큼
                        </span>{" "}
                        쿠폰을 드려요!
                      </span>
                    ),
                    button: {
                      confirm: {
                        text: "확인",
                        color: "Secondary",
                        variant: "Tinted",
                      },
                    },
                  });
                  break;
                }
                case ErrorCode.ERROR_NONE: {
                  this.props.confirm({
                    img: (
                      <img
                        style={{ width: "200px", height: "200px" }}
                        src={imgInviteSuccess}
                      />
                    ),
                    title: `친구초대 이벤트 참여 완료!`,
                    description: (
                      <span>
                        * 채용 공고 카카오 알림 쿠폰은 <br />
                        이벤트 종료 후 일괄 지급됩니다.
                      </span>
                    ),
                    descriptionType: "Body2Medium",
                    button: {
                      confirm: {
                        text: "확인",
                        color: "Secondary",
                        variant: "Tinted",
                      },
                    },
                  });
                  break;
                }
                case ErrorCode.ERROR_EXPIRED: {
                  this.props.confirm({
                    img: (
                      <img
                        style={{ width: "160px", height: "160px" }}
                        src={imgInviteFailed}
                      />
                    ),
                    title: `기존 회원은 이벤트에 참여할 수 없어요.`,
                    description: (
                      <span>
                        대신,{" "}
                        <span
                          style={{ color: COLOR_SYSTEM.get("Skyblue")[400] }}
                        >
                          새로운 친구를 초대하면 <br /> 가입자 수만큼
                        </span>{" "}
                        쿠폰을 드려요!
                      </span>
                    ),
                    button: {
                      confirm: {
                        text: "닫기",
                        color: "Secondary",
                        variant: "Tinted",
                      },
                    },
                  });
                  break;
                }
                default: {
                  let query = await this.props.confirm({
                    img: (
                      <img
                        style={{ width: "160px", height: "160px" }}
                        src={imgInviteInvalid}
                      />
                    ),
                    title: `유효하지 않은 초대 경로입니다.`,
                    button: {
                      cancel: {
                        text: "닫기",
                      },
                      confirm: {
                        text: "문의하기",
                        color: "Secondary",
                      },
                    },
                  });

                  if (query) {
                    ChannelTalkService.show();
                  }
                }
              }
            }

            break;
          }
          default: {
            if (this.props.urlAction[qs.action])
              this.props.urlAction[qs.action](qs.data);
          }
        }
      } else if (qs.type === "jobpost") {
        if (qs.offerId) {
          this.props.history.push("/jobpost/view?id=" + qs.offerId);
          // }else if(qs.applyId){
          //   this.props.history.push("/job/apply?id=" + qs.applyId);
        } else if (qs.bizOfferId) {
          this.props.history.push(`/jobpost/biz/view?id=${qs.bizOfferId}`);
        } else {
          this.props.history.push("/main/jobpost");
        }
        AnalyticsUtil.event(
          AnalyticsUtil.TYPE_ALL,
          "RUN_CUSTOM_URL",
          "CustomUrl 실행",
          {
            type: "구인구직",
            공지id: qs.offerId,
            지원id: qs.applyId,
            Url: url,
          }
        );
      } else if (qs.type === "allinone") {
        if (qs.partnerId) {
          this.props.history.push("/partners/detail?id=" + qs.partnerId);
        } else {
          this.props.history.push("/main/partners");
        }
        AnalyticsUtil.event(
          AnalyticsUtil.TYPE_ALL,
          "RUN_CUSTOM_URL",
          "CustomUrl 실행",
          {
            type: "파트너스",
            파트너id: qs.partnerId,
            Url: url,
          }
        );
      } else if (qs.type === "jump") {
        let search = { ...qs };
        delete search.type;
        let path = "";
        if (qs.path) {
          path = qs.path.replace(/_/gi, "/");
          delete search.path;
        }
        let searchQuery = Object.keys(search)
          .map(
            (k) => encodeURIComponent(k) + "=" + encodeURIComponent(search[k])
          )
          .join("&");
        if (searchQuery) searchQuery = "?" + searchQuery;
        this.props.history.push("/" + path + searchQuery);
        AnalyticsUtil.event(
          AnalyticsUtil.TYPE_ALL,
          "RUN_CUSTOM_URL",
          "CustomUrl 실행",
          {
            path: "/" + path + searchQuery,
          }
        );
      } else if (qs.type === "mysales") {
        this.props.history.push("/sale/myorders");
        AnalyticsUtil.event(
          AnalyticsUtil.TYPE_ALL,
          "RUN_CUSTOM_URL",
          "CustomUrl 실행",
          { path: "/sale/myorders" }
        );
      } else if (qs.type === "chat") {
        if (qs.channelId) {
          this.props.history.push("/chat?id=" + qs.channelId);
        } else {
          this.props.history.push("/chat/chatlist");
        }
        AnalyticsUtil.event(
          AnalyticsUtil.TYPE_ALL,
          "RUN_CUSTOM_URL",
          "CustomUrl 실행",
          { path: "/sale/myorders" }
        );
      } else {
        AnalyticsUtil.event(
          AnalyticsUtil.TYPE_ALL,
          "RUN_CUSTOM_URL",
          "CustomUrl 실행"
        );
      }
    }, 500);
  };

  setPopupView() {
    const { active } = useSelector((state: RootState) => state.popup);
    return (
      <>
        {active ? (
          <Suspense fallback={null}>
            <PopupPortal>
              <PopupTest />
            </PopupPortal>
          </Suspense>
        ) : null}
      </>
    );
  }

  setConfirmDialogView() {
    const { show } = useSelector((state: RootState) => state.confirm);

    return (
      <>
        {show ? (
          <Suspense fallback={null}>
            <ConfirmPortal>
              <ConfirmDialog />
            </ConfirmPortal>
          </Suspense>
        ) : null}
      </>
    );
  }

  setBottomSheetView() {
    const { on } = useSelector((state: RootState) => state.bottomSheet);

    return (
      <>
        {on ? (
          <Suspense fallback={null}>
            <BottomSheetPortal>
              <BottomSheetTest maxHeight="90vh" />
            </BottomSheetPortal>
          </Suspense>
        ) : null}
      </>
    );
  }

  render() {
    let mainuser = [];
    if (ABTestUtil.isTest(ABTestFeature.UI_MENU)) {
      mainuser.push(
        <PrivateRoute
          key="0"
          path="/main/user/setting/:type(notice)"
          component={Notice}
        />
      );
      mainuser.push(
        <PrivateRoute
          key="1"
          path="/main/user/setting/notification"
          component={SettingNotification}
        />
      );
      mainuser.push(
        <PrivateRoute
          key="2"
          path="/main/user/setting/feedback"
          component={Setting}
        />
      );
      mainuser.push(
        <PrivateRoute
          key="3"
          path="/main/user/setting/:type(faq)"
          component={Notice}
        />
      );
      mainuser.push(
        <Route
          key="4"
          path="/main/user/setting/:type(privacy)"
          component={Information}
        />
      );
      mainuser.push(
        <Route
          key="5"
          path="/main/user/setting/:type(contract)"
          component={Information}
        />
      );
      mainuser.push(
        <Route
          key="6"
          path="/main/user/setting/:type(company)"
          component={Information}
        />
      );
      mainuser.push(
        <PrivateRoute
          key="7"
          path="/main/user/setting/version"
          component={Setting}
        />
      );
      mainuser.push(
        <PrivateRoute
          key="8"
          path="/main/user/profile"
          component={MyProfileChange}
        />
      );
      mainuser.push(
        <PrivateRoute key="9" path="/main/user/setting" component={Setting} />
      );
      mainuser.push(
        <PrivateRoute key="10" path="/main/user" component={MyProfile} />
      );

      if (ABTestUtil.isTest(ABTestFeature.UI_MENU)) {
        mainuser.push(
          <PrivateRoute key="12" path="/main/bookmark" component={MyScrap} />
        );
      }
    }

    let app = (
      <div id="app">
        <this.setPopupView />
        <this.setConfirmDialogView />
        <this.setBottomSheetView />
        <IonApp>
          <Switch>
            {/* <Route exact path="/" component={Commercial} /> */}
            {/* <Route path="/main" component={Main} /> */}
            <Route path="/login" component={Login} />
            <Route path="/landing" component={Landing} />
            <Route path="/register" component={Register} exact={true} />
            <RegisteringRoute
              path="/register/update"
              component={Register}
              options={{ private: true }}
            />
            <Route path="/registering" component={RegisterWaiting} />
            <PrivateRoute
              path="/new"
              component={Composer}
              options={{ type: "new" }}
            />
            <PrivateRoute
              path="/reply"
              component={Composer}
              options={{ type: "reply" }}
            />
            <PrivateRoute
              path="/edit"
              component={Composer}
              options={{ type: "edit" }}
            />
            <PrivateRoute path="/user" component={OtherProfile} />
            <PrivateRoute path="/welcome" component={Welcome} />
            <PrivateRoute path="/view" component={Viewer} />
            <PrivateRoute path="/view/:id" component={Viewer} />
            <PrivateRoute path="/boards/view" component={Viewer} />
            <PrivateRoute path="/boards/view/:id" component={Viewer} />
            {/* <PrivateRoute path="/boards/view" component={BoardView} /> */}
            {/* <PrivateRoute path="/boards/view/:id" component={BoardView} /> */}
            <PrivateRoute path="/sale/product" component={ProductSaleViewer} />
            <PrivateRoute
              path="/sale/order/done"
              component={ProductSaleOrderDone}
            />
            <PrivateRoute path="/sale/order" component={ProductSaleOrder} />
            <PrivateRoute path="/sale/myorders" component={PurchaseHistory} />
            <PrivateRoute
              path="/sale/myorder"
              component={ProductSaleMyOrderDetailViewer}
            />
            <PrivateRoute
              path="/sale/return"
              component={ProductSaleMyOrderReturn}
            />
            <PrivateRoute
              path="/sale/cancel"
              component={ProductSaleMyOrderCancel}
            />
            <PrivateRoute path="/jobposts" component={JobPostList} />
            <PrivateRoute
              path="/jobpost/register"
              component={JobPostRegistration}
            />
            <PrivateRoute
              path="/jobpost/success/survey"
              component={JobPostSuccessSurvey}
            />
            <Route
              path="/jobpost/view/:token"
              component={JobPostPublicDetail}
            />
            <PrivateRoute path="/jobpost/view" component={JobPostDetail} />
            <PrivateRoute
              path="/jobpost/biz/view"
              component={JobPostBizDetail}
            />
            <PrivateRoute path="/jobpost/setting" component={JobPostSetting} />
            <PrivateRoute path="/jobpost/edit" component={JobPostEdit} />
            <PrivateRoute
              path="/jobpost/order/alimtalk"
              component={JobPostAlimtalkOrder}
            />
            <PrivateRoute
              path="/jobpost/order/premium"
              component={JobPostPremiumOrder}
            />
            <PrivateRoute path="/chat" component={ChatMain} />
            <PrivateRoute path="/contract" component={UserContractUpdateSign} />
            {mainuser}
            <PrivateRoute path="/main" component={Main} />
            <Route path="/test" component={PeriodicPurchase} />
            {ABTestUtil.isTest() && (
              <Route path="/components" component={ComponentTest} />
            )}
            {/* <PrivateRoute path="/testc" component={ChatSendbird} />
                <PrivateRoute path="/testl" component={ChatSendbirdList} /> */}
            <Route path="/jump" component={JumpUrlScheme} />
            <PrivateRoute path="/lecture" component={SeminarViewer} />
            <PrivateRoute path="/lectures" component={SeminarListViewer} />
            <PrivateRoute path="/mylectures" component={SeminarMyListViewer} />
            <PrivateRoute
              path="/premium/done/subscribe"
              component={PremiumUserSubscribeDone}
            />
            <PrivateRoute
              path="/premium/done/unsubscribe"
              component={PremiumUserUnsubscribeDone}
            />
            <PrivateRoute
              path="/premium/subscribe"
              component={PremiumUserSubscribe}
            />
            {/* <PrivateRoute path="/scholarsearch" component={ScholarSearch} />
                <PrivateRoute path="/scholarsearchresult" component={ScholarSearchResultsViewer} /> */}
            <Route path="/allinone/home" component={AllInOneHome} />
            <Route
              path="/allinone/preview/home"
              component={AllInOnePreviewHome}
            />
            <Route
              path="/allinone/introduction"
              component={AllInOneIntroduction}
            />
            <PrivateRoute path="/allinone/detail" component={AllInOneDetail} />
            <PrivateRoute
              path="/allinone/consult"
              component={AllInOneConsult}
            />
            <Route path="/partners/main" component={PartnersMain} />
            <Route
              path="/partners/introduction"
              component={PartnersIntroduction}
            />
            <PrivateRoute path="/partners/detail" component={PartnersDetail} />
            <PrivateRoute
              path="/partners/consult"
              component={PartnersConsult}
            />
            {ABTestUtil.isTest(ABTestFeature.UI_GPT_SUMMARY) && (
              <PrivateRoute
                path="/boards/summary"
                component={BoardSummaryGroup}
              />
            )}
            {ABTestUtil.isTest(ABTestFeature.UI_QUIZ) && (
              <PrivateRoute path="/quiz/daily" component={DailyQuiz} />
            )}

            <Route path="/main/:type(contract)" component={Information} />
            <Route path="/main/:type(privacy)" component={Information} />
            <Route path="/main/:type(company)" component={Information} />
            <ManagerRoute path="/admin" component={AdminMain} />
            <Route exact path="/" component={Loading} />
            <PrivateRoute path="/medicine" component={Medicine} />
          </Switch>
          <AppUIServices />
        </IonApp>
      </div>
    );

    return app;
  }
}

const mapStateToProps = (state: RootState) => ({
  token: state.user.token,
  authStatus: state.user.authStatus,
  me: state.user.me,
  urlAction: state.ui.urlActions,
  filePath: state.board.filePath,
  toastPopup: state.ui.popups[UIPopupType.TOAST_POPUP],
});

const mapDispatchToProps = {
  loadEvents: () => actions.event.getEvents(),
  loadReport: () => actions.gamification.getReport(),
  loadNotification: () => actions.notification.refresh(),
  initNotificationCheckTime: () => actions.notification.initCheck(),
  loginUser: () => actions.user.login(),
  initializeSummaryCount: () => actions.user.getFreeSummaryCount(),
  loadFilePath: () => actions.board.getFilePath(),
  updateBoard: (board: BoardType) => actions.board.getBoardUpdates(board, true),
  loadBoards: (force: boolean = false) => actions.board.getBoards(force),
  loadBanners: () => actions.banner.getBanners(),

  loadChatUnreadCount: () => actions.notification.chatUnreadCountLoad(),
  setChatUnreadCount: (count: number) =>
    actions.notification.chatUnreadCountSet(count),

  initJobpost: (workType: UserWorkType) => actions.jobpost.init(workType),
  reloadJobList: () =>
    actions.jobpost.reloadList(
      null,
      JobListType.OFFER | JobListType.RECOMMENDED
    ),
  // loadSearchKeywords: () => actions.search.loadSearchKeywords(),
};
export default withConfirm(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
);

// function ChatNotificationManger(props) {
//   const context = useSendbirdStateContext();
//   const sdkInstance = sendBirdSelectors.getSdk(context);
//   useEffect(() => {
//     log(LogLevel.UI_EVENT, "ChatNotificationManger initializing", sdkInstance);
//     if (
//       ABTestUtil.isTest(ABTestFeature.CHAT) &&
//       sdkInstance &&
//       sdkInstance.ChannelHandler &&
//       sdkInstance.addChannelHandler
//     ) {
//       const channelHandler = new sdkInstance.ChannelHandler();
//       channelHandler.onMessageReceived = (channel, message) => {
//         log(
//           LogLevel.UI_EVENT,
//           "ChatNotificationManger onMessageReceived",
//           channel,
//           message,
//           location
//         );

//         let channelData = JSON.parse(channel.data);
//         if (channelData && channelData.type && message.messageType == "user") {
//           // if my message, do not show
//           let target = "";
//           if (ABTestUtil.isDev()) {
//             target = "DEV";
//           }

//           if (message["_sender"]["userId"] === target + props.me.id) return;

//           if (
//             location.pathname == "/chat" &&
//             location.search.endsWith(channel.url)
//           ) {
//             fetchAPI(
//               API.JOBPOST_CHAT_READ,
//               "",
//               null,
//               {
//                 channelId: channel.url,
//                 user: channelData.ouid == props.me.id ? "owner" : "applier",
//               },
//               getGlobal(GlobalKey.TOKEN)
//             );
//             return;
//           }

//           let sender = message["_sender"]["nickname"];
//           let senderMeta = message["_sender"]["metaData"];

//           if (channelData.type == "JOBPOST" && senderMeta && senderMeta.name)
//             sender = senderMeta.name;

//           let profileUrl = "";
//           profileUrl = message["_sender"]["profileUrl"];
//           toast(
//             <div className="app-chat-noti-container">
//               <ProfileImage
//                 className="app-chat-noti-profile"
//                 profileUrl={profileUrl}
//               />
//               <div className="common-flex-grow">
//                 <div className="app-chat-noti-title">{sender}</div>
//                 <div className="app-chat-noti-body">
//                   <Truncate lines={1} ellipsis="...">
//                     {message.message}
//                   </Truncate>
//                 </div>
//               </div>
//             </div>,
//             {
//               onClick: () => {
//                 props.history.push("/chat?id=" + channel.url);
//               },
//             }
//           );
//         }
//       };
//       sdkInstance.addChannelHandler("ChatNofitication", channelHandler);

//       setTimeout(() => {
//         sdkInstance
//           .getTotalUnreadMessageCount()
//           .then((count) => {
//             if (count > 0 && window.location.pathname == "/main/boards") {
//               toast(
//                 <div className="app-chat-noti-container">
//                   <div className="common-flex-grow">
//                     <div className="app-chat-noti-title">
//                       {count}개의 안읽은 메시지가 있습니다.
//                     </div>
//                   </div>
//                 </div>,
//                 {
//                   onClick: () => {
//                     props.history.push("/chat/chatlist");
//                   },
//                 }
//               );
//             }
//           })
//           .catch((e) => {});
//       }, 300);

//       log(LogLevel.UI_EVENT, "ChatNotificationManger initialized");
//     }

//     return () => {
//       if (sdkInstance && sdkInstance.removeChannelHandler) {
//         sdkInstance.removeChannelHandler("ChatNofitication");
//       }
//     };
//   }, [sdkInstance]);
//   return <div />;
// }
