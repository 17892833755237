import React, { useRef } from "react";
import styled from "styled-components";
import Button from "../Button";
import Text from "../Text";
import { COLOR_SYSTEM } from "../../design/design-system";
import { typography } from "../../design/typography";
import Icon from "../Icon";
import { Absolute, Relative } from "../Layout";

export interface InputProps {
  type?: string;
  placeholder?: string | React.ReactNode;
  rightItems?: React.ReactNode[];
  value?: string;
  name?: string;
  label?: string;
  required?: boolean;
  error?: boolean;
  multiLine?: boolean;
  hint?: string;
  readonly?: boolean;
  className?: string;
  style?: React.CSSProperties;
  search?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onClear?: (params?: any) => any;
  onClick?: (params?: any) => any;
  onEnter?: () => any;

  inputRef?: React.RefObject<HTMLInputElement>;
}

interface InputStyledProps {
  error?: boolean;
}

const InputField = styled.input<InputStyledProps>`
  display: block;
  min-width: 30px;
  height: 100%;
  appearance: none;
  outline: none;
  border: none;
  flex: 1;
  background-color: ${({ error }) =>
    error ? COLOR_SYSTEM.get("Red")[10] : "inherit"};
  color: ${COLOR_SYSTEM.get("Gray")[800]};
  ${typography.get("InputLarge")};

  &::placeholder {
    color: ${COLOR_SYSTEM.get("Gray")[300]};
  }

  &:focus {
    background-color: ${({ error }) =>
      error ? COLOR_SYSTEM.get("Red")[10] : COLOR_SYSTEM.get("Gray")[20]};
  }
`;

const TextAreaField = styled.textarea<InputStyledProps>`
  padding: 11.5px 16px;
  display: block;
  min-width: 70px;
  width: 100%;
  height: 180px;
  appearance: none;
  outline: none;
  border: none;
  flex: 1;
  background-color: ${({ error }) =>
    COLOR_SYSTEM.get(error ? "Red" : "Gray")[10]};
  color: ${COLOR_SYSTEM.get("Gray")[800]};
  resize: none;
  border: ${({ error }) =>
    error
      ? `1px solid ${COLOR_SYSTEM.get("Red")[50]}`
      : "1px solid transparent"};
  border-radius: 12px;
  transform: translateZ(0);
  box-sizing: border-box;
  ${typography.get("InputLarge")};

  &::placeholder {
    color: ${COLOR_SYSTEM.get("Gray")[300]};
  }

  &:focus {
    border: 1px solid ${COLOR_SYSTEM.get("Gray")[100]};
    background-color: ${COLOR_SYSTEM.get("Gray")[20]};
  }
`;

const InputWrapper = styled.div<InputStyledProps>`
  height: 48px;
  padding: 11.5px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ error }) =>
    COLOR_SYSTEM.get(error ? "Red" : "Gray")[10]};
  box-sizing: border-box;
  border-radius: 12px;
  transform: translateZ(0);

  border: ${({ error }) =>
    error
      ? `1px solid ${COLOR_SYSTEM.get("Red")[50]}`
      : "1px solid transparent"};

  &:focus-within {
    /* border: 1px solid ${COLOR_SYSTEM.get("Gray")[100]}; */
    border: ${({ error }) =>
      error
        ? `1px solid ${COLOR_SYSTEM.get("Red")[50]}`
        : `1px solid ${COLOR_SYSTEM.get("Gray")[100]}`};
    background-color: ${({ error }) =>
      error ? COLOR_SYSTEM.get("Red")[10] : COLOR_SYSTEM.get("Gray")[20]};
  }
`;

const Label = styled.label`
  display: inline-flex;
  align-items: flex-start;
  margin-bottom: 8px;

  * + * {
    margin-left: 4px;
  }
`;

const Wrapper = styled.div`
  min-width: 120px;
  position: relative;

  &:focus-within ${Label} {
    span:first-child {
      color: ${COLOR_SYSTEM.get("Skyblue")[500]};
    }
  }
`;

const Input: React.FC<InputProps> = ({
  type = "text",
  placeholder,
  rightItems,
  value,
  name,
  label,
  required,
  error = false,
  hint,
  multiLine: muitiLine = false,
  readonly = false,
  className,
  style,
  search = false,
  onChange = () => {},
  onClear,
  onClick,
  onEnter,
  inputRef,
}) => {
  const textareaRef = inputRef ? inputRef : useRef<HTMLTextAreaElement>();

  const onKeyDown = (e) => {
    // check enter key press without shift key
    if (onEnter && e.keyCode == 13 && !e.shiftKey) onEnter();
  };

  return (
    <Wrapper className={className} style={style}>
      {label && (
        <Label>
          <Text
            textType="InputSmall"
            color={
              error
                ? COLOR_SYSTEM.get("Red")[300]
                : COLOR_SYSTEM.get("Gray")[600]
            }
          >
            {label}
          </Text>
          {required && (
            <Text textType="InputSmall" color={COLOR_SYSTEM.get("Red")[300]}>
              *
            </Text>
          )}
        </Label>
      )}
      {muitiLine ? (
        <Relative>
          <TextAreaField
            autoComplete="off"
            value={value}
            name={name}
            error={error}
            ref={textareaRef}
            onChange={onChange}
            onKeyDown={onKeyDown}
            onClick={onClick}
          />
          {placeholder && !value && (
            <Absolute
              top={0}
              left={0}
              width="100%"
              height="100%"
              customStyle={{
                padding: "16px",
                color: COLOR_SYSTEM.get("Gray")[300],
                textAlign: "left",
              }}
              onClick={() => {
                textareaRef.current.focus();
              }}
            >
              {placeholder}
            </Absolute>
          )}
        </Relative>
      ) : (
        <InputWrapper error={error} onClick={onClick}>
          {search && (
            <div style={{ marginRight: "16px" }}>
              <Icon name={"MagnifyingGlass"} width={20} height={20} />
            </div>
          )}
          <InputField
            type={type}
            autoComplete="off"
            spellcheck={false}
            placeholder={placeholder}
            value={value}
            name={name}
            error={error}
            onChange={onChange}
            onKeyDown={onKeyDown}
            readonly={readonly}
          />
          {onClear && value && !readonly && (
            <Button
              color="Quaternary"
              icon="XFilled"
              size="Medium"
              type="Icon"
              variant="Ghost"
              onClick={onClear}
            />
          )}
          {rightItems && rightItems.length && rightItems.map((item) => item)}
        </InputWrapper>
      )}
      {hint && (
        <Text
          textType="InputSmall"
          color={COLOR_SYSTEM.get(error ? "Red" : "Gray")[400]}
        >
          {hint}
        </Text>
      )}
    </Wrapper>
  );
};

export default Input;
