import React, { Component } from 'react';
import './DynamicCheckerInput.css';
import './../Common.css';
import { IonSpinner, IonTitle, IonLoading, IonToast, IonIcon, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonSegment, IonSegmentButton, IonButton, IonSearchbar, IonContent, IonRefresher, IonRefresherContent, IonFab, IonFabList, IonFabButton, IonAlert, IonBadge, IonLabel, IonInput } from '@ionic/react';
import DynamicChecker from './DynamicChecker';
import { TextFieldTypes } from '@ionic/core';
import { LogLevel, log } from '../utils/LogUtil';

export enum CheckStatus {
  UNCHECKED = 0,
  CHECKING = 1,
  CHECKED = 2,
  WARNING = 3,
}

/**
 * status: 0:unchecked 1:checking 2:checked 3:warning
 */
type Props = {
  status?:CheckStatus
  title?: string;
  input?: string;
  onChange?: (e) => void;
  onClick?: (e) => void;
  messages?: string;
  warnings?: string;
  optional?: boolean;
  type?: TextFieldTypes;
  titleClassName?:string;
  inputClassName?:string;
  containerClassName?: string;
  withoutChecker?:boolean;
  onBlur?: () => void;
};

type State = {
  warning: boolean;
}

class DynamicCheckerInput extends Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {warning:(this.props.status == CheckStatus.CHECKED)?false:true};
    log(LogLevel.UI_LIFECYCLE, "DynamicCheckerInput:constructor", props, this.state)
  }

  componentDidUpdate (lastProps, lastState){
    log(LogLevel.UI_LIFECYCLE, "DynamicCheckerInput:componentWillUpdate", this.props, this.state)
    if(this.state.warning){
      if(this.props.status == CheckStatus.CHECKED)
        this.setState({warning:false});
    }else{
      if(this.props.status == CheckStatus.WARNING || this.props.status == CheckStatus.UNCHECKED)
        this.setState({warning:true});
    }
  }

  onChange = (e) => {
    if(this.props.onChange)
      this.props.onChange(e)
  }

  onClick = (e) => {
    if(this.props.onClick)
      this.props.onClick(e)
  }

  onBlur = () => {
    if(this.props.onBlur)
      this.props.onBlur();
  }

  render() {
    let status = this.props.status;
    let description;
    if(this.props.warnings)
      description = <div className="dynamic-checker-input-warning">{this.props.warnings}</div>
    else if(this.props.messages)
      description = <div className="dynamic-checker-input-message">{this.props.messages}</div>

    let disabled = false;
    if(!this.props.onChange)
      disabled = true;
    let checker;
    if(!this.props.withoutChecker)
      checker = <DynamicChecker status={this.props.status}/>

    let title;
    if(this.props.title || this.props.optional){
      title = <div className={(this.props.titleClassName?this.props.titleClassName + " dynamic-checker-input-title":"dynamic-checker-input-title") + (this.state.warning?" dynamic-checker-input-title-warn":"")}>{this.props.title}<br/>{this.props.optional?"":"(필수)"}</div>
    }
    return(
      <div className={(this.props.containerClassName?this.props.containerClassName + " dynamic-checker-input-container":"dynamic-checker-input-container")} onClick={this.onClick}>
        {title}
        <div className="common-flex-grow">
          <div className="dynamic-checker-input-input-container">
            <input className={(this.props.inputClassName?this.props.inputClassName + " dynamic-checker-input-input":"dynamic-checker-input-input") + (this.state.warning?" dynamic-checker-input-input-warn":"")} disabled={disabled} value={this.props.input} onBlur={this.onBlur} onChange={this.onChange} type={this.props.type}/>
            {checker}
          </div>
          {description}
        </div>
      </div>
    );
  }
}

export default DynamicCheckerInput;